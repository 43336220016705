/* eslint-disable @next/next/no-img-element */
import React from "react";
import Icon from "../Icon";
import Button from "../Button";
import Popup from "reactjs-popup";

export const MagicQuoteModal = ({
  open,
  setOpenMagicModal,
  quoteCarrier,
  quoteErrorMessage,
}) => {
  const quoteCarrierImageHandler = () => {
    switch (quoteCarrier) {
      case "Progressive":
        return "/Images/Progressive.webp";
      case "Coverwhale":
        return "/Images/Coverwhale.webp";
      default:
        return "/Images/Progressive.webp";
    }
  };
  return (
    <div>
      <Popup
        modal
        open={open}
        onClose={() => setOpenMagicModal(false)}
        contentStyle={{
          backgroundColor: "#fff",
          borderRadius: "20px",
          padding: "20px",
          position: "relative",
          width: "370px",
          alignItems: "center",
        }}
      >
        <div className=" flex justify-center items-center flex-col gap-y-2">
          <img
            className={`self-center `}
            src={quoteCarrierImageHandler()}
            alt="truck"
            style={
              quoteCarrier == "Coverwhale"
                ? { width: "200px" }
                : { width: "200px", height: "20px" }
            }
          />
          {quoteErrorMessage ? (
            <>
              <p className="text-xl text-gray6  text-center  font-SpaceBold">
                {"Error occurred in application!"}
              </p>
              <p className="text-gray6 opacity-70 text-lg whitespace-pre-wrap font-hkRegular text-center ">
                {quoteErrorMessage}
              </p>
            </>
          ) : (
            <>
              {" "}
              <p className="text-xl text-gray6  text-center  font-SpaceBold">
                {"Magic has happened!"}
              </p>
              <p className="text-gray6 opacity-70 text-lg whitespace-pre-wrap font-hkRegular text-center ">
                {"A quote has been returned. Good luck!"}
              </p>
            </>
          )}

          <Button
            width="w-full"
            title={"Close"}
            disabled={false}
            onButtonClick={() => setOpenMagicModal(false)}
          />
        </div>
      </Popup>
    </div>
  );
};
