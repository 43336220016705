import {
  SET_POLICIES,
  UPDATE_POLICY,
  UPDATE_POLICIES_ON_CREATED_NOTIFICATION,
  CLEAR_DATA,
  UPDATE_POLICY_FILE,
  CLEAR_DATA_WITHOUT_USER,
} from "../Types";

import {
  setAndTransformPolicies,
  updatePolicyInState,
  updatePoliciesOnNotification,
  updatePolicyForFile,
} from "../Utils/policyUtils";

const INITIAL_STATE = {
  policies: null,
};

const PolicyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_POLICIES:
      return setAndTransformPolicies(state, action.payload);

    case UPDATE_POLICY:
      return updatePolicyInState(state, action.payload);

    case UPDATE_POLICY_FILE:
      return updatePolicyForFile(state, action.payload);

    case UPDATE_POLICIES_ON_CREATED_NOTIFICATION:
      return updatePoliciesOnNotification(state, action.payload);

    case CLEAR_DATA:
      return { ...INITIAL_STATE };
    case CLEAR_DATA_WITHOUT_USER:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export default PolicyReducer;
