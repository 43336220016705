import moment from "moment-mini";

export const fileNameAndDateFormater = (name) => {
  const splitted = name.split("__&__");
  if (splitted?.length > 1) {
    const extension = splitted[1]?.split(".");

    const date = moment(new Date(Number(extension[0]))).format(
      "dddd, MMMM Do YYYY, h:mm:ss a"
    );

    return { name: `${splitted[0]}.${extension[1]}`, date };
  } else {
    const blankName = name.split(".");
    const date = moment(new Date(Number(blankName[0]))).format(
      "dddd, MMMM Do YYYY, h:mm:ss a"
    );
    if (date) {
      return {
        name: `File.${blankName[1]}`,
        data: date === "Invalid Date" ? "" : date,
      };
    }
  }
};
