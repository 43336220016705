import {
  SET_DRIVERS,
  ADD_NEW_DRIVER,
  DELETE_DRIVER,
  UPDATE_DRIVER,
  UPDATE_DRIVER_STATUS,
  RESTORE_DELETED_DRIVER,
  CLEAR_DATA_WITHOUT_USER,
  CLEAR_DATA,
} from "../Types";

import {
  setDriversUtils,
  updateDrivers,
  updateDriverMVRStatus,
  addNewDriverHandler,
  deleteDriverHandler,
  restoreDriverHandler,
} from "../Utils/driversUtils";

const INITIAL_STATE = {
  drivers: [],
  pastDrivers: [],
  pendingDrivers: [],
};

const driversReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_DRIVERS:
      return setDriversUtils(state, action.payload);

    case ADD_NEW_DRIVER:
      return addNewDriverHandler(state, action.payload);

    case UPDATE_DRIVER:
      return {
        ...state,
        pendingDrivers: updateDrivers(
          [...state.pendingDrivers],
          action.payload
        ),
        drivers: updateDrivers([...state.drivers], action.payload),
      };

    case DELETE_DRIVER:
      return deleteDriverHandler(state, action.payload);

    case UPDATE_DRIVER_STATUS:
      return {
        ...state,
        drivers: updateDriverMVRStatus([...state.drivers], action.payload),
      };

    case RESTORE_DELETED_DRIVER:
      return restoreDriverHandler(state, action.payload);
    case CLEAR_DATA:
      return { ...INITIAL_STATE };
    case CLEAR_DATA_WITHOUT_USER:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

export default driversReducer;
