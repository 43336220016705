export const brokerDetails = [
  {
    image: "/Images/Brokers/default.webp",
  },
  {
    name: "Aaron Ealy",
    email: "aaron.ealy@luckytruck.com",
    phone: "(773) 330-140",
    image: "/Images/Aaron Ealy.webp",
  },
  {
    name: "Adam Smith",
    email: "adam.smith@luckytruck.com",
    phone: "(906) 203-9222",
    image: "/Images/Adam Smith.webp",
  },
  {
    name: "Aiden Zimmer",
    email: "aiden.zimmer@luckytruck.com",
    phone: "(757) 716-0024",
    image: "/Images/Brokers/Corey Tator.webp",
  },
  {
    name: "Cal Brackin",
    email: "cal.brackin@luckytruck.com",
    phone: "(307) 413-1726",
    image: "/Images/Cal Brackin.webp",
  },
  {
    name: "Carlos Burman",
    email: "carlos.burman@luckytruck.com",
    phone: "(954) 800-7807",
    image: "/Images/Brokers/Carlos Burman.webp",
  },
  {
    name: "Carlos Ward",
    email: "carlos.ward@luckytruck.com",
    phone: "(954) 417-0501",
    image: "/Images/Brokers/Carlos Ward.webp",
  },
  {
    name: "Carolynn Earls",
    email: "carolynn.earls@luckytruck.com",
    phone: "(952) 204-5377",
    image: "/Images/Brokers/Carolynn Earls.webp",
  },
  {
    name: "Corey Tator",
    email: "corey.tator@luckytruck.com",
    phone: "(952) 222-5105",
    image: "/Images/Brokers/Corey Tator.webp",
  },
  {
    name: "Daniela Prokic",
    email: "daniela.prokic@luckytruck.com",
    phone: "(601)-476-5055",
    image: "/Images/Brokers/Daniela Prokic.webp",
  },
  {
    name: "Daryl Syes",
    email: "daryl.syes@luckytruck.com",
    phone: "(954) 417-0535",
    image: "/Images/Brokers/Daryl Syes.webp",
  },
  {
    name: "Elisabeth Bryan",
    email: "elisabeth.bryan@luckytruck.com",
    phone: "(952) 208-6362",
    image: "/Images/Brokers/Elisabeth Bryan.webp",
  },
  {
    name: "Hannah Burt",
    email: "hannah.burt@luckytruck.com",
    phone: "(952) 213-2025",
    image: "/Images/Brokers/Hannah Burt.webp",
  },
  {
    name: "Jacob Freeman",
    email: "jacob.freeman@luckytruck.com",
    phone: "(757) 716-0019",
    image: "/Images/Brokers/Corey Tator.webp",
  },
  {
    name: "Julie Zimmer",
    email: "julie.zimmer@luckytruck.com",
    phone: "(847) 431-1851",
    image: "/Images/Brokers/Julie Zimmer.webp",
  },
  {
    name: "Karen Bridges",
    email: "karen.bridges@luckytruck.com",
    phone: "(952) 204-5422",
    image: "/Images/Karen Bridges.webp",
  },
  {
    name: "Maggie Reyes",
    email: "maggie.reyes@luckytruck.com",
    phone: "(954) 417-8176",
    image: "/Images/Brokers/Maggie Reyes.webp",
  },
  {
    name: "Nicole Howard",
    email: "nicole.howard@luckytruck.com",
    phone: "(952) 373-6409",
    image: "/Images/Brokers/Nicole Howard.webp",
  },
  {
    name: "Nikola Atanackovic",
    email: "nikola.atanackovic@luckytruck.com",
    phone: "(601)-476-5055",
    image: "/Images/Brokers/Nikola Atanackovic.webp",
  },
  {
    name: "Robyn Winsmore",
    email: "robyn.winsmore@luckytruck.com",
    phone: "(952) 208-5624",
    image: "/Images/Brokers/Robyn Winsmore.webp",
  },
  {
    name: "Sadina Garabin",
    email: "sadina.garabin@luckytruck.com",
    phone: "(952) 208-5830",
    image: "/Images/Brokers/Sadina Garabin.webp",
  },
  {
    name: "Sara Klein",
    email: "sara.klein@luckytruck.com",
    phone: "(952) 373-6381",
    image: "/Images/Brokers/sadina.webp",
  },
  {
    name: "Sarah Hayman",
    email: "sarah.hayman@luckytruck.com",
    phone: "(952) 204-5372",
    image: "/Images/Brokers/Sarah Hayman.webp",
  },
  {
    name: "Shon Adams",
    email: "shon.adams@luckytruck.com",
    phone: "(954) 361-1653",
    image: "/Images/Brokers/Shon Adams.webp",
  },
  {
    name: "Vuk Vasiljevic",
    email: "vuk.vasiljevic@luckytruck.com",
    phone: "381638086219",
    image: "/Images/Vuk Vasiljevic.webp",
  },
  {
    name: "Zoran Petrovic",
    email: "zoran.petrovic@luckytruck.com",
    phone: "(601)-476-5055",
    image: "/Images/Brokers/Zoran Petrovic.webp",
  },
];
