import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import { useMediaQuery } from "react-responsive";
import LtSideBar from "../LTSidebar/LtSidebar";
import { useRouter } from "next/router";

const Layout3 = ({ children }) => {
  const router = useRouter();
  const [check, setCheck] = useState(true);
  const [openMobile, setOpenMobile] = useState(false);
  const [isCustomCheck, setIsCustom] = useState(false);
  const [isTabletScreen, setIsTabletScreen] = useState(null);
  const [wait, setWait] = useState(true);
  const isCustom = useMediaQuery({ minWidth: "481px", maxWidth: "700px" });
  const callback = (open) => {
    setCheck(open);
  };
  const openSidebarFunc = () => {
    setOpenMobile(!openMobile);
  };

  useEffect(() => {
    const mainToken = localStorage.getItem("mainToken");
    const LTtoken = localStorage.getItem("LTtoken");

    if (mainToken && LTtoken) {
      router.replace(process.env.NEXT_PUBLIC_USER_DASHBOARD);
      return;
    } else if (!LTtoken) {
      router.replace(process.env.NEXT_PUBLIC_LT_TEAM_LOGIN);
    } else {
      setWait(false);
    }
  }, []);

  useEffect(() => {
    if (isCustom) {
      setIsCustom(true);
      setCheck(false);
    } else {
      setIsCustom(false);
      setCheck(true);
    }
  }, [isCustom]);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 481px)" });
  useEffect(() => {
    setCheck(true);
  }, [isTabletOrMobile]);

  useEffect(() => {
    function handleResize() {
      const { innerWidth, innerHeight } = window;
      setIsTabletScreen(innerWidth > 482 && innerWidth <= 800);
    }
    typeof window !== "undefined"
      ? window.addEventListener("resize", handleResize)
      : "";

    // Call handler right away so state gets updated with initial window size
    typeof window !== "undefined" ? handleResize() : "";
  }, []);

  return (
    <>
      {!wait && (
        <div className={`${isTabletScreen && "w-fit"} flex flex-col`}>
          <Navbar
            openSidebar={check}
            openSidebarFunc={openSidebarFunc}
            isCustomCheck={isCustomCheck}
          />

          {/* <SideBar callback={callback} /> */}
          {/* <MobileSideBar openMobile={openMobile} /> */}

          <LtSideBar
            callback={callback}
            isCustomCheck={isCustomCheck}
            setIsCustom={setIsCustom}
          />

          <div
            className={`flex-1  ${
              check ? "pl-64" : " pl-20"
            } duration-300 xs:pl-0`}
          >
            {/* <MobileBrokerTabView /> */}
            {children}
          </div>
        </div>
      )}
    </>
  );
};
export default Layout3;
