import {
  CLEAR_DATA,
  SET_COMMODITIES,
  UPDATE_COMMODITY,
  DELETE_COMMODITY,
  ADD_NEW_COMMODITY,
  RESTORE_COMMODITY,
  CLEAR_DATA_WITHOUT_USER,
} from "../Types";

import {
  addCommodityHelper,
  setCommoditiesHelper,
  updateCommodityHelper,
  removeCommodityHelper,
  restoreCommodityHelper,
} from "../Utils/commodityUtils";

const INITIAL_STATE = {
  commodities: [],
  pastCommodities: [],
  pendingCommodities: [],
  totalPercentage: 0,
};

const CommodityReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_COMMODITIES:
      return setCommoditiesHelper(state, action.payload);
    case ADD_NEW_COMMODITY:
      return addCommodityHelper(state, action.payload);
    case UPDATE_COMMODITY:
      return updateCommodityHelper(state, action.payload);
    case DELETE_COMMODITY:
      return removeCommodityHelper(state, action.payload);
    case RESTORE_COMMODITY:
      return restoreCommodityHelper(state, action.payload);
    case CLEAR_DATA:
      return { ...INITIAL_STATE };
    case CLEAR_DATA_WITHOUT_USER:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export default CommodityReducer;
