import React from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import Icon from "../Icon";
export default function MobileSideBar({ callback, openMobile }) {
  const [open, setOpen] = React.useState(true);
  const [openSubMenu, setOpenSubMenu] = React.useState(false);
  const [index, setIndex] = React.useState(null);

  const router = useRouter();
  const closeSideBarFunc = () => {
    setCloseSideBar(true);
  };

  let Menu = [
    {
      title: "Dashboard",
      src: <Icon name={"icon-dashboard-inactive"} />,
      subMenu: ["Drivers", "Vehicles", "Fright"],
    },
    {
      title: "Documents",
      src: <Icon name={"icon-dashboard-inactive"} />,
    },
    {
      title: "Settings",
      src: <Icon name={"icon-dashboard-inactive"} />,
    },
  ];

  const onClickSubMenu = (i) => {
    if (i === 0) {
    } else {
      callback();
    }
    setIndex(i);

    setOpenSubMenu(!openSubMenu);
  };
  return (
    <div
      className={`${
        !openMobile
          ? "h-full  bg-gray4 rounded-r-lg fixed z-40   shadow-xl duration-200 transform -translate-x-full  ease-in "
          : "h-full  bg-gray4 rounded-r-lg fixed z-40 w-60  duration-300 shadow-xl transform translate-x-0   ease-out "
      }  `}
    >
      <ul className={`${open ? "pt-28 space-y-4 " : " pt-10 space-y-4 "}`}>
        {Menu?.map((menu, main) => (
          <>
            <Link href={`/${menu.title === "Dashboard" ? "" : menu.title}`}>
              <li
                key={main}
                className={`text-white text-sm flex items-center pl-8 gap-x-4  cursor-pointer py-2 mx-3 hover:bg-bgInactiveButton rounded-md relative`}
                onClick={() => onClickSubMenu(main)}
              >
                {(router.pathname === "/" || router.pathname.includes(process.env.NEXT_PUBLIC_USER_DASHBOARD)) &&
                main === 0 ? (
                  <div className="bg-bgActiveButton w-2 h-8 rounded-l-none rounded-r-md absolute -left-3" />
                ) : null}
                {router.pathname === process.env.NEXT_PUBLIC_USER_DOCUMENTS && main === 1 ? (
                  <div className="bg-bgActiveButton w-2 h-8 rounded-l-none rounded-r-md absolute -left-3" />
                ) : null}
                {menu.src}
                <span className={`w-fit  origin-left duration-200 font-hkBold text-lg`}>{menu.title}</span>
              </li>
            </Link>
            {openSubMenu && menu.subMenu !== null && main === 0
              ? menu.subMenu?.map((item, i) => {
                  if (main === index) {
                    return (
                      <>
                        <Link href={`/Dashboard/${item}`}>
                          <div key={i} onClick={callback} className=" flex flex-col pl-28 cursor-pointer">
                            <h3
                              className={`font-hkRegular text-lg text-white duration-300 ${
                                router.pathname === "/Dashboard/Drivers" && i === 0
                                  ? "underline underline-offset-2"
                                  : ""
                              } ${
                                router.pathname === "/Dashboard/Vehicles" && i === 1
                                  ? "underline underline-offset-2"
                                  : ""
                              } ${
                                router.pathname === "/Dashboard/Freight" && i === 2
                                  ? "underline underline-offset-2"
                                  : ""
                              }`}
                            >
                              {item}
                            </h3>
                          </div>
                        </Link>
                      </>
                    );
                  }
                })
              : null}
          </>
        ))}
      </ul>
    </div>
  );
}
