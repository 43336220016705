import {
  UPDATE_CURRENT_APPLICATION_DATA,
  RESET_APPLICATION_OBJECT,
  SET_APPLICATIONS,
  DELETE_APPLICATION,
  UPDATE_APPLICATION_IN_APPLICATIONS,
  UPDATE_APPLICATION_IN_APPLICATIONS_FOR_FILE,
  CLEAR_DATA,
  CLEAR_DATA_WITHOUT_USER,
  UPDATE_APPLICATION_PACKAGE,
  SHOW_POLICY_OR_QUOTES,
} from "../Types";

import {
  defaultCurrentApplicationObject,
  updateApplicationInApplications,
  updateApplicationPackage,
  updateApplicationPackageForFile,
} from "../Utils/quoteUtils";

const INITIAL_STATE = {
  currentApplication: { ...defaultCurrentApplicationObject },
  applications: null,
  showPolicesOrQuotes: false,
};

const quoteReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_CURRENT_APPLICATION_DATA:
      return { ...state, currentApplication: { ...action.payload } };

    case RESET_APPLICATION_OBJECT:
      return {
        ...state,
        currentApplication: { ...defaultCurrentApplicationObject },
      };

    case SHOW_POLICY_OR_QUOTES: {
      return {
        ...state,
        showPolicesOrQuotes: action.payload,
      };
    }

    case SET_APPLICATIONS:
      return { ...state, applications: [...action.payload] };

    case DELETE_APPLICATION:
      return {
        ...state,
        applications:
          state.applications && state.applications?.length > 0
            ? [...state.applications?.filter((app) => app.id !== action.payload)]
            : [],
      };

    case UPDATE_APPLICATION_IN_APPLICATIONS:
      return {
        ...state,
        applications: updateApplicationInApplications(state.applications, action.payload),
      };
    case UPDATE_APPLICATION_PACKAGE:
      return {
        ...state,
        applications: updateApplicationPackage(state.applications, action.payload),
      };

    case UPDATE_APPLICATION_IN_APPLICATIONS_FOR_FILE:
      return {
        ...state,
        applications: updateApplicationPackageForFile(state.applications, action.payload),
      };

    case CLEAR_DATA:
      return { ...INITIAL_STATE };
    case CLEAR_DATA_WITHOUT_USER:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export default quoteReducer;
