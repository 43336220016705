import { useRouter } from "next/router";
import React, { useState, useEffect } from "react";

const Layout2 = ({ children }) => {
  const router = useRouter();
  const [wait, setWait] = useState(true);

  useEffect(() => {
    const mainToken = localStorage.getItem("mainToken");
    const LTtoken = localStorage.getItem("LTtoken");
    const DispatcherToken = localStorage.getItem("DispatcherToken");

    if (mainToken) {
      router.replace(process.env.NEXT_PUBLIC_USER_DASHBOARD);
    } else if (LTtoken) {
      const identifyOldUserSession = localStorage.getItem("LTuserData");

      if (identifyOldUserSession) {
        localStorage.clear();
        router.replace(process.env.NEXT_PUBLIC_LT_TEAM_LOGIN);
      } else {
        if (process.env.NEXT_PUBLIC_ORGANIZATION === "Luckytruck") {
          router.replace(process.env.NEXT_PUBLIC_LT_HOME);
        } else {
          router.replace(process.env.NEXT_PUBLIC_LT_COMPANIES);
        }
      }
    } else if (DispatcherToken) {
      router.replace(process.env.NEXT_PUBLIC_DISPATCHER_HOME);
    } else {
      setWait(false);
    }
  }, []);

  return (
    !wait && (
      <>
        {process.env.NEXT_PUBLIC_THEME === "Flexport" && (
          <header className="bg-flexportNavbar sticky top-0 h-16 flex flex-row items-center justify-between font-hkLight uppercase z-20 shadow-md duration-300 px-2">
            <img
              className=" ml-5"
              src="/Images/flexport/Flexport-Insurance.svg"
              width={"180px"}
              height={"35px"}
            />
          </header>
        )}

        <div>{children}</div>
      </>
    )
  );
};
export default Layout2;
