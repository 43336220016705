import SideBar from "../Sidebar/sidebar";
import Navbar from "../Navbar";
import { useSelector } from "react-redux";
import MobileSideBar from "../MobileSidebar";
import { useMediaQuery } from "react-responsive";
import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";
import LTLoggedInUserBar from "../LTLoggedInUserBar";

const Layout1 = ({ children }) => {
  const router = useRouter();
  const [check, setCheck] = useState(true);
  const [openMobile, setOpenMobile] = useState(false);
  const [isCustomCheck, setIsCustom] = useState(false);
  const { LtLoggedIn, LTuser } = useSelector((state) => state.ltUser);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 481px)" });
  const isCustom = useMediaQuery({ minWidth: "481px", maxWidth: "700px" });
  const [wait, setWait] = useState(true);

  const callback = (open) => {
    setCheck(open);
  };

  const openSidebarFunc = () => {
    setOpenMobile(!openMobile);
  };

  const callback1 = () => {
    setOpenMobile(!openMobile);
  };

  useEffect(() => {
    const mainToken = localStorage.getItem("mainToken");
    const token = localStorage.getItem("token");
    let withOutDot = localStorage.getItem("withOutDot");

    if (!mainToken && !withOutDot && !token) {
      router.push(process.env.NEXT_PUBLIC_USER_LOGIN);
    } else {
      setWait(false);
    }
  }, []);

  useEffect(() => {
    setCheck(true);
  }, [isTabletOrMobile]);

  useEffect(() => {
    if (isCustom) {
      setIsCustom(true);
      setCheck(false);
    } else {
      setIsCustom(false);
      setCheck(true);
    }
  }, [isCustom]);

  return (
    <>
      {!wait && (
        <div className={`flex flex-col `}>
          <Navbar
            openSidebar={check}
            openSidebarFunc={openSidebarFunc}
            isCustomCheck={isCustomCheck}
          />
          {LtLoggedIn ? (
            <LTLoggedInUserBar openSidebar={check} LTuser={LTuser} />
          ) : null}

          {isTabletOrMobile ? (
            <MobileSideBar openMobile={openMobile} callback={callback1} />
          ) : (
            <SideBar
              callback={callback}
              isCustomCheck={isCustomCheck}
              setIsCustom={setIsCustom}
            />
          )}
          {/* {isTabletOrMobile ? (
        <Backdrop
          openMobile={openMobile}
          sideClose={() => setOpenMobile(false)}
        />
      ) : null} */}
          <div
            className={`flex-1  ${
              check && !isCustomCheck ? "pl-64" : " pl-20"
            } duration-300 xs:pl-0 `}
          >
            {children}
          </div>
        </div>
      )}
    </>
  );
};
export default Layout1;
