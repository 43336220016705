import { SET_NOTES, ADD_NEW_NOTE } from "../Types";

export const setNotes = (notes) => ({
  type: SET_NOTES,
  payload: notes,
});

export const addNewNote = (note) => ({
  type: ADD_NEW_NOTE,
  payload: note,
});
