import {
  SET_SIDEBAR,
  UPDATE_SIDEBAR,
  SET_LT_SIDEBAR,
  UPDATE_LT_SIDEBAR,
} from "../Types";

let Menu = [
  {
    id: 1,
    isOpen: false,
    title: "Dashboard",
    icon: "icon-dashboard-inactive",
  },
  {
    id: 2,
    isOpen: false,
    title: "Assets",
    icon: "icon-asset-sidebar",
    subMenu: [
      {
        subTitle: "Vehicles",
        isActive: false,
      },
      {
        subTitle: "Drivers",
        isActive: false,
      },
      {
        subTitle: "Commodities",
        isActive: false,
      },
    ],
  },
  {
    id: 3,
    isOpen: false,
    title: "Policies",
    icon: "icon-policy-sidebar",
  },
  {
    id: 5,
    isOpen: false,
    title: "Quotes",
    icon: "icon-quote-sidebar",
  },
  {
    id: 6,
    isOpen: false,
    title: "Documents",
    icon: "icon-documents-inactive",
  },
  {
    id: 7,
    isOpen: false,
    title: "Dispatchers",
    icon: "icon-dispatcher-sidebar",
  },
  {
    id: 8,
    isOpen: false,
    title: "Referral",
    icon: "icon-referral",
  },
  {
    id: 9,
    isOpen: false,
    title: "Settings",
    icon: "icon-setting-sidebar",
  },
];

let LTMenu = [
  {
    id: 1,
    isOpen: false,
    title: "Companies",
    icon: "icon-lt-logo",
  },
  {
    id: 2,
    isOpen: false,
    title: "Dashboard",
    icon: "icon-dashboard-inactive",
  },
  {
    id: 3,
    isOpen: false,
    title: "Learning",
    icon: "icon-lt-learning",
  },
];

const INITIAL_STATE = {
  menu: Menu,
  ltMenu: LTMenu,
};

const sidebarReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SIDEBAR:
      return { ...state, menu: Menu };

    case UPDATE_SIDEBAR:
      return { ...state, menu: action.payload };

    case SET_LT_SIDEBAR:
      return { ...state, ltMenu: LTMenu };

    case UPDATE_LT_SIDEBAR:
      return { ...state, ltMenu: action.payload };

    default:
      return state;
  }
};

export default sidebarReducer;
