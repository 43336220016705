import {
  CLEAR_DATA,
  SET_VEHICLES,
  UPDATE_VEHICLE,
  DELETE_VEHICLE,
  ADD_NEW_VEHICLE,
  RESTORE_VEHICLE,
  CLEAR_DATA_WITHOUT_USER,
} from "../Types";

import {
  addVehicleHelper,
  setVehiclesHelper,
  updateVehicleHelper,
  removeVehicleHelper,
  restoreVehicleHelper,
} from "../Utils/vehiclesUtils";

const INITIAL_STATE = {
  vehicles: [],
  pastVehicles: [],
  pendingVehicles: [],
};

const vehiclesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_VEHICLES:
      return setVehiclesHelper(state, action.payload);

    case ADD_NEW_VEHICLE:
      return addVehicleHelper(state, action.payload);

    case UPDATE_VEHICLE:
      return {
        ...state,
        vehicles: updateVehicleHelper([...state.vehicles], action.payload),
        pendingVehicles: updateVehicleHelper(
          [...state.pendingVehicles],
          action.payload
        ),
      };

    case DELETE_VEHICLE:
      return removeVehicleHelper(state, action.payload);
    case RESTORE_VEHICLE:
      return restoreVehicleHelper(state, action.payload);
    case CLEAR_DATA:
      return { ...INITIAL_STATE };
    case CLEAR_DATA_WITHOUT_USER:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export default vehiclesReducer;
