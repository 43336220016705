import moment from "moment-mini";

export const viewMoreNotificationsHanlder = (payload, notifications) => {
    if(notifications?.length==payload.count){
        return false
    }
    else if (notifications?.length < payload.count) {
        return true;
    } else {
        return false
    }
}

export const calculateTime = (created) => {
    const now = new Date()
    const then = new Date(created)
    const years = moment(now).diff(moment(then), 'years');
    const months = moment(now).diff(moment(then), 'months');
    const weeks = moment(now).diff(moment(then), 'weeks');
    const days = moment(now).diff(moment(then), 'days');
    const hours = moment(now).diff(moment(then), 'hours');
    const minutes = moment(now).diff(moment(then), 'minutes');
    const seconds = moment(now).diff(moment(then), 'seconds');
    if(years>0){
        return `${years>1? `${years} years ago`:`${years} year ago`}`
    }
    else if(months>0){
        return `${months>1? `${months} months ago`:`${months} month ago`}`
    }
    else if(weeks>0){
        return `${weeks>1? `${weeks} weeks ago`:`${weeks} week ago`}`
    }
    else if(days>0){
        return `${days>1? `${days} days ago`:`${days} day ago`}`
    }
    else if(hours>0){
        return `${hours>1? `${hours} hours ago`:`${hours} hour ago`}`
    }
    else if(minutes>0){
        return `${minutes>1? `${minutes} mins ago`:`${minutes} min ago`}`
    }
    else if(seconds>0){
        return `${seconds>1? `${seconds} sec ago`:`${seconds} sec ago`}`
    }
}
