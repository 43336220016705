import React from "react";
import Icon from "../Icon";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

const DownloadDocument = ({ open, onClose, name, date, url }) => {
  return (
    <div className=" xs:hidden">
      <Popup
        modal
        open={open}
        closeOnDocumentClick={false}
        onClose={onClose}
        contentStyle={{
          backgroundColor: "#fff",
          borderRadius: "20px",
          padding: "30px",
          position: "relative",
          width: "350px",
        }}
      >
        <div className="flex flex-col gap-y-4 text-center">
          <h3 className="font-hkBold text-inputColor text-lg">File Details</h3>
          <div className=" flex flex-col justify-center items-center">
            <h3 className=" font-hkBold text-input text-base">{name}</h3>
            <h3 className=" text-gray7 text-base font-hkRegular pb-5">
              {date}
            </h3>
            <a
              className="activeIconButton cursor-pointer"
              rel="noreferrer"
              href={url}
              onClick={() => onClose(false)}
              target="_blank"
              download
            >
              {"DOWNLOAD"}
            </a>
          </div>
        </div>

        <Icon name="icon-close-modal" onClick={() => onClose(false)} />
      </Popup>
    </div>
  );
};

export default DownloadDocument;
