import React, { Component } from "react";

const BasicLayout = ({ children }) => {
  // class BasicLayout extends Component {
  //   render() {
  return <div className="basic-layout">{children}</div>;
};
// }

export default BasicLayout;
