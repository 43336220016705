import React, { useState, useRef, useEffect } from "react";
import Icon from "../Icon";
import Badge from "../CounterBadge";
import { useRouter } from "next/router";
import Notifications from "../Notification";
import { useMutation } from "@apollo/client";
import { useMediaQuery } from "react-responsive";
import useClickOutside from "../useClickOutside";
import SmoothCollapse from "react-smooth-collapse";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../Redux/Actions/ltUserActions";
import { setCompanyDetails } from "../../Redux/Actions/companyActions";
import { selectTransformedNotifications } from "../../Redux/Selectors/notificationSelectors";
import {
  setNotifications,
  setHomeNotifications,
} from "../../Redux/Actions/notificationActions";
import {
  GET_COMPANY_NOTIFICATIONS_MUTATION,
  GET_USER_UNSEEN_NOTIFICATIONS_MUTATION,
} from "../../pages/api/mutations";
import {
  CLEAR_DATA,
  SET_SIDEBAR,
  CLEAR_DATA_LT,
  SET_LT_SIDEBAR,
} from "../../Redux/Types";

const Navbar = ({ openSidebar, isCustomCheck }) => {
  const el = useRef(null);
  const notificationTrayRef = useRef(null);

  const router = useRouter();
  const dispatch = useDispatch();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 481px)" });

  // States
  const [openSettingTray, setOpenSettingTray] = useState(false);
  const [openNotificationTray, setOpenNotificationTray] = useState(false);

  // Selectors
  const { user } = useSelector((state) => state.auth);
  const { dispatcherUser } = useSelector((state) => state.dispatcher);
  const { LtLoggedIn, LTuser } = useSelector((state) => state.ltUser);

  // Mutations
  const [getNotificationsMutation] = useMutation(
    GET_COMPANY_NOTIFICATIONS_MUTATION
  );
  const [getUserUnseenNotificationsMutation] = useMutation(
    GET_USER_UNSEEN_NOTIFICATIONS_MUTATION
  );
  const transformedNotifications = useSelector(selectTransformedNotifications);
  useEffect(() => {
    async function onLoad() {
      // Anything in here is fired on component mount.
      if ((!LTuser && !dispatcherUser) || localStorage.getItem("mainToken")) {
        const {
          data: { userUnseenNotificationsList },
        } = await getUserUnseenNotificationsMutation({
          variables: { page: 0 },
        });
        dispatch(setHomeNotifications(userUnseenNotificationsList));

        const {
          data: { notificationsList },
        } = await getNotificationsMutation({
          variables: { page: 0 },
        });
        dispatch(setNotifications(notificationsList));
      }
    }
    onLoad();
  }, []);

  useClickOutside(el, () => setOpenSettingTray(false));
  useClickOutside(notificationTrayRef, () => setOpenNotificationTray(false));

  const onClickSettings = () => {
    const DispatcherToken = localStorage.getItem("DispatcherToken");
    const LTtoken = localStorage.getItem("LTtoken");
    const mainToken = localStorage.getItem("mainToken");
    if (DispatcherToken)
      router.push(process.env.NEXT_PUBLIC_DISPATCHER_SETTINGS);
    else if (LTtoken && mainToken)
      router.push(process.env.NEXT_PUBLIC_SETTINGS);
    else if (LTtoken) router.push(process.env.NEXT_PUBLIC_LT_TEAM_SETTINGS);
    else router.push(process.env.NEXT_PUBLIC_SETTINGS);
  };

  const onClickLogout = () => {
    if (typeof window !== "undefined") {
      const LTtoken = localStorage.getItem("LTtoken");
      const DispatcherToken = localStorage.getItem("DispatcherToken");
      const mainToken = localStorage.getItem("mainToken");
      if (mainToken && LtLoggedIn) {
        localStorage.removeItem("userData");
        localStorage.removeItem("mainToken");
        dispatch({ type: CLEAR_DATA });
        dispatch({ type: SET_SIDEBAR });
        dispatch({ type: SET_LT_SIDEBAR });
        router.push(process.env.NEXT_PUBLIC_LT_TEAM_COMPANIES);
      } else if (LTtoken) {
        localStorage.removeItem("userData");
        localStorage.removeItem("LTtoken");
        router.push(process.env.NEXT_PUBLIC_LT_TEAM_LOGIN);
        dispatch({ type: CLEAR_DATA_LT });
        dispatch(setCompanyDetails(null));
        dispatch(setUser(null));
      } else if (DispatcherToken) {
        localStorage.removeItem("DispatcherToken");
        router.push(process.env.NEXT_PUBLIC_DISPATCHER_LOGIN);
        dispatch({ type: CLEAR_DATA });
      } else {
        localStorage.removeItem("mainToken");
        localStorage.removeItem("token");
        localStorage.removeItem("withOutDot");
        localStorage.removeItem("LTtoken");
        localStorage.removeItem("userData");

        dispatch({ type: CLEAR_DATA });
        dispatch({ type: SET_SIDEBAR });
        dispatch({ type: SET_LT_SIDEBAR });
        router.push(process.env.NEXT_PUBLIC_USER_LOGIN);
      }
    }
  };

  const avatarText = () => {
    const mainToken = localStorage.getItem("mainToken");
    let name =
      (LtLoggedIn || LTuser) && !mainToken
        ? LTuser?.name
        : dispatcherUser
        ? dispatcherUser?.name
        : `${user?.firstName} ${user?.lastName}`;
    let splittedName = name?.trim().split(" ");
    return (
      splittedName
        ?.slice(0, 2)
        ?.map((n) => n?.[0])
        .join("") || "LT"
    );
  };

  return (
    <header
      className={
        isTabletOrMobile
          ? `${
              process.env.NEXT_PUBLIC_THEME === "LT"
                ? "bg-Body"
                : "bg-flexportNavbar"
            } sticky top-0 h-16 flex flex-row items-center justify-between font-hkLight uppercase z-20 shadow-md duration-300 px-2`
          : `${
              process.env.NEXT_PUBLIC_THEME === "LT"
                ? "bg-Body"
                : "bg-flexportNavbar"
            } sticky top-0 h-16 flex flex-row ${
              openSidebar && !isCustomCheck ? "pl-navBar" : "pl-32"
            }   
            items-center sm:justify-center font-hkLight uppercase z-20 shadow-md duration-300 custom1:pr-0 md:pr-10`
      }
    >
      {isTabletOrMobile ? (
        <div
          className="flex items-center cursor-pointer"
          onClick={() => router.push("/")}
        >
          {process.env.NEXT_PUBLIC_THEME === "LT" ? (
            <>
              <img
                src="/Images/logo-circle.webp"
                width={"40px"}
                className="  rounded-full"
              />
              <img
                src="/Images/wordmark-luckytruck.webp"
                width={"180px"}
                height={"36px"}
                className="  rounded-full"
              />
            </>
          ) : (
            <img
              src="/Images/flexport/Flexport-Insurance.svg"
              width={"180px"}
              height={"36px"}
            />
          )}
        </div>
      ) : (
        <div
          className="flex items-center cursor-pointer"
          onClick={() =>
            dispatcherUser
              ? router.push(process.env.NEXT_PUBLIC_DISPATCHER_HOME)
              : LTuser && !LtLoggedIn
              ? process.env.NEXT_PUBLIC_ORGANIZATION === "Luckytruck"
                ? router.push(process.env.NEXT_PUBLIC_LT_HOME)
                : router.push(process.env.NEXT_PUBLIC_LT_COMPANIES)
              : router.push(process.env.NEXT_PUBLIC_USER_DASHBOARD)
          }
        >
          {process.env.NEXT_PUBLIC_THEME === "LT" ? (
            <>
              <img
                src="/Images/logo-circle.webp"
                width={"70px"}
                className="mt-1 rounded-full"
              />
              <img
                src="/Images/wordmark-luckytruck.webp"
                width={"220px"}
                className="rounded-full custom1:hidden"
              />
            </>
          ) : (
            <>
              <img src="/Images/flexport/Flexport-Insurance.svg" />
              <img src="/Images/flexport/Powerd-by-LT.png" width={"180px"} />
            </>
          )}
        </div>
      )}
      {isTabletOrMobile &&
      (!LTuser || localStorage.getItem("mainToken")) &&
      user?.company ? (
        <>
          <div
            className="relative cursor-pointer"
            onClick={() => {
              setOpenNotificationTray(!openNotificationTray);
              setOpenSettingTray(false);
            }}
          >
            {transformedNotifications?.notifications?.filter((n) => !n.seen)
              .length > 0 ? (
              <Badge
                counter={
                  transformedNotifications?.notifications?.filter(
                    (n) => !n.seen
                  ).length || null
                }
                color={"bg-errorColor"}
                absolute={true}
                top={"-top-3"}
                right={"-right-4"}
              />
            ) : null}

            <Icon name="icon-notification-inactive" />
          </div>
          {openNotificationTray ? (
            // <SmoothCollapse expanded={openNotificationTray}>
            <div className=" absolute right-4 top-10  ">
              <Notifications
                setOpenNotificationTray={setOpenNotificationTray}
                {...transformedNotifications}
              />
            </div>
          ) : // </SmoothCollapse>
          null}
        </>
      ) : null}

      {isTabletOrMobile && (
        <div
          className="relative cursor-pointer"
          onClick={() => {
            setOpenSettingTray(!openSettingTray);
            setOpenNotificationTray(false);
          }}
        >
          <img src="/Images/menu-button.webp" width={"45px"} height={"45px"} />

          {openSettingTray && (
            <SmoothCollapse expanded={openSettingTray}>
              <div
                className={`absolute rounded-lg shadow-lg bg-white -right-1 font-hkBold text-gray6 w-80 xs:w-64
                ${
                  LtLoggedIn && localStorage.getItem("mainToken")
                    ? "top-24"
                    : "top-16"
                } `}
                ref={el}
              >
                <p
                  className="hover:bg-hoverColor rounded-t-lg py-4 px-4"
                  onClick={onClickSettings}
                >
                  Settings
                </p>
                <p
                  className="hover:bg-hoverColor rounded-b-lg py-4 px-4"
                  onClick={onClickLogout}
                >
                  Log Out
                </p>
              </div>
            </SmoothCollapse>
          )}
        </div>
      )}

      {isTabletOrMobile ? null : (
        <>
          {(!LTuser && !dispatcherUser) || localStorage.getItem("mainToken") ? (
            <>
              <div
                className=" ml-auto relative cursor-pointer pr-12"
                onClick={() => {
                  setOpenNotificationTray(!openNotificationTray);
                }}
              >
                {transformedNotifications?.notifications?.filter((n) => !n.seen)
                  .length > 0 ? (
                  <Badge
                    counter={
                      transformedNotifications?.notifications?.filter(
                        (n) => !n.seen
                      ).length || null
                    }
                    color={"bg-errorColor"}
                    absolute={true}
                    top={"-top-3"}
                    right={"right-8"}
                  />
                ) : null}
                <Icon name="icon-notification-inactive" />
              </div>
              {openNotificationTray ? (
                <div className="  relative cursor-pointer  ">
                  <SmoothCollapse expanded={openNotificationTray}>
                    <div className=" absolute -right-15 ">
                      <Notifications
                        {...transformedNotifications}
                        setOpenNotificationTray={setOpenNotificationTray}
                        notificationTrayRef={notificationTrayRef}
                      />
                    </div>
                  </SmoothCollapse>
                </div>
              ) : null}
            </>
          ) : null}

          <div
            className={` ${
              (dispatcherUser || LTuser) && !localStorage.getItem("mainToken")
                ? "ml-auto"
                : localStorage.getItem("mainToken")
                ? ""
                : localStorage.getItem("mainToken")
                ? "ml-auto"
                : ""
            } relative cursor-pointer`}
            onClick={() => {
              setOpenSettingTray(!openSettingTray);
              setOpenNotificationTray(false);
            }}
          >
            <div className="w-10 h-10 bg-white border-2 border-borderColor2  rounded-full relative flex justify-center items-center">
              <div className=" p-4  w-8 h-8 shadow-lg  relative flex justify-center items-center rounded-full bg-gray4 text-lg text-white uppercase font-hkMedium">
                {avatarText()}
              </div>
            </div>
            {openSettingTray && (
              <SmoothCollapse expanded={openSettingTray}>
                <div
                  className={`absolute rounded-lg shadow-lg bg-white -right-6 font-hkBold text-gray6
                ${
                  LtLoggedIn && localStorage.getItem("mainToken")
                    ? "top-24"
                    : "top-16"
                } `}
                  style={{ width: "20vw" }}
                  ref={el}
                >
                  <p
                    className={`${
                      router.pathname.includes(
                        process.env.NEXT_PUBLIC_SETTINGS
                      ) && "text-bgActiveButton"
                    } hover:bg-hoverColor rounded-t-lg py-4 px-4`}
                    onClick={onClickSettings}
                  >
                    Settings
                  </p>
                  <p
                    className="hover:bg-hoverColor rounded-b-lg py-4 px-4"
                    onClick={onClickLogout}
                  >
                    Log Out
                  </p>
                </div>
              </SmoothCollapse>
            )}
          </div>
        </>
      )}
    </header>
  );
};

export default Navbar;
