import {
  UPDATE_CURRENT_APPLICATION_DATA,
  RESET_APPLICATION_OBJECT,
  SHOW_POLICY_OR_QUOTES,
  UPDATE_APPLICATION_IN_APPLICATIONS_FOR_FILE,
  SET_APPLICATIONS,
  DELETE_APPLICATION,
  UPDATE_APPLICATION_IN_APPLICATIONS,
  UPDATE_APPLICATION_PACKAGE,
} from "../Types";

export const updateCurrentApplicationData = (data) => ({
  type: UPDATE_CURRENT_APPLICATION_DATA,
  payload: data,
});

export const showPolicyOrQuotesFunc = (data) => ({
  type: SHOW_POLICY_OR_QUOTES,
  payload: data,
});

export const updateCurrentApplicationDataForFile = (data) => ({
  type: UPDATE_APPLICATION_IN_APPLICATIONS_FOR_FILE,
  payload: data,
});

export const resetAppObj = () => ({
  type: RESET_APPLICATION_OBJECT,
});

export const setCompanyApllications = (data) => ({
  type: SET_APPLICATIONS,
  payload: data,
});

export const deleteApplicationAction = (data) => ({
  type: DELETE_APPLICATION,
  payload: data,
});

export const updateApplicationAction = (data) => ({
  type: UPDATE_APPLICATION_IN_APPLICATIONS,
  payload: data,
});

export const updateApplicationPackageData = (data) => ({
  type: UPDATE_APPLICATION_PACKAGE,
  payload: data,
});
