export const saveUserDataInLocalStorage = (user, token, company, type) => {
  if (type === "company") {
    localStorage.setItem("userData", JSON.stringify(user));
    localStorage.setItem("token", token);
    const decoded = jwt_decode(token);
    localStorage.setItem("companyState", company.garagingState);
    localStorage.setItem("decodedToken", JSON.stringify(decoded));
    dispatch(saveUserDetailsAction(user));
  } else if (type === "local") {
    localStorage.setItem("userData", JSON.stringify(user));
    localStorage.setItem("token", token);
    const decoded = jwt_decode(token);
    localStorage.setItem("decodedToken", JSON.stringify(decoded));
    dispatch(saveUserDetailsAction(user));
  } else if (type === "dispatcher") {
    localStorage.setItem("dispatcherToken", token);
    localStorage.setItem("dispatcherData", JSON.stringify(user));
  }
};

export const FAQS = [
  {
    question: `I'm starting a trucking company - what kind of insurance do I need?`,
    answer: `To operate a trucking company in the U.S., the government mandates that you have Primary Auto Liability insurance with a minimum limit of $750,000. However, most trucking companies also purchase 1) Physical Damage, 2) Motor Truck Cargo, and 3) General Liability so that they have comprehensive coverage. Without these other coverages, you’re not fully protecting your truck, your cargo, and your business in the event of an accident.`,
  },
  {
    question: "How do I get started with LuckyTruck?",
    answer: `With LuckyTruck, starting is as easy as entering your name, DOT, email, and phone number. Start here!`,
  },
  {
    question: `What happens after I buy a policy?`,
    answer: `After binding your policy your Agent will ensure we have all of the post-bind documents needed such as Proof-of-Prior and Loss Runs. Once your Agent has collected all of the necessary documents, they will introduce you to your LuckyTruck Account Manager – a specialized insurance Agent who has been paired with you based on your business’s profile. Your Account Manager will handle all of your service needs such as generating COIs and processing endorsements.`,
  },
  {
    question: `How do I make a change to my policy?`,
    answer: `LuckyTruck’s goal is to make understanding and managing your insurance as easy as possible - that’s why we created the LuckyTruck Dashboard! After purchasing a policy, you can log into your dashboard at any time and directly make changes to your policy. Want to add a vehicle? Head to the Vehicles tab and add that freightliner! Need to remove an old driver? Go to the Drivers tab and click “remove” on the driver you’d like to take off the policy. It’s as simple as that!
    `,
  },
  {
    question: `Why is someone else's insurance less than mine?`,
    answer: `The price of insurance is based on dozens of factors - some obvious and some less obvious - and these factors vary between insurance carriers. Examples of factors include: vehicle value, driving record, type of commodities being hauled, deductible amounts, and years in business. Even the smallest change to one of these dozens of factors can lead to higher or lower insurance costs. To understand why your insurance is a certain price or  if you want to make sure your insurance accurately reflects your business, please reach out to your Account Manager`,
  },
  {
    question: `How do I get a Certificate of Insurance?`,
    answer: `After logging into your LuckyTruck dashboard, head over to the Certificate Holders tab. From there, you can click “Get a Certificate,” enter your certificate holder’s details, choose whether you’d also like to send this certificate to anyone else on your account, and then hit “Request.” This will generate a certificate of insurance tailored to your active policies, store it in your “Documents” section, and send it directly over to that Certificate Holder’s email address.`,
  },
  {
    question: `Can you help me grow my business and manage my insurance costs?`,
    answer: `As your broker, we aim to deeply understand your current business and what you have planned for the future so that we can best advise you on your insurance purchases and decisions. As your business grows, there are going to be a lot of insurance decisions that need to be made, but that’s what we are here for - we can advise you on which carrier to choose, how to adjust your active insurance policies, plan out renewal options, and so much more. We are here to help you make the right insurance decisions for your business today and for the years to come.`,
  },
];

export const mtcFAQS = [
  {
    question: `What is motor truck cargo insurance?`,
    answer: `A motor truck cargo policy protects against the risks of losses or damages to the commodities being hauled. `,
  },
  {
    question: "What does cargo insurance cover?",
    answer: `Cargo insurance covers damages or losses to cargo. Covered events might include natural disasters, vehicle accidents, cargo abandonment, customs rejection, acts of war, and piracy.`,
  },
  {
    question: `Who needs motor truck cargo insurance?`,
    answer: `Because there are a variety of risks commercial truck drivers may encounter on the road, from weather and equipment failures to unforeseen circumstances, all transporters should consider finding the best motor truck cargo insurance to protect their liability.`,
  },
  {
    question: `What does Motor truck Cargo Insurance not cover?`,
    answer: `Motor Truck Cargo insurance does not include garbage trucks, limos, hearses, buses, passenger vans or ice cream trucks. Cargo, like art, jewelry, money, or paper. Additionally, contraband, pharmaceuticals, tobacco, and alcohol are not covered.`,
  },
  {
    question: `Is Motor Truck Cargo Insurance available in my state?`,
    answer: `Motor truck cargo insurance is available in all states`,
  },
];

export const endorsementStatus = {
  OPEN: "Open",
  CLOSE: "Close",
};

export const referralStatus = {
  PENDING: "Pending",
  ELIGIBLE: "Eligible",
  REJECTED: "Rejected",
  COMPLETED: "Completed",
};

export const notificationType = {
  mvrResponse: "MVR_RESPONSE_FROM_CHECKR",
  policyCreated: "POLICY_CREATED_TYPE",
  nonInstantQuoteUpdate: "NON_INSTANT_QUTOE_UPDATE",
  quoteQuoted: "QUOTE_QUOTE_UPDATE_SUBSCRIPTION",
  endorsementResolved: "RESOLVED_ENDORSEMENT_SUBSCRIPTION",
  quoteError: "QUOTE_ERROR_SUBSCRIPTION",
  dispatcherRequest: "DISPATCHER_REQUEST",
};

export const endorsementTypes = [
  "Add Additional Insured",
  "Add Commodities",
  "Add Driver",
  "Add LOB",
  "Add LP",
  "Add Trailer",
  "Add Truck",
  "Addition on Policy",
  "Decrease Coverage",
  "Increase Coverage",
  "Name Change / Coverage Increase",
  "Other",
  "Remove Additional Insured",
  "Remove Commodities",
  "Remove Driver",
  "Remove LOB",
  "Remove LP",
  "Remove Trailer",
  "Remove Truck",
  "Update Commodities",
  "Restore Vehicle",
  "Restore Driver"
];
