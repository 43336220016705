import {
  SET_LOADING,
  SET_USER,
  SET_COMPANY_DETAILS,
  CLEAR_DATA,
  SET_DOT,
  CLEAR_DATA_WITHOUT_USER,
  SET_CREDIT,
} from "../Types";
import { setCompanyMapper } from "../Utils/companyUtils";

// import { setAndTransformPolicies, updatePolicyInState, updatePoliciesOnNotification } from "../Utils/policyUtils"

const INITIAL_STATE = {
  user: null,
  loading: false,
  dot: "",
  credit: false,
  company: null,
};

const AuthReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, user: action.payload };
    case SET_DOT:
      return { ...state, dot: action.payload };

    case SET_CREDIT:
      return { ...state, credit: action.payload };

    case SET_LOADING:
      return { ...state, loading: action.payload };
    case SET_COMPANY_DETAILS:
      return { ...state, company: setCompanyMapper(action.payload) };
    case CLEAR_DATA:
      return { ...INITIAL_STATE };
    case CLEAR_DATA_WITHOUT_USER:
      return { ...state, company: null };

    default:
      return state;
  }
};

export default AuthReducer;
