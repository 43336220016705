import React from "react";
import Icon from "../Icon";
import { useMediaQuery } from "react-responsive";
import { useRouter } from "next/router";
import Link from "next/link";
export default function DispatcherSideBar({ callback }) {
  const [open, setOpen] = React.useState(true);
  const [openSubMenu, setOpenSubMenu] = React.useState(false);
  const [index, setIndex] = React.useState(null);
  const router = useRouter();

  let Menu = [
    {
      title: "Dashboard",
      srcInActive: <Icon name={"icon-dashboard-inactive"} />,
      srcActive: <Icon name={"icon-dashboard-active"} />,
    },
    {
      title: "Request access",
      srcInActive: <Icon name={"icon-req-access"} />,
      srcActive: <Icon name={"icon-req-access-active"} />,
    },
  ];

  const onClickSubMenu = (i) => {
    setIndex(i);
    setOpen(true);
    callback(true);
    setOpenSubMenu(!openSubMenu);
  };

  const loadIcon = (menu, index) => {
    if (index === 0) {
      if (router.pathname === "/" || router.pathname.includes("/Home")) {
        return Menu[0].srcActive;
      } else {
        return Menu[0].srcInActive;
      }
    } else if (index === 1) {
      if (router.pathname === "/Dispatcher/RequestAccess") {
        return Menu[1].srcActive;
      } else {
        return Menu[1].srcInActive;
      }
    }
  };
  return (
    <div
      className={`h-full rounded-r-lg fixed z-30 ${open ? " w-64" : "w-20"} ${
        process.env.NEXT_PUBLIC_THEME === "LT"
          ? "bg-gray4"
          : "bg-flexportNavbar"
      } duration-300 xs:hidden`}
    >
      <img
        src={`${
          !open
            ? `${
                process.env.NEXT_PUBLIC_THEME == "LT"
                  ? "/Images/Tab-open.webp"
                  : "/Images/flexport/Tab-open.webp"
              }`
            : `${
                process.env.NEXT_PUBLIC_THEME == "LT"
                  ? "/Images/Tab-close.webp"
                  : "/Images/flexport/Tab-close.webp"
              }`
        }`}
        onClick={() => {
          setOpen(!open);
          callback(!open);
          setOpenSubMenu(false);
        }}
        alt="truck"
        className={` absolute cursor-pointer -right-10 top-2 w-12 h-12`}
      />
      {!open ? (
        <div className=" flex items-center mt-20 ml-2">
          <img
            src="/Images/logo-circle.webp"
            width={"50px"}
            height={"50px"}
            className={`rounded-full ${!open && "rounded-t-full"}`}
          />
        </div>
      ) : null}
      <ul className={`${open ? "pt-28 space-y-4 " : " pt-10 space-y-4 "}`}>
        {Menu?.map((menu, mainIndex) => (
          <>
            <Link
              href={`/${
                menu.title === "Dashboard"
                  ? process.env.NEXT_PUBLIC_DISPATCHER_HOME
                  : menu.title === "Request access"
                  ? "/Dispatcher/RequestAccess"
                  : menu.title
              }`}
            >
              <li
                key={mainIndex}
                className={`text-white text-sm flex items-center ${
                  open ? "pl-8" : "pl-15"
                }   gap-x-4  cursor-pointer py-2 mx-3 hover:bg-bgInactiveButton rounded-md relative`}
                onClick={() => {
                  if (router.pathname === "/" && mainIndex === 0) {
                    onClickSubMenu(mainIndex);
                  }
                }}
              >
                {router.pathname.includes("/Home") && mainIndex === 0 ? (
                  <div className="bg-bgActiveButton w-2 h-8 rounded-l-none rounded-r-md absolute -left-3" />
                ) : null}
                {router.pathname === "/Dispatcher/RequestAccess" &&
                mainIndex === 1 ? (
                  <div className="bg-bgActiveButton w-2 h-8 rounded-l-none rounded-r-md absolute -left-3" />
                ) : null}

                <div className=" flex flex-row gap-x-4">
                  {loadIcon(menu, mainIndex)}

                  <span
                    className={`w-fit ${
                      !open && "hidden"
                    } origin-left duration-200 font-hkBold text-lg`}
                  >
                    {menu.title}
                  </span>
                </div>
              </li>
            </Link>
          </>
        ))}
      </ul>
    </div>
  );
}
