import { addNewNoteHandler } from "../Utils/notesUtils";
import { SET_NOTES, CLEAR_DATA, ADD_NEW_NOTE } from "../Types";

const INITIAL_STATE = {
  notes: [],
};

const notesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_NOTES:
      return { ...state, notes: [...action.payload] };
    case ADD_NEW_NOTE:
      return addNewNoteHandler(state, action.payload);
    case CLEAR_DATA:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export default notesReducer;
