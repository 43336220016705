import { compose, applyMiddleware } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./index";
import { persistStore } from "redux-persist";
const { persistReducer } = require("redux-persist");
import { createLogger } from "redux-logger";
let store;
let persistor;
const isClient = typeof window !== "undefined";
const composeEnhancers = (typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
if (isClient) {
  const storage = require("redux-persist/lib/storage").default;

  const persistConfig = {
    key: "rooty",
    storage,
  };
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  if (typeof window !== "undefined" && process.env.NEXT_PUBLIC_NODE_ENV == "production") {
    store = configureStore({ reducer: persistedReducer });
    persistor = persistStore(store);
  } else {
    store = configureStore({
      reducer: persistedReducer,
      devTools: composeEnhancers(applyMiddleware(createLogger())),
    });
    persistor = persistStore(store);
  }
} else {
  store = configureStore({ reducer: rootReducer });
}
export { persistor };
export default store;
