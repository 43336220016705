import React from "react";
import Notes from "../Notes";
import Badge from "../CounterBadge";
import { useRouter } from "next/router";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { setNotesFlag } from "../../Redux/Actions/companyActions";
import { selectNotesList } from "../../Redux/Selectors/notesSelectors";
import { CLEAR_DATA, SET_LT_SIDEBAR, SET_SIDEBAR } from "../../Redux/Types";

const LTLoggedInUserBar = ({ openSidebar, LTuser }) => {
  const router = useRouter();
  const dispatch = useDispatch();

  const notes = useSelector(selectNotesList);
  const { company } = useSelector((state) => state.auth);
  const { notesFlag } = useSelector((state) => state.company);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 481px)" });

  // Extracting action types for better readability
  const clearDataAction = { type: CLEAR_DATA };
  const setSidebarAction = { type: SET_SIDEBAR };
  const setLtSidebarAction = { type: SET_LT_SIDEBAR };

  const setNotes = (value) => dispatch(setNotesFlag(value));
  const onExitAccess = () => {
    if (typeof window !== "undefined") {
      const token = localStorage.getItem("mainToken");
      localStorage.removeItem("getTow");
      if (token) {
        localStorage.removeItem("mainToken");
        router.push(process.env.NEXT_PUBLIC_LT_TEAM_COMPANIES);

        // Dispatching multiple actions at once
        dispatch(clearDataAction);
        dispatch(setSidebarAction);
        dispatch(setLtSidebarAction);
      }
    }
  };

  return (
    <header
      className={`${
        process.env.NEXT_PUBLIC_THEME === "LT"
          ? "bg-bgActiveButton"
          : "bg-flextportColor"
      } sticky top-16 sm:h-10 xs:h-12 flex flex-row items-center uppercase z-10 shadow-md duration-300 lg:text-base xl:text-base text-white font-hkBold xs:font-hkMedium xs:text-sm
        ${
          isTabletOrMobile
            ? `justify-between px-2`
            : `xs:justify-center custom:text-xs custom5:text-xs sm:justify-between md:justify-between pr-20 xs:pr-4 gap-x-4 xs:pl-0 md:text-sm ${
                openSidebar ? "pl-72" : "pl-32"
              }`
        }`}
    >
      <div>{`BROKER VIEW: ${LTuser?.name}`}</div>
      <div>{`LOGGED IN AS ${company?.accountName}`}</div>
      <div className="flex gap-16 relative">
        {/* Notes Section */}
        <div
          onClick={() => setNotes(notesFlag == null ? true : !notesFlag)}
          className="flex flex-row items-center justify-center space-x-2 cursor-pointer bg-white text-inActiveTab rounded-md px-2 py-0.5"
        >
          <h4> NOTES</h4>
          <Badge
            counter={notes.length}
            color="bg-badgeColor"
            text="text-inActiveTab"
          />
        </div>
        {notesFlag && (
          <Notes company={company} showNotes={setNotes} notesFlag={notesFlag} />
        )}

        {/* Exit Section */}
        <div
          onClick={onExitAccess}
          className="flex flex-row items-center justify-center space-x-2 cursor-pointer bg-white text-inActiveTab rounded-md px-3"
        >
          <h4 className="text-center"> EXIT</h4>
        </div>
      </div>
    </header>
  );
};

export default LTLoggedInUserBar;
