import React from "react";

export default function Badge({
  counter,
  top = "",
  left = "",
  right = "",
  bottom = "",
  absolute = false,
  text = "text-white",
  color = "bg-counterColor",
}) {
  return (
    <div
      className={`rounded-full w-6 h-6 p-badge ${color} ${text} text-center font-PoppinsSemiBold text-base ${
        absolute && `absolute ${top} ${bottom} ${left} ${right}`
      }`}
    >
      {counter}
    </div>
  );
}
