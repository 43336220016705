import {
  SET_LTUSER_COMPANIES,
  SET_BACK_PRESSED,
  SET_BACK_PRESSED_WITH_SEARCH,
  SET_LTUSER,
  SET_LT_LOGGED_IN,
  UPDATE_LTUSER_COMPANIES_PAYLOAD,
  SET_LTUSER_COMPANY_SEARCH_RESULTS,
  UPDATE_LTUSER_COMPANY_SEARCH_RESULTS_PAYLOAD,
  CLEAR_LTUSER_COMPANY_SEARCH_RESULTS,
  SET_USER,
  SET_PLATFORM_FEE_DATA,
} from "../Types";

//Action Creator

export const setLTUserCompanies = (data) => ({
  type: SET_LTUSER_COMPANIES,
  payload: data,
});

export const setLTLoggedIn = (data) => ({
  type: SET_LT_LOGGED_IN,
  payload: data,
});

export function setUser(payload) {
  return function (dispatch) {
    dispatch({
      type: SET_USER,
      payload: payload,
    });
  };
}

export const updateLTUserCompaniesPayload = (data) => ({
  type: UPDATE_LTUSER_COMPANIES_PAYLOAD,
  payload: data,
});
export const setLTUser = (data) => ({
  type: SET_LTUSER,
  payload: data,
});

export const setCompanySearchResults = (data) => ({
  type: SET_LTUSER_COMPANY_SEARCH_RESULTS,
  payload: data,
});
export const updateLTUserCompaniesSearchPayload = (data) => ({
  type: UPDATE_LTUSER_COMPANY_SEARCH_RESULTS_PAYLOAD,
  payload: data,
});
export const setBackButton = (data) => ({
  type: SET_BACK_PRESSED,
  payload: data,
});

export const setBackButtonWithSearch = (data) => ({
  type: SET_BACK_PRESSED_WITH_SEARCH,
  payload: data,
});
export const setPlatformFeeData = (data) => ({
  type: SET_PLATFORM_FEE_DATA,
  payload: data,
});

export const clearSearchResults = () => ({
  type: CLEAR_LTUSER_COMPANY_SEARCH_RESULTS,
});
