import { createSelector } from "reselect";

import {
  getActivePolicies,
  activePoliciesTotalPaid,
  activePoliciesTotal,
} from "../Utils/policyUtils";

const selectPolicy = (state) => state.policy;

export const selectActivePolicies = createSelector([selectPolicy], (policy) =>
  getActivePolicies(policy.policies, "active")
);

export const selectPrevPolicies = createSelector([selectPolicy], (policy) =>
  getActivePolicies(policy.policies, "previous")
);

export const selectPolicyTotalPaid = createSelector([selectPolicy], (policy) =>
  activePoliciesTotalPaid(policy.policies)
);
export const selectPolicyTotal = createSelector([selectPolicy], (policy) =>
  activePoliciesTotal(policy.policies)
);
