import {
  SET_OPEN_ENDORSEMENTS,
  SET_CLOSE_ENDORSEMENTS,
  SET_CLOSE_ENDORSEMENTS_COUNT,
  SET_OPEN_ENDORSEMENTS_COUNT,
  CLEAR_DATA,
} from "../Types";

const INITIAL_STATE = {
  openEndorsements: [],
  closeEndorsements: [],
  openEndorsementCount: 0,
  closeEndorsementCount: 0,
};

const endorsementReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_OPEN_ENDORSEMENTS:
      return {
        ...state,
        openEndorsements: action.payload,
      };

    case SET_CLOSE_ENDORSEMENTS:
      return {
        ...state,
        closeEndorsements: action.payload,
      };

    case SET_CLOSE_ENDORSEMENTS_COUNT:
      return {
        ...state,
        closeEndorsementCount: action.payload,
      };

    case SET_OPEN_ENDORSEMENTS_COUNT:
      return {
        ...state,
        openEndorsementCount: action.payload,
      };
    case CLEAR_DATA:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

export default endorsementReducer;
