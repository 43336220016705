import { CLEAR_DATA, SET_ELDPROVIDER } from "../Types";

const INITIAL_STATE = {
  eldProvider: [],
};

const EldProviderReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ELDPROVIDER:
      return { ...state, eldProvider: [...action.payload] };
    case CLEAR_DATA:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export default EldProviderReducer;
