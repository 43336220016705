import {
  SET_PENDING_REFERRALS,
  SET_REJECTED_REFERRALS,
  SET_ELIGIBLE_REFERRALS,
  SET_COMPLETED_REFERRALS,
  SET_PENDING_REFERRALS_COUNT,
  SET_REJECTED_REFERRALS_COUNT,
  SET_ELIGIBLE_REFERRALS_COUNT,
  SET_COMPLETED_REFERRALS_COUNT,
  CLEAR_DATA,
} from "../Types";

const INITIAL_STATE = {
  pendingReferrals: [],
  rejectedReferrals: [],
  eligibleReferrals: [],
  completedReferrals: [],
  pendingReferralCount: 0,
  rejectedReferralCount: 0,
  eligibleReferralCount: 0,
  completedReferralCount: 0,
};

const referralReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_COMPLETED_REFERRALS:
      return {
        ...state,
        completedReferrals: action.payload,
      };

    case SET_PENDING_REFERRALS:
      return {
        ...state,
        pendingReferrals: action.payload,
      };

    case SET_REJECTED_REFERRALS:
      return {
        ...state,
        rejectedReferrals: action.payload,
      };

    case SET_ELIGIBLE_REFERRALS:
      return {
        ...state,
        eligibleReferrals: action.payload,
      };

    case SET_COMPLETED_REFERRALS_COUNT:
      return {
        ...state,
        completedReferralCount: action.payload,
      };

    case SET_PENDING_REFERRALS_COUNT:
      return {
        ...state,
        pendingReferralCount: action.payload,
      };

    case SET_REJECTED_REFERRALS_COUNT:
      return {
        ...state,
        rejectedReferralCount: action.payload,
      };

    case SET_ELIGIBLE_REFERRALS_COUNT:
      return {
        ...state,
        eligibleReferralCount: action.payload,
      };
    case CLEAR_DATA:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
};

export default referralReducer;
