export const updateViewMore = (totalCompanies, resultCount) => {
  if (totalCompanies < resultCount - 1) {
    return true;
  } else {
    return false;
  }
};

export const updateBackButtonCompanies = (totalCompanies, currentPage) => {
  let firstIndex = (currentPage - 2) * 3;
  let check = totalCompanies.slice(firstIndex, firstIndex + 3);
  return totalCompanies.slice(firstIndex, firstIndex + 3);
};
export const updateBackButtonWithSearchCompanies = (companies, currentPage) => {
  let firstIndex = (currentPage - 2) * 3;
  return companies.slice(firstIndex, firstIndex + 3);
};

export const updateListPage = (stateListPage, result) => {
  if (result?.length > 0) {
    return (stateListPage = stateListPage + 1);
  }
};

export const updateLTUserPayload = (state, payload) => {
  const prevCompanies = [...state.totalCompanies];
  return [...prevCompanies, ...payload.companies];
};

export const updateLTUserPayloadOnBack = (state) => {
  return state.totalCompanies?.filter(
    (obj1) => !state.companies.some((obj2) => obj2.id === obj1.id)
  );
};

export const onlyLettersAndSpaces = (str) => {
  return /\s/.test(str);
};

export const updateLTUserSearchPayload = (state, payload) => {
  const prevCompanies = [...state.totalSearchedCompanies];
  return [...prevCompanies, ...payload.companies];
};
