import { gql } from "@apollo/client";

export const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!, $org: Organizations) {
    login(email: $email, password: $password, org: $org) {
      token
      auth {
        id
        role
        accessAllowed
        email
        phone
      }
      lead {
        id
        zohoId
        account {
          id
        }
        contact {
          id
        }
        otherLeads {
          id
        }
        parentLeadId
        parentLead {
          id
        }
        activePendingList
        havePrevAuthority
        annualPayroll
        annualRevenue
        authorityHistoryList
        avgTimeSpent
        garagingCity
        garagingCounty
        garagingMail
        garagingNumberOfUnits
        garagingState
        garagingStreet
        garagingZipCode
        bipdInsuranceOnFile
        bipdInsuranceRequired
        bondInsuranceOnFile
        bondInsuranceRequired
        brokerAppPending
        brokerAuthStatus
        businessDescription
        businessEntityType
        cargo
        cargoCarried
        cargoCarrier
        cargoInsuranceOnFile
        cargoInsuranceRequired
        cargoLimits
        carrierOperation
        city
        commonAppPending
        commonAuthStatus
        company
        contactTitle
        contractAppPending
        contractAuthStatus
        convertedAccount
        convertedContact
        convertedDeal
        county
        crashedFatal
        crashedInjury
        crashedTotal
        crashedTow
        createdBy
        daysVisited
        dbaName
        designation
        dotNumber
        dotRegistration
        dunsNumber
        email
        emailNotValid
        emailOptOut
        expiringInsuranceCompany
        fax
        firstName
        firstPageVisited
        firstVisit
        fullTimeEmployees
        inspectionsDriver
        inspectionsHazmat
        inspectionsLep
        InspectionsVehicle
        insuranceHistoryListData
        insuranceType
        lastKnownAutoExpirationDate
        lastKnownCargoExpirationDate
        lastKnownWcExpirationDate
        lastName
        layout
        leadCost
        leadOwner
        leadSource
        leadSourceLeadId
        leadStatus
        leadType
        liabilityCarrier
        liabilityLimits
        limitsRequired
        listCampaignType
        mailZipCode
        mailingCity
        mailingCounty
        mailingMail
        mailingNumberOfUnits
        mailingState
        garagingCity
        garagingState
        garagingStreet
        garagingZipCode
        mailingStreet
        marketingAutoScore
        maximumRadiusOfOperations
        mcNumber
        mcs150FormDate
        mobile
        modifiedBy
        mostRecentVisit
        naicCode
        natlAverageDriver
        natlAverageHazmat
        natlAverageLep
        natlAverageVehicle
        noOfEmployees
        numberOfChats
        numberOfDrivers
        numberOfUnits
        operatingStatus
        operationClassification
        outOfServiceDriver
        outOfServiceHazmat
        outOfServiceLep
        outOfServicePercentageDriver
        outOfServicePercentageHazmat
        outOfServicePercentageLep
        outOfServicePercentageVehicle
        outOfServiceVehicle
        ownerYearsOfExperience
        partTimeEmployees
        pendingApplicationList
        phone
        physicalMail
        policyNumber
        referrer
        rejectionInsuranceList
        revocationListData
        sdrLead
        sicCode
        spId
        spTier
        spType
        state
        street
        subContractorCosts
        visitorScore
        website
        yearsInBusiness
        zipCode
        org
        auth {
          id
        }
        createdAt
        updatedAt
      }
      contact {
        id
        firstName
        lastName
        email
        phone
        mobile
        role
      }
      account {
        id
        accountManager
        leadId
        dayAMTransitionEdit
        havePrevAuthority
        policyChangeData
        recordImage
        accountName
        notes1
        activePendingList
        accountNumber
        owner
        accountShortName
        accountSite
        accountStage
        accountType
        activityLevel
        alternateContact
        annualPayroll
        annualRevenue
        garagingCity
        garagingCounty
        garagingMail
        garagingNumberOfUnits
        garagingState
        garagingStreet
        garagingZipCode
        businessEntityType
        cargo
        cargoLimits
        claimsLink
        comments
        commodity
        createdAt
        additionalInsured
        lossPayee
        eldProvider
        additionalInsuredSubFields
        uiiaAccount
        injuryProtectionAmount
        breakdownCoverage
        specialVerbiage
        brokerAuthorityStatus
        brokerApplicationPending
        bondInsuranceRequired
        bondInsuranceOnFile
        bondAuthorityStatus
        bondApplicationPending
        bipdInsuranceRequired
        bipdInsuranceOnFile
        bipdAuthorityStatus
        bipdApplicationPending
        carrierOperation
        cargoInsuranceRequired
        cargoInsuranceOnFile
        cargoCarried
        cargoAuthorityStatus
        cargoApplicationPending
        zipCode
        street
        state
        rejectionInsuranceList
        pendingApplicationList
        outOfServiceVehicle
        outOfServicePercentageVehicle
        outOfServicePercentageLEP
        outOfServicePercentageHazmat
        outOfServicePercentageDriverr
        outOfServiceLEP
        outOfServiceHazmat
        outOfServiceDriver
        operationClassification
        operatingStatus
        numberOfDrivers
        natlAverageVehicle
        natlAverageLEP
        natlAverageHazmat
        natlAverageDriver
        mcs150FormDate
        mailingState
        mailingCounty
        mailingCity
        mailZipCode
        insuranceHistoryListData
        inspectionsVehicle
        inspectionsLEP
        inspectionsHazmat
        inspectionsDriver
        dunsNumber
        crashedTow
        crashedTotal
        crashedInjury
        crashedFatal
        contractAuthorityStatus
        contractApplicationPending
        commonAuthorityStatus
        commonApplicationPending
        city
        yearsInBusiness
        website
        web
        underwritingCompanies
        totalActivePremium
        tickerSymbol
        tag
        subform1
        similarInvestments
        sicCode
        shippingStreet
        shippingState
        shippingCountry
        shippingCode
        shippingCity
        seriesPreference
        saferScore
        revenueSize
        resourceAndInvestmentNeeded
        resourceAndInvestmentDescription
        referredBy
        referedBy
        rating
        prioritization
        platformAccountUsers
        platformAccountID
        phone
        paymentBasedOn
        partnershipType
        parentAccount
        ownership
        ownerYearsOfExperience
        numberOfUnits
        numberOfEmployees
        naicCode
        monetizationDescriptions
        modifiedBy
        minInvestment
        mcNumber
        partnerContact
        partnerContactEmail
        opportunityDescription
        partnerContactPhone
        contract
        createdBy
        dbaName
        dealTerms
        description
        dotNumber
        dotNumber1
        ebitda
        eldVendor
        employees
        estimateAnnualPartnershipRevenue
        estimatedAnnualPartnershipRevenueToLT
        estimatedNumberOfLeadsToLT
        fax
        garagingCity
        garagingState
        garagingStreet
        garagingZipCode
        industry
        investmentThesis
        layout
        leadCost
        leadSource
        legacyAccount
        liabilityLimits
        limitsRequired
        maType
        maStage
        maxInvestment
      }
    }
  }
`;

export const PORTAL_AUTO_LOGIN_MUTATION = gql`
  mutation platformAutoLogin($token: String!) {
    platformAutoLogin(token: $token) {
      token
      auth {
        id
        role
        accessAllowed
        email
        phone
      }
      lead {
        id
        zohoId
        account {
          id
        }
        contact {
          id
        }
        otherLeads {
          id
        }
        parentLeadId
        parentLead {
          id
        }
        activePendingList
        havePrevAuthority
        annualPayroll
        annualRevenue
        authorityHistoryList
        avgTimeSpent
        garagingCity
        garagingCounty
        garagingMail
        garagingNumberOfUnits
        garagingState
        garagingStreet
        garagingZipCode
        bipdInsuranceOnFile
        bipdInsuranceRequired
        bondInsuranceOnFile
        bondInsuranceRequired
        brokerAppPending
        brokerAuthStatus
        businessDescription
        businessEntityType
        cargo
        cargoCarried
        cargoCarrier
        cargoInsuranceOnFile
        cargoInsuranceRequired
        cargoLimits
        carrierOperation
        city
        commonAppPending
        commonAuthStatus
        company
        contactTitle
        contractAppPending
        contractAuthStatus
        convertedAccount
        convertedContact
        convertedDeal
        county
        crashedFatal
        crashedInjury
        crashedTotal
        crashedTow
        createdBy
        daysVisited
        dbaName
        designation
        dotNumber
        dotRegistration
        dunsNumber
        email
        emailNotValid
        emailOptOut
        expiringInsuranceCompany
        fax
        firstName
        firstPageVisited
        firstVisit
        fullTimeEmployees
        inspectionsDriver
        inspectionsHazmat
        inspectionsLep
        InspectionsVehicle
        insuranceHistoryListData
        insuranceType
        lastKnownAutoExpirationDate
        lastKnownCargoExpirationDate
        lastKnownWcExpirationDate
        lastName
        layout
        leadCost
        leadOwner
        leadSource
        leadSourceLeadId
        leadStatus
        leadType
        liabilityCarrier
        liabilityLimits
        limitsRequired
        listCampaignType
        mailZipCode
        mailingCity
        mailingCounty
        mailingMail
        mailingNumberOfUnits
        mailingState
        garagingCity
        garagingState
        garagingStreet
        garagingZipCode
        mailingStreet
        marketingAutoScore
        maximumRadiusOfOperations
        mcNumber
        mcs150FormDate
        mobile
        modifiedBy
        mostRecentVisit
        naicCode
        natlAverageDriver
        natlAverageHazmat
        natlAverageLep
        natlAverageVehicle
        noOfEmployees
        numberOfChats
        numberOfDrivers
        numberOfUnits
        operatingStatus
        operationClassification
        outOfServiceDriver
        outOfServiceHazmat
        outOfServiceLep
        outOfServicePercentageDriver
        outOfServicePercentageHazmat
        outOfServicePercentageLep
        outOfServicePercentageVehicle
        outOfServiceVehicle
        ownerYearsOfExperience
        partTimeEmployees
        pendingApplicationList
        phone
        physicalMail
        policyNumber
        referrer
        rejectionInsuranceList
        revocationListData
        sdrLead
        sicCode
        spId
        spTier
        spType
        state
        street
        subContractorCosts
        visitorScore
        website
        yearsInBusiness
        zipCode
        org
        auth {
          id
        }
        createdAt
        updatedAt
      }
      contact {
        id
        firstName
        lastName
        email
        phone
        mobile
        role
      }
      account {
        id
        accountManager
        leadId
        dayAMTransitionEdit
        havePrevAuthority
        policyChangeData
        recordImage
        accountName
        notes1
        activePendingList
        accountNumber
        owner
        accountShortName
        accountSite
        accountStage
        accountType
        activityLevel
        alternateContact
        annualPayroll
        annualRevenue
        garagingCity
        garagingCounty
        garagingMail
        garagingNumberOfUnits
        garagingState
        garagingStreet
        garagingZipCode
        businessEntityType
        cargo
        cargoLimits
        claimsLink
        comments
        commodity
        createdAt
        additionalInsured
        lossPayee
        eldProvider
        additionalInsuredSubFields
        uiiaAccount
        injuryProtectionAmount
        breakdownCoverage
        specialVerbiage
        brokerAuthorityStatus
        brokerApplicationPending
        bondInsuranceRequired
        bondInsuranceOnFile
        bondAuthorityStatus
        bondApplicationPending
        bipdInsuranceRequired
        bipdInsuranceOnFile
        bipdAuthorityStatus
        bipdApplicationPending
        carrierOperation
        cargoInsuranceRequired
        cargoInsuranceOnFile
        cargoCarried
        cargoAuthorityStatus
        cargoApplicationPending
        zipCode
        street
        state
        rejectionInsuranceList
        pendingApplicationList
        outOfServiceVehicle
        outOfServicePercentageVehicle
        outOfServicePercentageLEP
        outOfServicePercentageHazmat
        outOfServicePercentageDriverr
        outOfServiceLEP
        outOfServiceHazmat
        outOfServiceDriver
        operationClassification
        operatingStatus
        numberOfDrivers
        natlAverageVehicle
        natlAverageLEP
        natlAverageHazmat
        natlAverageDriver
        mcs150FormDate
        mailingState
        mailingCounty
        mailingCity
        mailZipCode
        insuranceHistoryListData
        inspectionsVehicle
        inspectionsLEP
        inspectionsHazmat
        inspectionsDriver
        dunsNumber
        crashedTow
        crashedTotal
        crashedInjury
        crashedFatal
        contractAuthorityStatus
        contractApplicationPending
        commonAuthorityStatus
        commonApplicationPending
        city
        yearsInBusiness
        website
        web
        underwritingCompanies
        totalActivePremium
        tickerSymbol
        tag
        subform1
        similarInvestments
        sicCode
        shippingStreet
        shippingState
        shippingCountry
        shippingCode
        shippingCity
        seriesPreference
        saferScore
        revenueSize
        resourceAndInvestmentNeeded
        resourceAndInvestmentDescription
        referredBy
        referedBy
        rating
        prioritization
        platformAccountUsers
        platformAccountID
        phone
        paymentBasedOn
        partnershipType
        parentAccount
        ownership
        ownerYearsOfExperience
        numberOfUnits
        numberOfEmployees
        naicCode
        monetizationDescriptions
        modifiedBy
        minInvestment
        mcNumber
        partnerContact
        partnerContactEmail
        opportunityDescription
        partnerContactPhone
        contract
        createdBy
        dbaName
        dealTerms
        description
        dotNumber
        dotNumber1
        ebitda
        eldVendor
        employees
        estimateAnnualPartnershipRevenue
        estimatedAnnualPartnershipRevenueToLT
        estimatedNumberOfLeadsToLT
        fax
        garagingCity
        garagingState
        garagingStreet
        garagingZipCode
        industry
        investmentThesis
        layout
        leadCost
        leadSource
        legacyAccount
        liabilityLimits
        limitsRequired
        maType
        maStage
        maxInvestment
      }
    }
  }
`;

export const GET_SINGLE_APPLICATION = gql`
  mutation fetchSingleApplication($appId: ID!) {
    fetchSingleApplication(appId: $appId) {
      id
      stage
      activeAuth
      averageViolations
      doTheseApply
      doTheseApplyCw
      preferedCarrier
      priorityStatus
      operationsDescription
      driversAbove23
      cdlExperience
      anySeriousViolations
      currentProgCust
      businessStructure
      driverSummary
      totalValueTrucks
      totalValueTrailers
      grossAnnualTruckRev
      annualTotalMileage
      haulsUnder50Miles
      haulsUnder200Miles
      haulsUnder500Miles
      haulsAbove500Miles
      currentlyInsured
      otherCoverages
      totalAdditionalInsureds
      totalNamedInsureds
      blanketAdditionalNeeded
      blanketWaiverSubrogations
      stateOrFederalFilingsReq
      snapshotPreview
      effectiveDate
      towingLaborStorageLimit
      trailerInterchangeLimit
      autoLiabilityLossCount
      autoLiabilityLossPaid
      physicalDamageLossCount
      physicalDamageLossPaid
      cargoLossCount
      cargoLossPaid
      truckerGenLiabLossCount
      truckerGenLiabLossPaid
      lossesConfirmedVsPending
      createdAt
      updatedAt
      isTest
      isThereRigging
      numberLosses3Years
      currentPage
      numberDrivers
      numberPowerUnits
      lobs
      avgRadOps
      domiciledWithin50Miles
      limitInsPerPowerUnit
      limitInsPerPowerUnitNonOwned
      perOccurenceLimit
      deductible
      spoilageFreezingCoverage
      mtcAdditionalCoverage
      pollutionCleanUp
      ownersGoodExtension
      nonOwnedTrailer
      expirationDate
      disclaimer
      status
      typeOfOps
      typeOfOpsDescriptionOther
      typeOfOpsFao
      glOrBop
      yearCurrentBusiness
      limitAl
      limitPd
      limitMtc
      limitGl
      limitNtl
      trailerInterchangeDeductible
      deductibleAl
      deductiblePd
      deductibleMtc
      deductibleGl
      deductibleNtl
      numberLossesPast3YearsGA
      # tarpsAndChains
      # reusablePackingContainers
      # subHaulerOwnerOrOperator
      # numberOfNonOwnedTrailers
      # debrisRemovalLimitGA
      # reloadingLimitGA
      # pollutionCleanUpLimitGA
      # earnedFreightLimitGA
      # fireDepartmentServiceChargesLimitGA
      # lossDataPreparationLimitGA
      # rewardCoverageLimitGA
      # reusablePackingContainersLimitGA
      # tarpsAndChainsLimitGA
      # annualValueShippedGA
      # totalCargoLossesInLast36MonthsGA
      continuousCoverage
      DOTObtain60Days
      typeOfTrucker
      ineligibleOperations
      vehicleHaulOilAndGas
      hazardousMaterialPlacard
      isCEODriver
      isCEOInvolvedInOperations
      bodilyInjuryLiabilityLimit
      otherGlOrBop
      priorInsuranceCancelledOrRenewed
      seriousViolationsWithinPast5Years
      ELDVendorName
      LossControlTechnology
      ELDRequiredToRecord
      commercialVehiclesInsuredOwns
      privatePassengerAutosInsured
      insuredVehiclesUsesInBusiness
      federal
      federalCargo
      progressiveState
      progressiveStateCargo
      progressiveOther
      progressiveEligibilityCriteria
      progressivePolicyState
      progressiveELDVendor
      ELDVendorAccess
      customerSpendLastYear
      nonOwnedVehiclesUsedInBusiness
      cargoCoverageForMobileHomes
      progressiveTrailerInterchangeCoverage
      trailerInterchangeFurnishAgreement
      nonOwnedTrailerPhysicalDamage
      MCS90
      isGAKickOut
      isCWKickOut
      cwLosses
      motorTruckCargoLimit
      towingLaborStorageLimitstr
      trailerInterchangeLimitstr
      trailerInterchangeLimitProgressive
      isProgKickOut
      vehiclesTowingOrHauling
      vehiclesHualSteel
      previousClaimCw
      totalAccidents
      totalNumberViolations
      totalSuspensions
      primaryAndNoncontributoryForTGL
      primaryAndNoncontributoryForAL
      requireFederalFillingForAL
      requireStateFillingForAL
      hauledRefrigeratedGoods
      ownerFname
      ownerLname
      ownerEmail
      ownerDob
      appStack
      appFlow
      insuredWithNICO
      policyNumbers
      effectiveDates
      newVenture
      towTruckRepossessions
      primaryBusiness
      ifNotPrimaryExplain
      Seasonal
      filedForBankruptcy
      whenFilledForBankruptcy
      explainForBankruptcy
      GrossReceiptsLastYear
      EstimateForComingYear
      BusinessForSale
      operateMoreThanOneState
      listStates
      largestCitiesEntered
      haulHazardousMaterialByEPA
      listingForChemicalMaterials
      hireVehicles
      driversCoveredCompensation
      ifYesNameOfCarrier
      minYearsDrivingExpRequired
      vehiclesOwnerDriven
      driversAllowedToTakeVehiclesHome
      maxDrivingHoursDaily
      maxDrivingHoursWeekly
      driverPayBasis
      lessorBeAdded
      nameAddressOfLessorForEachVehicle
      anyLossPayees
      nameAddressOfMortgageeForEachVehicle
      applicantAwareOfClaim
      provideCompleteDetails
      everBeenDeclined
      ifYesDateAndWhy
      additionalCoverageOptions
      intrastateFilingNeeded
      stateAndPermitNumber
      stateListForCargoFilings
      nameAndAddressPermitIssued
      MCS90EndorsementNeeded
      overWeightCommoditiesHauled
      ifFillingRequiredShowStates
      escortVehiclesTowed
      allowOthersToHaulUnderYourAuthority
      allowOthersToHaulHazardousCommodities
      everChangedYourOperatingName
      operateAnyOtherName
      leaseYourAuthority
      appointAgentsOnYourBehalf
      appliedForAuthorityPast3Years
      authorityWithdrawn
      evidenceRequired
      explainForAboveMultipleBooleanYes
      agreementsWithOtherCarriers
      withWhomAgreementHasBeenMade
      partiesNamedAutoMobilesLiability
      nameOfInsuranceCompanyAndLimitOfLiability
      whosePermitOtherPartiesOperate
      holdHarmlessInAgreement
      barterOrHireVehicles
      premiumBeFinanced
      cargoReqForMobileHomes
      cargoReqForBizDocsOrSecs
      fridgeBreakdownCoverage
      progMTCLimit
      compDeductible
      collDeductible
      forHireTruckingOps
      operateAWarehouse
      setUpOrInstallation
      frackingGasProd
      bizOutsideOfForHireOps
      progGLLimit
      vehicleLeaseProvidePrimaryLiability
      progNTLLimit
      progNonOwnedTrailerPDLimit
      progressive2ndPageKickOut
      filingState
      stateFilingsType
      proViewPolicyState
      snapShotProView
      proViewVehicleAnnualMileage
      proViewTermsAndConditions
      monthInBusiness
      typeOfOpsCW
      edited
      progCurrentPolicyexpirationDate
      coverWhaleOptAlUm
      coverWhaleOptAlPip
      authorityNumber
      indications {
        id
        carrier
        status
        estimatedPremium
      }
      File {
        url
        name
      }

      quotes {
        effectiveDate
        expirationDate
        totalLimit
        totalPremium
        totalDeductible
        monthlyPayment
        downPaymentPercentage
        lob
        pfa
        id
        carrier
        ascendProgramId
        ascendProgramUrl
        ascendInvoiceId
        ascendInvoiceUrl
        ascendInvoiceNumber
        ascendInvoiceDueDate
        File {
          id
          name
          type
          url
        }
        products {
          id
          status
          lob
          bindingPremium
          limit
          deductible
          quoteNumber
          invoicedBy
          admitted
          ascendQuoteId
          issuingCarrier
          billingCarrier
          quoteFileLocation
          quoteSigned
          quoteSignedFileLocation
          purchasedFlag
          oppStage
          downPaymentPercentage
          downPaymentAmount
          paymentType
          paymentSource
          applicationError
          declinedReason
          taxes
          platformFee
          PlatformFeeType
          File {
            id
            name
            type
            url
          }
          policy {
            id
            policyNumber
          }
        }
      }

      company {
        accountOwner
      }
    }
  }
`;

export const GET_SINGLE_PACKAGE_INFO_MUTATION = gql`
  mutation fetchSinglePackage($packageId: ID) {
    fetchSinglePackage(packageId: $packageId) {
      policy {
        id
        policyNumber
      }
      payments {
        id
        paymentStatus
        amountPaid
        ascendInvoiceId
        createdAt
      }
      application {
        id
        stage
        activeAuth
        averageViolations
        doTheseApply
        doTheseApplyCw
        preferedCarrier
        priorityStatus
        operationsDescription
        driversAbove23
        cdlExperience
        anySeriousViolations
        currentProgCust
        businessStructure
        driverSummary
        totalValueTrucks
        totalValueTrailers
        grossAnnualTruckRev
        annualTotalMileage
        haulsUnder50Miles
        haulsUnder200Miles
        haulsUnder500Miles
        haulsAbove500Miles
        currentlyInsured
        otherCoverages
        totalAdditionalInsureds
        totalNamedInsureds
        blanketAdditionalNeeded
        blanketWaiverSubrogations
        stateOrFederalFilingsReq
        snapshotPreview
        effectiveDate
        towingLaborStorageLimit
        trailerInterchangeLimit
        autoLiabilityLossCount
        autoLiabilityLossPaid
        physicalDamageLossCount
        physicalDamageLossPaid
        cargoLossCount
        cargoLossPaid
        truckerGenLiabLossCount
        truckerGenLiabLossPaid
        lossesConfirmedVsPending
        createdAt
        updatedAt
        isTest
        isThereRigging
        numberLosses3Years
        currentPage
        numberDrivers
        numberPowerUnits
        lobs
        avgRadOps
        domiciledWithin50Miles
        limitInsPerPowerUnit
        limitInsPerPowerUnitNonOwned
        perOccurenceLimit
        deductible
        spoilageFreezingCoverage
        mtcAdditionalCoverage
        pollutionCleanUp
        ownersGoodExtension
        nonOwnedTrailer
        expirationDate
        disclaimer
        status
        typeOfOps
        typeOfOpsDescriptionOther
        typeOfOpsFao
        glOrBop
        yearCurrentBusiness
        limitAl
        limitPd
        limitMtc
        limitGl
        limitNtl
        trailerInterchangeDeductible
        deductibleAl
        deductiblePd
        deductibleMtc
        deductibleGl
        deductibleNtl
        numberLossesPast3YearsGA
        # tarpsAndChains
        # reusablePackingContainers
        # subHaulerOwnerOrOperator
        # numberOfNonOwnedTrailers
        # debrisRemovalLimitGA
        # reloadingLimitGA
        # pollutionCleanUpLimitGA
        # earnedFreightLimitGA
        # fireDepartmentServiceChargesLimitGA
        # lossDataPreparationLimitGA
        # rewardCoverageLimitGA
        # reusablePackingContainersLimitGA
        # tarpsAndChainsLimitGA
        # annualValueShippedGA
        # totalCargoLossesInLast36MonthsGA
        continuousCoverage
        DOTObtain60Days
        typeOfTrucker
        ineligibleOperations
        vehicleHaulOilAndGas
        hazardousMaterialPlacard
        isCEODriver
        isCEOInvolvedInOperations
        bodilyInjuryLiabilityLimit
        otherGlOrBop
        priorInsuranceCancelledOrRenewed
        seriousViolationsWithinPast5Years
        ELDVendorName
        LossControlTechnology
        ELDRequiredToRecord
        commercialVehiclesInsuredOwns
        privatePassengerAutosInsured
        insuredVehiclesUsesInBusiness
        federal
        federalCargo
        progressiveState
        progressiveStateCargo
        progressiveOther
        progressiveEligibilityCriteria
        progressivePolicyState
        progressiveELDVendor
        ELDVendorAccess
        customerSpendLastYear
        nonOwnedVehiclesUsedInBusiness
        cargoCoverageForMobileHomes
        progressiveTrailerInterchangeCoverage
        trailerInterchangeFurnishAgreement
        nonOwnedTrailerPhysicalDamage
        MCS90
        isGAKickOut
        isCWKickOut
        cwLosses
        motorTruckCargoLimit
        towingLaborStorageLimitstr
        trailerInterchangeLimitstr
        trailerInterchangeLimitProgressive
        isProgKickOut
        vehiclesTowingOrHauling
        vehiclesHualSteel
        previousClaimCw
        totalAccidents
        totalNumberViolations
        totalSuspensions
        primaryAndNoncontributoryForTGL
        primaryAndNoncontributoryForAL
        requireFederalFillingForAL
        requireStateFillingForAL
        hauledRefrigeratedGoods
        indications {
          id
          carrier
          status
          estimatedPremium
        }
      }
      products {
        id
        status
        lob
        bindingPremium
        limit
        deductible
        quoteNumber
        invoicedBy
        admitted
        ascendQuoteId
        issuingCarrier
        billingCarrier
        quoteFileLocation
        quoteSigned
        quoteSignedFileLocation
        purchasedFlag
        oppStage
        application {
          id
          stage
          activeAuth
          averageViolations
          doTheseApply
          doTheseApplyCw
          preferedCarrier
          priorityStatus
          operationsDescription
          driversAbove23
          cdlExperience
          anySeriousViolations
          currentProgCust
          businessStructure
          driverSummary
          totalValueTrucks
          totalValueTrailers
          grossAnnualTruckRev
          annualTotalMileage
          haulsUnder50Miles
          haulsUnder200Miles
          haulsUnder500Miles
          haulsAbove500Miles
          currentlyInsured
          otherCoverages
          totalAdditionalInsureds
          totalNamedInsureds
          blanketAdditionalNeeded
          blanketWaiverSubrogations
          stateOrFederalFilingsReq
          snapshotPreview
          effectiveDate
          towingLaborStorageLimit
          trailerInterchangeLimit
          autoLiabilityLossCount
          autoLiabilityLossPaid
          physicalDamageLossCount
          physicalDamageLossPaid
          cargoLossCount
          cargoLossPaid
          truckerGenLiabLossCount
          truckerGenLiabLossPaid
          lossesConfirmedVsPending
          createdAt
          updatedAt
          isTest
          isThereRigging
          numberLosses3Years
          currentPage
          numberDrivers
          numberPowerUnits
          lobs
          avgRadOps
          domiciledWithin50Miles
          limitInsPerPowerUnit
          limitInsPerPowerUnitNonOwned
          perOccurenceLimit
          deductible
          spoilageFreezingCoverage
          mtcAdditionalCoverage
          pollutionCleanUp
          ownersGoodExtension
          nonOwnedTrailer
          expirationDate
          disclaimer
          status
          typeOfOps
          typeOfOpsDescriptionOther
          typeOfOpsFao
          glOrBop
          yearCurrentBusiness
          limitAl
          limitPd
          limitMtc
          limitGl
          limitNtl
          trailerInterchangeDeductible
          deductibleAl
          deductiblePd
          deductibleMtc
          deductibleGl
          deductibleNtl
          numberLossesPast3YearsGA
          # tarpsAndChains
          # reusablePackingContainers
          # subHaulerOwnerOrOperator
          # numberOfNonOwnedTrailers
          # debrisRemovalLimitGA
          # reloadingLimitGA
          # pollutionCleanUpLimitGA
          # earnedFreightLimitGA
          # fireDepartmentServiceChargesLimitGA
          # lossDataPreparationLimitGA
          # rewardCoverageLimitGA
          # reusablePackingContainersLimitGA
          # tarpsAndChainsLimitGA
          # annualValueShippedGA
          # totalCargoLossesInLast36MonthsGA
        }
      }
      effectiveDate
      expirationDate
      totalLimit
      totalPremium
      totalDeductible
      monthlyPayment
      lob
      pfa
      id
    }
  }
`;

export const LT_LOGIN_MUTATION = gql`
  mutation ltTeamLogin($email: String!, $password: String!) {
    ltTeamLogin(email: $email, password: $password) {
      token
      user {
        id
        name
        phone
        email
        roles
        isAdmin
      }
    }
  }
`;

export const GET_PLATFORM_FEE_DATA = gql`
  mutation getPlatformFeeData {
    getPlatformFeeData {
      id
      name
      type
      value
    }
  }
`;

export const UPDATE_PLATFORM_FEE = gql`
  mutation upsertUniverse($id: ID!, $inputData: UniverseInput) {
    upsertUniverse(id: $id, inputData: $inputData) {
      id
      name
      type
      value
    }
  }
`;

export const GET_LT_USER_COMPANIES_LIST = gql`
  mutation companiesList {
    companiesList {
      id
      dot
      name
      phone
      garagingAddress
      garagingCity
      garagingZip
      garagingState
      garagingCounty
      users {
        id
        name
        role
      }
    }
  }
`;

export const SIGNUP_MUTATION = gql`
  mutation Signup(
    $email: String!
    $firstName: String!
    $lastName: String!
    $password: String!
    $phone: String
    $org: Organizations
    $utmCampaign: String
    $utmSource: String
    $referralCode: String
    $termsAndConditions: Boolean!
  ) {
    signUp(
      email: $email
      firstName: $firstName
      lastName: $lastName
      password: $password
      phone: $phone
      org: $org
      utmCampaign: $utmCampaign
      utmSource: $utmSource
      referralCode: $referralCode
      termsAndConditions: $termsAndConditions
    ) {
      token
      lead {
        id
        firstName
        lastName
        email
        phone
        mobile
      }
    }
  }
`;

export const SIGNUP_USER_WITH_MUTATION = gql`
  mutation singUpUserWithCompany(
    $email: String!
    $name: String!
    $password: String!
    $phone: String!
    $companyId: String!
  ) {
    singUpUserWithCompany(
      email: $email
      name: $name
      password: $password
      phone: $phone
      companyId: $companyId
      termsAndConditions: $termsAndConditions
    ) {
      message
    }
  }
`;

export const DISPATCHER_SIGNUP_MUTATION = gql`
  mutation dispatcherSignUp(
    $email: String!
    $password: String!
    $name: String!
    $phone: String!
    $org: Organizations!
  ) {
    dispatcherSignUp(
      email: $email
      password: $password
      name: $name
      phone: $phone
      org: $org
    ) {
      token
      user {
        id
        name
        phone
        email
      }
    }
  }
`;

export const SOCIAL_AUTHENTICATION_ON_CREATE_COMPANY = gql`
  mutation socialAuthenticationOnCreateCompany($email: String!) {
    socialAuthenticationOnCreateCompany(email: $email) {
      token
      user {
        id
        name
        phone
        email
        lastLogin
        role
        accessAllowed
        company {
          id
          dot
          dba
          name
          mailingAddress
          garagingAddress
          mcNumber
          mailingCity
          garagingCity
          accountOwner
          mailingZip
          garagingZip
          garagingCounty
          mailingCounty
          yearsInBusiness
          mailingState
          garagingState
          phone
          eldProvider
          policyChangeData
          notes
        }
      }
    }
  }
`;

export const LT_CREATE_ADMIN_USER_MUTATION = gql`
  mutation createAdminUserForNewCompany(
    $email: String!
    $name: String!
    $phone: String!
    $role: Role!
    $accessAllowed: Boolean!
  ) {
    createAdminUserForNewCompany(
      email: $email
      name: $name
      phone: $phone
      role: $role
      accessAllowed: $accessAllowed
    ) {
      token
      user {
        id
        email
        role
        accessAllowed
      }
    }
  }
`;

export const REQUEST_COMPANY_ACCESS_FOR_DISPATCHER_MUTATION = gql`
  mutation connectDispatcherWithCompany($companyId: String!, $role: Role!) {
    connectDispatcherWithCompany(companyId: $companyId, role: $role) {
      id
      name
      email
    }
  }
`;

export const REGISTER_USER_AND_COMPANY_MUTATION = gql`
  mutation registerUserAndCompanyByBroker(
    $user: UserInput
    $company: CompanyInput
    $isSDR: Boolean!
  ) {
    registerUserAndCompanyByBroker(
      user: $user
      company: $company
      isSDR: $isSDR
    ) {
      lead {
        id
        zohoId
        account {
          id
        }
        contact {
          id
        }
        otherLeads {
          id
        }
        parentLeadId
        parentLead {
          id
        }
        activePendingList
        annualPayroll
        annualRevenue
        authorityHistoryList
        avgTimeSpent
        garagingCity
        garagingCounty
        garagingMail
        garagingNumberOfUnits
        garagingState
        garagingStreet
        garagingZipCode
        bipdInsuranceOnFile
        bipdInsuranceRequired
        bondInsuranceOnFile
        bondInsuranceRequired
        brokerAppPending
        brokerAuthStatus
        businessDescription
        businessEntityType
        cargo
        cargoCarried
        cargoCarrier
        cargoInsuranceOnFile
        cargoInsuranceRequired
        cargoLimits
        carrierOperation
        city
        commonAppPending
        commonAuthStatus
        company
        contactTitle
        contractAppPending
        contractAuthStatus
        convertedAccount
        convertedContact
        convertedDeal
        county
        crashedFatal
        crashedInjury
        crashedTotal
        crashedTow
        createdBy
        daysVisited
        dbaName
        designation
        dotNumber
        dotRegistration
        dunsNumber
        email
        emailNotValid
        emailOptOut
        expiringInsuranceCompany
        fax
        firstName
        firstPageVisited
        firstVisit
        fullTimeEmployees
        inspectionsDriver
        inspectionsHazmat
        inspectionsLep
        InspectionsVehicle
        insuranceHistoryListData
        insuranceType
        lastKnownAutoExpirationDate
        lastKnownCargoExpirationDate
        lastKnownWcExpirationDate
        lastName
        layout
        leadCost
        leadOwner
        leadSource
        leadSourceLeadId
        leadStatus
        leadType
        liabilityCarrier
        liabilityLimits
        limitsRequired
        listCampaignType
        mailZipCode
        mailingCity
        mailingCounty
        mailingMail
        mailingNumberOfUnits
        garagingCity
        garagingState
        garagingStreet
        garagingZipCode
        garagingCounty
        garagingMail
        garagingNumberOfUnits
        mailingState
        mailingStreet
        marketingAutoScore
        maximumRadiusOfOperations
        mcNumber
        mcs150FormDate
        mobile
        modifiedBy
        mostRecentVisit
        naicCode
        natlAverageDriver
        natlAverageHazmat
        natlAverageLep
        natlAverageVehicle
        noOfEmployees
        numberOfChats
        numberOfDrivers
        numberOfUnits
        operatingStatus
        operationClassification
        outOfServiceDriver
        outOfServiceHazmat
        outOfServiceLep
        outOfServicePercentageDriver
        outOfServicePercentageHazmat
        outOfServicePercentageLep
        outOfServicePercentageVehicle
        outOfServiceVehicle
        ownerYearsOfExperience
        partTimeEmployees
        pendingApplicationList
        phone
        physicalMail
        policyNumber
        referrer
        rejectionInsuranceList
        revocationListData
        sdrLead
        sicCode
        spId
        spTier
        spType
        state
        street
        subContractorCosts
        visitorScore
        website
        yearsInBusiness
        zipCode
        org
        auth {
          id
        }
        createdAt
        updatedAt
      }
      auth {
        id
        contactId
        leadId
        email
        role
        socialProvider
        providerId
        phoneVerified
        isAuthorized
        accessAllowed
        lastLogin
        phone
        org
      }
      token
    }
  }
`;

export const UPDATE_DISPATCHER_REQUEST_MUTATION = gql`
  mutation updateDispatcherRequestStatus(
    $status: String!
    $dispatcherId: String!
    $role: Role!
    $companyId: String!
  ) {
    updateDispatcherRequestStatus(
      status: $status
      dispatcherId: $dispatcherId
      role: $role
      companyId: $companyId
    ) {
      id
      name
      email
      companies {
        role
        accessAllowed
        company {
          id
          accountName
        }
      }
    }
  }
`;

export const UPDATE_COMPANY_MUTATION = gql`
  mutation UpdateCompany(
    $input: CompanyInput
    $companyId: ID!
    $nonEndorsement: Boolean
  ) {
    updateCompany(
      input: $input
      companyId: $companyId
      nonEndorsement: $nonEndorsement
    ) {
      lead {
        id
        zohoId
        account {
          id
        }
        contact {
          id
        }
        otherLeads {
          id
        }
        parentLeadId
        parentLead {
          id
        }
        eldProvider
        activePendingList
        havePrevAuthority
        annualPayroll
        annualRevenue
        authorityHistoryList
        avgTimeSpent
        garagingCity
        garagingCounty
        garagingMail
        garagingNumberOfUnits
        garagingState
        garagingStreet
        garagingZipCode
        bipdInsuranceOnFile
        bipdInsuranceRequired
        bondInsuranceOnFile
        bondInsuranceRequired
        brokerAppPending
        brokerAuthStatus
        businessDescription
        businessEntityType
        cargo
        cargoCarried
        cargoCarrier
        cargoInsuranceOnFile
        cargoInsuranceRequired
        cargoLimits
        carrierOperation
        city
        commonAppPending
        commonAuthStatus
        company
        contactTitle
        contractAppPending
        contractAuthStatus
        convertedAccount
        convertedContact
        convertedDeal
        county
        crashedFatal
        crashedInjury
        crashedTotal
        crashedTow
        createdBy
        daysVisited
        dbaName
        designation
        dotNumber
        dotRegistration
        dunsNumber
        email
        emailNotValid
        emailOptOut
        expiringInsuranceCompany
        fax
        firstName
        firstPageVisited
        firstVisit
        fullTimeEmployees
        inspectionsDriver
        inspectionsHazmat
        inspectionsLep
        InspectionsVehicle
        insuranceHistoryListData
        insuranceType
        lastKnownAutoExpirationDate
        lastKnownCargoExpirationDate
        lastKnownWcExpirationDate
        lastName
        layout
        leadCost
        leadOwner
        leadSource
        leadSourceLeadId
        leadStatus
        leadType
        liabilityCarrier
        liabilityLimits
        limitsRequired
        listCampaignType
        mailZipCode
        mailingCity
        mailingCounty
        mailingMail
        mailingNumberOfUnits
        garagingCity
        garagingState
        garagingStreet
        garagingZipCode
        garagingCounty
        garagingMail
        garagingNumberOfUnits
        mailingState
        mailingStreet
        marketingAutoScore
        maximumRadiusOfOperations
        mcNumber
        mcs150FormDate
        mobile
        modifiedBy
        mostRecentVisit
        naicCode
        natlAverageDriver
        natlAverageHazmat
        natlAverageLep
        natlAverageVehicle
        noOfEmployees
        numberOfChats
        numberOfDrivers
        numberOfUnits
        operatingStatus
        operationClassification
        outOfServiceDriver
        outOfServiceHazmat
        outOfServiceLep
        outOfServicePercentageDriver
        outOfServicePercentageHazmat
        outOfServicePercentageLep
        outOfServicePercentageVehicle
        outOfServiceVehicle
        ownerYearsOfExperience
        partTimeEmployees
        pendingApplicationList
        phone
        physicalMail
        policyNumber
        referrer
        rejectionInsuranceList
        revocationListData
        sdrLead
        sicCode
        spId
        spTier
        spType
        state
        street
        subContractorCosts
        visitorScore
        website
        yearsInBusiness
        zipCode
        org
        auth {
          id
        }
        createdAt
        updatedAt
      }
      account {
        id
        accountManager
        leadId
        dayAMTransitionEdit
        recordImage
        accountName
        havePrevAuthority
        notes1
        activePendingList
        accountNumber
        policyChangeData
        owner
        accountShortName
        accountSite
        accountStage
        accountType
        activityLevel
        alternateContact
        annualPayroll
        annualRevenue
        garagingCity
        garagingCounty
        garagingMail
        garagingNumberOfUnits
        garagingState
        garagingStreet
        garagingZipCode
        businessEntityType
        cargo
        cargoLimits
        claimsLink
        comments
        commodity
        createdAt
        additionalInsured
        lossPayee
        additionalInsuredSubFields
        uiiaAccount
        injuryProtectionAmount
        breakdownCoverage
        specialVerbiage
        brokerAuthorityStatus
        brokerApplicationPending
        bondInsuranceRequired
        bondInsuranceOnFile
        bondAuthorityStatus
        bondApplicationPending
        bipdInsuranceRequired
        bipdInsuranceOnFile
        bipdAuthorityStatus
        bipdApplicationPending
        carrierOperation
        cargoInsuranceRequired
        cargoInsuranceOnFile
        cargoCarried
        eldProvider
        cargoAuthorityStatus
        cargoApplicationPending
        zipCode
        street
        state
        rejectionInsuranceList
        pendingApplicationList
        outOfServiceVehicle
        outOfServicePercentageVehicle
        outOfServicePercentageLEP
        outOfServicePercentageHazmat
        outOfServicePercentageDriverr
        outOfServiceLEP
        outOfServiceHazmat
        outOfServiceDriver
        operationClassification
        operatingStatus
        numberOfDrivers
        natlAverageVehicle
        natlAverageLEP
        natlAverageHazmat
        natlAverageDriver
        mcs150FormDate
        mailingState
        mailingCounty
        mailingCity
        mailingStreet
        mailZipCode
        garagingCity
        garagingState
        garagingStreet
        garagingZipCode
        garagingCounty
        insuranceHistoryListData
        inspectionsVehicle
        inspectionsLEP
        inspectionsHazmat
        inspectionsDriver
        dunsNumber
        crashedTow
        crashedTotal
        crashedInjury
        crashedFatal
        contractAuthorityStatus
        contractApplicationPending
        commonAuthorityStatus
        commonApplicationPending
        city
        yearsInBusiness
        website
        web
        underwritingCompanies
        totalActivePremium
        tickerSymbol
        tag
        subform1
        similarInvestments
        sicCode
        shippingStreet
        shippingState
        shippingCountry
        shippingCode
        shippingCity
        seriesPreference
        saferScore
        revenueSize
        resourceAndInvestmentNeeded
        resourceAndInvestmentDescription
        referredBy
        referedBy
        rating
        prioritization
        platformAccountUsers
        platformAccountID
        phone
        paymentBasedOn
        partnershipType
        parentAccount
        ownership
        ownerYearsOfExperience
        numberOfUnits
        numberOfEmployees
        naicCode
        monetizationDescriptions
        modifiedBy
        minInvestment
        mcNumber
        partnerContact
        partnerContactEmail
        opportunityDescription
        partnerContactPhone
        contract
        createdBy
        dbaName
        dealTerms
        description
        dotNumber
        dotNumber1
        ebitda
        eldVendor
        employees
        estimateAnnualPartnershipRevenue
        estimatedAnnualPartnershipRevenueToLT
        estimatedNumberOfLeadsToLT
        fax
        garagingCity
        garagingState
        garagingStreet
        garagingZipCode
        industry
        investmentThesis
        layout
        leadCost
        leadSource
        legacyAccount
        liabilityLimits
        limitsRequired
        maType
        maStage
        maxInvestment
      }
    }
  }
`;

export const CREATE_UPDATE_COMPANY_MUTATION = gql`
  mutation CompleteRegistration(
    $input: CompleteRegistrationInput!
    $leadId: ID
    $referralCode: String
  ) {
    completeRegistration(
      input: $input
      leadId: $leadId
      referralCode: $referralCode
    ) {
      token
      auth {
        id
        contactId
        contact {
          id
        }
        leadId
        lead {
          id
        }
        email
        role
        socialProvider
        providerId
        phoneVerified
        isAuthorized
        accessAllowed
        lastLogin
        phone
        org
      }
      lead {
        id
        zohoId

        account {
          id
        }
        contact {
          id
        }
        otherLeads {
          id
        }
        parentLeadId
        parentLead {
          id
        }
        activePendingList
        havePrevAuthority
        annualPayroll
        annualRevenue
        authorityHistoryList
        avgTimeSpent
        garagingCity
        garagingCounty
        garagingMail
        garagingNumberOfUnits
        garagingState
        garagingStreet
        garagingZipCode
        bipdInsuranceOnFile
        bipdInsuranceRequired
        bondInsuranceOnFile
        bondInsuranceRequired
        brokerAppPending
        brokerAuthStatus
        businessDescription
        businessEntityType
        cargo
        cargoCarried
        cargoCarrier
        cargoInsuranceOnFile
        cargoInsuranceRequired
        cargoLimits
        carrierOperation
        city
        commonAppPending
        commonAuthStatus
        company
        contactTitle
        contractAppPending
        contractAuthStatus
        convertedAccount
        convertedContact
        convertedDeal
        county
        crashedFatal
        crashedInjury
        crashedTotal
        crashedTow
        createdBy
        daysVisited
        dbaName
        designation
        dotNumber
        dotRegistration
        dunsNumber
        email
        emailNotValid
        emailOptOut
        expiringInsuranceCompany
        fax
        firstName
        firstPageVisited
        firstVisit
        fullTimeEmployees
        inspectionsDriver
        inspectionsHazmat
        inspectionsLep
        InspectionsVehicle
        insuranceHistoryListData
        insuranceType
        lastKnownAutoExpirationDate
        lastKnownCargoExpirationDate
        lastKnownWcExpirationDate
        lastName
        layout
        leadCost
        leadOwner
        leadSource
        leadSourceLeadId
        leadStatus
        leadType
        liabilityCarrier
        liabilityLimits
        limitsRequired
        listCampaignType
        mailZipCode
        mailingCity
        mailingCounty
        mailZipCode
        mailingCity
        mailingCounty
        mailingMail
        mailingNumberOfUnits
        garagingCity
        garagingState
        garagingStreet
        garagingZipCode
        garagingCounty
        garagingMail
        garagingNumberOfUnits
        mailingState
        mailingStreet
        marketingAutoScore
        maximumRadiusOfOperations
        mcNumber
        mcs150FormDate
        mobile
        modifiedBy
        mostRecentVisit
        naicCode
        natlAverageDriver
        natlAverageHazmat
        natlAverageLep
        natlAverageVehicle
        noOfEmployees
        numberOfChats
        numberOfDrivers
        numberOfUnits
        operatingStatus
        operationClassification
        outOfServiceDriver
        outOfServiceHazmat
        outOfServiceLep
        outOfServicePercentageDriver
        outOfServicePercentageHazmat
        outOfServicePercentageLep
        outOfServicePercentageVehicle
        outOfServiceVehicle
        ownerYearsOfExperience
        partTimeEmployees
        pendingApplicationList
        phone
        physicalMail
        policyNumber
        referrer
        rejectionInsuranceList
        revocationListData
        sdrLead
        sicCode
        spId
        spTier
        spType
        state
        street
        subContractorCosts
        visitorScore
        website
        yearsInBusiness
        zipCode
        org
        auth {
          id
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const UPDATE_POLICY_LOBS_MUTATION = gql`
  mutation updatePolicyLOBS($policyId: ID!, $lobs: String!) {
    updatePolicyLOBS(policyId: $policyId, lobs: $lobs) {
      id
      status
      policyNumber
      totalPremium
      endorsmentsPremium
      issuingCarrier
      billingCarrier
      effectiveDate
      expirationDate
      accountManager
      lob
      endorsments {
        id
        name
        status
        Operations {
          id
          action
          subject
          subjectKey
        }
      }
      quote {
        id
        totalLimit
        totalDeductible
        totalPremium
        totalAmountPaid
        pfa
        application {
          id
        }
      }
    }
  }
`;

export const REMOVE_POLICY_LOBS_MUTATION = gql`
  mutation removePolicyLobs($policyId: ID!, $lobs: String!) {
    removePolicyLobs(policyId: $policyId, lobs: $lobs) {
      id
      status
      policyNumber
      totalPremium
      endorsmentsPremium
      issuingCarrier
      billingCarrier
      effectiveDate
      expirationDate
      accountManager
      lob
      endorsments {
        id
        name
        status
        Operations {
          id
          action
          subject
          subjectKey
        }
      }
      quote {
        id
        totalLimit
        totalDeductible
        totalPremium
        totalAmountPaid
        pfa
        application {
          id
        }
      }
    }
  }
`;

export const ADD_ADDITIONAL_INSURED_MUTATION = gql`
  mutation additionalInsuredEndorsement(
    $policyId: ID!
    $additionalInsured: String!
  ) {
    additionalInsuredEndorsement(
      policyId: $policyId
      additionalInsured: $additionalInsured
    ) {
      id
      status
      policyNumber
      totalPremium
      endorsmentsPremium
      issuingCarrier
      billingCarrier
      effectiveDate
      expirationDate
      accountManager
      lob
      endorsments {
        id
        name
        status
        Operations {
          id
          action
          subject
          subjectKey
        }
      }
      quote {
        id
        totalLimit
        totalDeductible
        totalPremium
        totalAmountPaid
        pfa
        application {
          id
        }
      }
    }
  }
`;

export const GET_SINGLE_DRIVER_DETAILS_MUTATION = gql`
  mutation driverDetails($driverId: ID!) {
    driverDetails(driverId: $driverId) {
      id
      company {
        id
      }
      firstName
      lastName
      dob
      dlNumber
      dlState
      dateOfHire
      phone
      dlYearsLicensed
      mvrStatus
      email
      phone
      mvrCheckDate
      address
      city
      state
      zip
      isOwner
      eligibility
      yearsExperience
      isSr22
      sr22CaseNumber
      maritalStatus
      hasAnyViolationsInPast5Years
      past3YearMajorViolations
      past3YearsAccidents
      past3YearsViolations
      totalMajorConvictions
      past3YearsSuspensions
      accidentsViolationsDetails
      totalMajorConvictionsDates
      past5YearsAccidentsViolations
      licenseIssueDate
      MotorVehicleRecord {
        id
        reportId
        reportUri
        mvrId
        createdAt
        updatedAt
        turnaround_time
        license_type
        license_status
        license_state
        license_number
        license_class
        expiration_date
        issued_date
        first_issued_date
        inferred_issued_date
        previous_license_number
        previous_license_state
        experience_failed
        result
        not_found
        accidents
        violations
        custom_rules
        restrictions
        endorsements
        File {
          id
          name
          description
          type
          url
        }
      }
    }
  }
`;

export const CREATE_UPDATE_INDICATION_MUTATION = gql`
  mutation upsertIndication($id: ID!, $appId: ID!, $input: IndicationInput) {
    upsertIndication(id: $id, input: $input, appId: $appId) {
      id
      carrier
      status
      application {
        id
      }
      company {
        id
      }
    }
  }
`;

export const GET_COMPANY_DOCUMENT_FILE_URL_MUTATION = gql`
  mutation getCompanyDocumentsFileUrl($key: String!) {
    getCompanyDocumentsFileUrl(key: $key)
  }
`;

export const INDICATION_SLACK_NOTIFICATION_MUTATION = gql`
  mutation indicationSlackNotification {
    indicationSlackNotification
  }
`;

export const USER_CONTACT_BROKER_SLACK_NOTIFICATION_MUTATION = gql`
  mutation indicationBrokerCallSlackNotification {
    indicationBrokerCallSlackNotification {
      message
    }
  }
`;

export const USER_CONTACT_BROKER_FOR_DOWNPAYMENT_SLACK_NOTIFICATION_MUTATION = gql`
  mutation userDownPaymentPurchaseNotification(
    $packageId: ID
    $carrier: String
  ) {
    userDownPaymentPurchaseNotification(
      packageId: $packageId
      carrier: $carrier
    )
  }
`;

export const UPDATE_QUOTE_PURCHASE_FLAG_MUTATION = gql`
  mutation updateQuotePurchaseFlag($packageId: ID, $carrier: String) {
    updateQuotePurchaseFlag(packageId: $packageId, carrier: $carrier) {
      id
      stage
      activeAuth
      averageViolations
      doTheseApply
      doTheseApplyCw
      preferedCarrier
      priorityStatus
      operationsDescription
      driversAbove23
      cdlExperience
      anySeriousViolations
      currentProgCust
      businessStructure
      driverSummary
      totalValueTrucks
      totalValueTrailers
      grossAnnualTruckRev
      annualTotalMileage
      haulsUnder50Miles
      haulsUnder200Miles
      haulsUnder500Miles
      haulsAbove500Miles
      currentlyInsured
      otherCoverages
      totalAdditionalInsureds
      totalNamedInsureds
      blanketAdditionalNeeded
      blanketWaiverSubrogations
      stateOrFederalFilingsReq
      snapshotPreview
      effectiveDate
      towingLaborStorageLimit
      trailerInterchangeLimit
      autoLiabilityLossCount
      autoLiabilityLossPaid
      physicalDamageLossCount
      physicalDamageLossPaid
      cargoLossCount
      cargoLossPaid
      truckerGenLiabLossCount
      truckerGenLiabLossPaid
      lossesConfirmedVsPending
      createdAt
      updatedAt
      isTest
      isThereRigging
      numberLosses3Years
      currentPage
      numberDrivers
      numberPowerUnits
      lobs
      avgRadOps
      domiciledWithin50Miles
      limitInsPerPowerUnit
      limitInsPerPowerUnitNonOwned
      perOccurenceLimit
      deductible
      spoilageFreezingCoverage
      mtcAdditionalCoverage
      pollutionCleanUp
      ownersGoodExtension
      nonOwnedTrailer
      expirationDate
      disclaimer
      status
      typeOfOps
      typeOfOpsDescriptionOther
      typeOfOpsFao
      glOrBop
      yearCurrentBusiness
      limitAl
      limitPd
      limitMtc
      limitGl
      limitNtl
      trailerInterchangeDeductible
      deductibleAl
      deductiblePd
      deductibleMtc
      deductibleGl
      deductibleNtl
      numberLossesPast3YearsGA
      # tarpsAndChains
      # reusablePackingContainers
      # subHaulerOwnerOrOperator
      # numberOfNonOwnedTrailers
      # debrisRemovalLimitGA
      # reloadingLimitGA
      # pollutionCleanUpLimitGA
      # earnedFreightLimitGA
      # fireDepartmentServiceChargesLimitGA
      # lossDataPreparationLimitGA
      # rewardCoverageLimitGA
      # reusablePackingContainersLimitGA
      # tarpsAndChainsLimitGA
      # annualValueShippedGA
      # totalCargoLossesInLast36MonthsGA
      continuousCoverage
      DOTObtain60Days
      typeOfTrucker
      ineligibleOperations
      vehicleHaulOilAndGas
      hazardousMaterialPlacard
      isCEODriver
      isCEOInvolvedInOperations
      bodilyInjuryLiabilityLimit
      otherGlOrBop
      priorInsuranceCancelledOrRenewed
      seriousViolationsWithinPast5Years
      ELDVendorName
      LossControlTechnology
      ELDRequiredToRecord
      commercialVehiclesInsuredOwns
      privatePassengerAutosInsured
      insuredVehiclesUsesInBusiness
      federal
      federalCargo
      progressiveState
      progressiveStateCargo
      progressiveOther
      progressiveEligibilityCriteria
      progressivePolicyState
      progressiveELDVendor
      ELDVendorAccess
      customerSpendLastYear
      nonOwnedVehiclesUsedInBusiness
      cargoCoverageForMobileHomes
      progressiveTrailerInterchangeCoverage
      trailerInterchangeFurnishAgreement
      nonOwnedTrailerPhysicalDamage
      MCS90
      isGAKickOut
      isCWKickOut
      cwLosses
      motorTruckCargoLimit
      towingLaborStorageLimitstr
      trailerInterchangeLimitstr
      trailerInterchangeLimitProgressive
      isProgKickOut
      vehiclesTowingOrHauling
      vehiclesHualSteel
      previousClaimCw
      totalAccidents
      totalNumberViolations
      totalSuspensions
      primaryAndNoncontributoryForTGL
      primaryAndNoncontributoryForAL
      requireFederalFillingForAL
      requireStateFillingForAL
      hauledRefrigeratedGoods
      ownerFname
      ownerLname
      ownerEmail
      ownerDob
      appStack
      appFlow
      insuredWithNICO
      policyNumbers
      effectiveDates
      newVenture
      towTruckRepossessions
      primaryBusiness
      ifNotPrimaryExplain
      Seasonal
      filedForBankruptcy
      whenFilledForBankruptcy
      explainForBankruptcy
      GrossReceiptsLastYear
      EstimateForComingYear
      BusinessForSale
      operateMoreThanOneState
      listStates
      largestCitiesEntered
      haulHazardousMaterialByEPA
      listingForChemicalMaterials
      hireVehicles
      driversCoveredCompensation
      ifYesNameOfCarrier
      minYearsDrivingExpRequired
      vehiclesOwnerDriven
      driversAllowedToTakeVehiclesHome
      maxDrivingHoursDaily
      maxDrivingHoursWeekly
      driverPayBasis
      lessorBeAdded
      nameAddressOfLessorForEachVehicle
      anyLossPayees
      nameAddressOfMortgageeForEachVehicle
      applicantAwareOfClaim
      provideCompleteDetails
      everBeenDeclined
      ifYesDateAndWhy
      additionalCoverageOptions
      intrastateFilingNeeded
      stateAndPermitNumber
      stateListForCargoFilings
      nameAndAddressPermitIssued
      MCS90EndorsementNeeded
      overWeightCommoditiesHauled
      ifFillingRequiredShowStates
      escortVehiclesTowed
      allowOthersToHaulUnderYourAuthority
      allowOthersToHaulHazardousCommodities
      everChangedYourOperatingName
      operateAnyOtherName
      leaseYourAuthority
      appointAgentsOnYourBehalf
      appliedForAuthorityPast3Years
      authorityWithdrawn
      evidenceRequired
      explainForAboveMultipleBooleanYes
      agreementsWithOtherCarriers
      withWhomAgreementHasBeenMade
      partiesNamedAutoMobilesLiability
      nameOfInsuranceCompanyAndLimitOfLiability
      whosePermitOtherPartiesOperate
      holdHarmlessInAgreement
      barterOrHireVehicles
      premiumBeFinanced
      cargoReqForMobileHomes
      cargoReqForBizDocsOrSecs
      fridgeBreakdownCoverage
      progMTCLimit
      compDeductible
      collDeductible
      forHireTruckingOps
      operateAWarehouse
      setUpOrInstallation
      frackingGasProd
      bizOutsideOfForHireOps
      progGLLimit
      vehicleLeaseProvidePrimaryLiability
      progNTLLimit
      progNonOwnedTrailerPDLimit
      progressive2ndPageKickOut
      filingState
      stateFilingsType
      proViewPolicyState
      snapShotProView
      proViewVehicleAnnualMileage
      proViewTermsAndConditions
      indications {
        id
        carrier
        status
        estimatedPremium
      }
      File {
        id
        name
        description
        type
        url
      }

      quotes {
        effectiveDate
        expirationDate
        totalLimit
        totalPremium
        totalDeductible
        monthlyPayment
        lob
        pfa
        id
        carrier
        ascendProgramId
        ascendProgramUrl
        ascendInvoiceId
        ascendInvoiceUrl
        ascendInvoiceNumber
        ascendInvoiceDueDate
        File {
          id
          name
          description
          type
          url
        }
        products {
          id
          status
          lob
          bindingPremium
          limit
          deductible
          quoteNumber
          invoicedBy
          admitted
          ascendQuoteId
          issuingCarrier
          billingCarrier
          quoteFileLocation
          quoteSigned
          quoteSignedFileLocation
          purchasedFlag
          oppStage
          taxes
          platformFee
          PlatformFeeType
          policy {
            id
            policyNumber
          }
          File {
            id
            name
            description
            type
            url
          }
        }
      }
      company {
        accountOwner
      }
    }
  }
`;

export const UPDATE_NOTIFICATION_STATUS_MUTATION = gql`
  mutation updateNotificationStatus($id: String!) {
    updateNotificationStatus(id: $id) {
      id
      type
      status
      createdAt
      lastUpdated
      payload
      severity
      seenBy
    }
  }
`;

export const DELETE_APPLICATION_MUTATION = gql`
  mutation deleteApp($applicationId: ID!) {
    deleteApp(applicationId: $applicationId) {
      id
    }
  }
`;

export const APP_FLOW_MUTATION = gql`
  mutation appFlowHandler(
    $applicationId: ID!
    $input: ApplicationInput
    $completedBy: String
  ) {
    appFlowHandler(
      applicationId: $applicationId
      input: $input
      completedBy: $completedBy
    ) {
      company {
        id
      }
      id
      stage
      activeAuth
      averageViolations
      doTheseApply
      doTheseApplyCw
      preferedCarrier
      priorityStatus
      operationsDescription
      driversAbove23
      cdlExperience
      anySeriousViolations
      currentProgCust
      businessStructure
      driverSummary
      totalValueTrucks
      totalValueTrailers
      grossAnnualTruckRev
      annualTotalMileage
      haulsUnder50Miles
      haulsUnder200Miles
      haulsUnder500Miles
      haulsAbove500Miles
      currentlyInsured
      otherCoverages
      totalAdditionalInsureds
      totalNamedInsureds
      blanketAdditionalNeeded
      blanketWaiverSubrogations
      stateOrFederalFilingsReq
      snapshotPreview
      effectiveDate
      towingLaborStorageLimit
      trailerInterchangeLimit
      autoLiabilityLossCount
      autoLiabilityLossPaid
      physicalDamageLossCount
      physicalDamageLossPaid
      cargoLossCount
      cargoLossPaid
      truckerGenLiabLossCount
      truckerGenLiabLossPaid
      lossesConfirmedVsPending
      createdAt
      updatedAt
      isTest
      isThereRigging
      numberLosses3Years
      currentPage
      numberDrivers
      numberPowerUnits
      lobs
      avgRadOps
      domiciledWithin50Miles
      limitInsPerPowerUnit
      limitInsPerPowerUnitNonOwned
      perOccurenceLimit
      deductible
      spoilageFreezingCoverage
      mtcAdditionalCoverage
      pollutionCleanUp
      ownersGoodExtension
      nonOwnedTrailer
      expirationDate
      disclaimer
      status
      typeOfOps
      typeOfOpsDescriptionOther
      typeOfOpsFao
      glOrBop
      yearCurrentBusiness
      limitAl
      limitPd
      limitMtc
      limitGl
      limitNtl
      trailerInterchangeDeductible
      deductibleAl
      deductiblePd
      deductibleMtc
      deductibleGl
      deductibleNtl
      numberLossesPast3YearsGA
      # tarpsAndChains
      # reusablePackingContainers
      # subHaulerOwnerOrOperator
      # numberOfNonOwnedTrailers
      # debrisRemovalLimitGA
      # reloadingLimitGA
      # pollutionCleanUpLimitGA
      # earnedFreightLimitGA
      # fireDepartmentServiceChargesLimitGA
      # lossDataPreparationLimitGA
      # rewardCoverageLimitGA
      # reusablePackingContainersLimitGA
      # tarpsAndChainsLimitGA
      # annualValueShippedGA
      # totalCargoLossesInLast36MonthsGA
      continuousCoverage
      DOTObtain60Days
      typeOfTrucker
      ineligibleOperations
      vehicleHaulOilAndGas
      hazardousMaterialPlacard
      isCEODriver
      isCEOInvolvedInOperations
      bodilyInjuryLiabilityLimit
      otherGlOrBop
      priorInsuranceCancelledOrRenewed
      seriousViolationsWithinPast5Years
      ELDVendorName
      LossControlTechnology
      ELDRequiredToRecord
      commercialVehiclesInsuredOwns
      privatePassengerAutosInsured
      insuredVehiclesUsesInBusiness
      federal
      federalCargo
      progressiveState
      progressiveStateCargo
      progressiveOther
      progressiveEligibilityCriteria
      progressivePolicyState
      progressiveELDVendor
      ELDVendorAccess
      customerSpendLastYear
      nonOwnedVehiclesUsedInBusiness
      cargoCoverageForMobileHomes
      progressiveTrailerInterchangeCoverage
      trailerInterchangeFurnishAgreement
      nonOwnedTrailerPhysicalDamage
      MCS90
      isGAKickOut
      isCWKickOut
      cwLosses
      motorTruckCargoLimit
      towingLaborStorageLimitstr
      trailerInterchangeLimitstr
      trailerInterchangeLimitProgressive
      isProgKickOut
      vehiclesTowingOrHauling
      vehiclesHualSteel
      previousClaimCw
      totalAccidents
      totalNumberViolations
      totalSuspensions
      primaryAndNoncontributoryForTGL
      primaryAndNoncontributoryForAL
      requireFederalFillingForAL
      requireStateFillingForAL
      hauledRefrigeratedGoods
      ownerFname
      ownerLname
      ownerEmail
      ownerDob
      appStack
      appFlow
      insuredWithNICO
      policyNumbers
      effectiveDates
      newVenture
      towTruckRepossessions
      primaryBusiness
      ifNotPrimaryExplain
      Seasonal
      filedForBankruptcy
      whenFilledForBankruptcy
      explainForBankruptcy
      GrossReceiptsLastYear
      EstimateForComingYear
      BusinessForSale
      operateMoreThanOneState
      listStates
      largestCitiesEntered
      haulHazardousMaterialByEPA
      listingForChemicalMaterials
      hireVehicles
      driversCoveredCompensation
      ifYesNameOfCarrier
      minYearsDrivingExpRequired
      vehiclesOwnerDriven
      driversAllowedToTakeVehiclesHome
      maxDrivingHoursDaily
      maxDrivingHoursWeekly
      driverPayBasis
      lessorBeAdded
      nameAddressOfLessorForEachVehicle
      anyLossPayees
      nameAddressOfMortgageeForEachVehicle
      applicantAwareOfClaim
      provideCompleteDetails
      everBeenDeclined
      ifYesDateAndWhy
      additionalCoverageOptions
      intrastateFilingNeeded
      stateAndPermitNumber
      stateListForCargoFilings
      nameAndAddressPermitIssued
      MCS90EndorsementNeeded
      overWeightCommoditiesHauled
      ifFillingRequiredShowStates
      escortVehiclesTowed
      allowOthersToHaulUnderYourAuthority
      allowOthersToHaulHazardousCommodities
      everChangedYourOperatingName
      operateAnyOtherName
      leaseYourAuthority
      appointAgentsOnYourBehalf
      appliedForAuthorityPast3Years
      authorityWithdrawn
      evidenceRequired
      explainForAboveMultipleBooleanYes
      agreementsWithOtherCarriers
      withWhomAgreementHasBeenMade
      partiesNamedAutoMobilesLiability
      nameOfInsuranceCompanyAndLimitOfLiability
      whosePermitOtherPartiesOperate
      holdHarmlessInAgreement
      barterOrHireVehicles
      premiumBeFinanced
      cargoReqForMobileHomes
      cargoReqForBizDocsOrSecs
      fridgeBreakdownCoverage
      progMTCLimit
      compDeductible
      collDeductible
      forHireTruckingOps
      operateAWarehouse
      setUpOrInstallation
      frackingGasProd
      bizOutsideOfForHireOps
      progGLLimit
      vehicleLeaseProvidePrimaryLiability
      progNTLLimit
      progNonOwnedTrailerPDLimit
      progressive2ndPageKickOut
      filingState
      stateFilingsType
      proViewPolicyState
      snapShotProView
      proViewVehicleAnnualMileage
      proViewTermsAndConditions
      typeOfOpsCW
      monthInBusiness
      edited
      progCurrentPolicyexpirationDate
      coverWhaleOptAlUm
      coverWhaleOptAlPip
      authorityNumber
      indications {
        id
        carrier
        status
        estimatedPremium
      }
      File {
        id
        name
        description
        type
        url
      }
    }
  }
`;

export const EDIT_APPLICATION_MUTATION = gql`
  mutation editApplication($applicationId: ID!) {
    editApplication(applicationId: $applicationId) {
      id
      stage
      activeAuth
      averageViolations
      doTheseApply
      doTheseApplyCw
      preferedCarrier
      priorityStatus
      operationsDescription
      driversAbove23
      cdlExperience
      anySeriousViolations
      currentProgCust
      businessStructure
      driverSummary
      totalValueTrucks
      totalValueTrailers
      grossAnnualTruckRev
      annualTotalMileage
      haulsUnder50Miles
      haulsUnder200Miles
      haulsUnder500Miles
      haulsAbove500Miles
      currentlyInsured
      otherCoverages
      totalAdditionalInsureds
      totalNamedInsureds
      blanketAdditionalNeeded
      blanketWaiverSubrogations
      stateOrFederalFilingsReq
      snapshotPreview
      effectiveDate
      towingLaborStorageLimit
      trailerInterchangeLimit
      autoLiabilityLossCount
      autoLiabilityLossPaid
      physicalDamageLossCount
      physicalDamageLossPaid
      cargoLossCount
      cargoLossPaid
      truckerGenLiabLossCount
      truckerGenLiabLossPaid
      lossesConfirmedVsPending
      createdAt
      updatedAt
      isTest
      isThereRigging
      numberLosses3Years
      currentPage
      numberDrivers
      numberPowerUnits
      lobs
      avgRadOps
      domiciledWithin50Miles
      limitInsPerPowerUnit
      limitInsPerPowerUnitNonOwned
      perOccurenceLimit
      deductible
      spoilageFreezingCoverage
      mtcAdditionalCoverage
      pollutionCleanUp
      ownersGoodExtension
      nonOwnedTrailer
      expirationDate
      disclaimer
      status
      typeOfOps
      typeOfOpsDescriptionOther
      typeOfOpsFao
      glOrBop
      yearCurrentBusiness
      limitAl
      limitPd
      limitMtc
      limitGl
      limitNtl
      trailerInterchangeDeductible
      deductibleAl
      deductiblePd
      deductibleMtc
      deductibleGl
      deductibleNtl
      numberLossesPast3YearsGA
      # tarpsAndChains
      # reusablePackingContainers
      # subHaulerOwnerOrOperator
      # numberOfNonOwnedTrailers
      # debrisRemovalLimitGA
      # reloadingLimitGA
      # pollutionCleanUpLimitGA
      # earnedFreightLimitGA
      # fireDepartmentServiceChargesLimitGA
      # lossDataPreparationLimitGA
      # rewardCoverageLimitGA
      # reusablePackingContainersLimitGA
      # tarpsAndChainsLimitGA
      # annualValueShippedGA
      # totalCargoLossesInLast36MonthsGA
      continuousCoverage
      DOTObtain60Days
      typeOfTrucker
      ineligibleOperations
      vehicleHaulOilAndGas
      hazardousMaterialPlacard
      isCEODriver
      isCEOInvolvedInOperations
      bodilyInjuryLiabilityLimit
      otherGlOrBop
      priorInsuranceCancelledOrRenewed
      seriousViolationsWithinPast5Years
      ELDVendorName
      LossControlTechnology
      ELDRequiredToRecord
      commercialVehiclesInsuredOwns
      privatePassengerAutosInsured
      insuredVehiclesUsesInBusiness
      federal
      federalCargo
      progressiveState
      progressiveStateCargo
      progressiveOther
      progressiveEligibilityCriteria
      progressivePolicyState
      progressiveELDVendor
      ELDVendorAccess
      customerSpendLastYear
      nonOwnedVehiclesUsedInBusiness
      cargoCoverageForMobileHomes
      progressiveTrailerInterchangeCoverage
      trailerInterchangeFurnishAgreement
      nonOwnedTrailerPhysicalDamage
      MCS90
      isGAKickOut
      isCWKickOut
      cwLosses
      motorTruckCargoLimit
      towingLaborStorageLimitstr
      trailerInterchangeLimitstr
      trailerInterchangeLimitProgressive
      isProgKickOut
      vehiclesTowingOrHauling
      vehiclesHualSteel
      previousClaimCw
      totalAccidents
      totalNumberViolations
      totalSuspensions
      primaryAndNoncontributoryForTGL
      primaryAndNoncontributoryForAL
      requireFederalFillingForAL
      requireStateFillingForAL
      hauledRefrigeratedGoods
      ownerFname
      ownerLname
      ownerEmail
      ownerDob
      appStack
      appFlow
      insuredWithNICO
      policyNumbers
      effectiveDates
      newVenture
      towTruckRepossessions
      primaryBusiness
      ifNotPrimaryExplain
      Seasonal
      filedForBankruptcy
      whenFilledForBankruptcy
      explainForBankruptcy
      GrossReceiptsLastYear
      EstimateForComingYear
      BusinessForSale
      operateMoreThanOneState
      listStates
      largestCitiesEntered
      haulHazardousMaterialByEPA
      listingForChemicalMaterials
      hireVehicles
      driversCoveredCompensation
      ifYesNameOfCarrier
      minYearsDrivingExpRequired
      vehiclesOwnerDriven
      driversAllowedToTakeVehiclesHome
      maxDrivingHoursDaily
      maxDrivingHoursWeekly
      driverPayBasis
      lessorBeAdded
      nameAddressOfLessorForEachVehicle
      anyLossPayees
      nameAddressOfMortgageeForEachVehicle
      applicantAwareOfClaim
      provideCompleteDetails
      everBeenDeclined
      ifYesDateAndWhy
      additionalCoverageOptions
      intrastateFilingNeeded
      stateAndPermitNumber
      stateListForCargoFilings
      nameAndAddressPermitIssued
      MCS90EndorsementNeeded
      overWeightCommoditiesHauled
      ifFillingRequiredShowStates
      escortVehiclesTowed
      allowOthersToHaulUnderYourAuthority
      allowOthersToHaulHazardousCommodities
      everChangedYourOperatingName
      operateAnyOtherName
      leaseYourAuthority
      appointAgentsOnYourBehalf
      appliedForAuthorityPast3Years
      authorityWithdrawn
      evidenceRequired
      explainForAboveMultipleBooleanYes
      agreementsWithOtherCarriers
      withWhomAgreementHasBeenMade
      partiesNamedAutoMobilesLiability
      nameOfInsuranceCompanyAndLimitOfLiability
      whosePermitOtherPartiesOperate
      holdHarmlessInAgreement
      barterOrHireVehicles
      premiumBeFinanced
      cargoReqForMobileHomes
      cargoReqForBizDocsOrSecs
      fridgeBreakdownCoverage
      progMTCLimit
      compDeductible
      collDeductible
      forHireTruckingOps
      operateAWarehouse
      setUpOrInstallation
      frackingGasProd
      bizOutsideOfForHireOps
      progGLLimit
      vehicleLeaseProvidePrimaryLiability
      progNTLLimit
      progNonOwnedTrailerPDLimit
      progressive2ndPageKickOut
      filingState
      stateFilingsType
      proViewPolicyState
      snapShotProView
      proViewVehicleAnnualMileage
      proViewTermsAndConditions
      monthInBusiness
      typeOfOpsCW
      edited
      progCurrentPolicyexpirationDate

      indications {
        id
        carrier
        status
        estimatedPremium
      }
      File {
        id
        name
        description
        type
        url
      }

      quotes {
        effectiveDate
        expirationDate
        totalLimit
        totalPremium
        totalDeductible
        monthlyPayment
        downPaymentPercentage
        lob
        pfa
        id
        carrier
        ascendProgramId
        ascendProgramUrl
        ascendInvoiceId
        ascendInvoiceUrl
        ascendInvoiceNumber
        ascendInvoiceDueDate
        File {
          id
          name
          type
          url
        }
        products {
          File {
            id
            name
            type
            url
          }
          id
          status
          lob
          bindingPremium
          limit
          deductible
          quoteNumber
          invoicedBy
          admitted
          ascendQuoteId
          issuingCarrier
          billingCarrier
          quoteFileLocation
          quoteSigned
          quoteSignedFileLocation
          purchasedFlag
          oppStage
          downPaymentPercentage
          downPaymentAmount
          paymentType
          paymentSource
          applicationError
          declinedReason
          taxes
          platformFee
          PlatformFeeType
          policy {
            id
            policyNumber
          }
        }
      }

      company {
        accountOwner
      }
    }
  }
`;

export const EDIT_CW_USER_FLOW_APPLICATION_MUTATION = gql`
  mutation editApplication($applicationId: ID!) {
    editApplication(applicationId: $applicationId) {
      id
      stage
      activeAuth
      averageViolations
      doTheseApply
      doTheseApplyCw
      preferedCarrier
      priorityStatus
      operationsDescription
      driversAbove23
      cdlExperience
      anySeriousViolations
      currentProgCust
      businessStructure
      driverSummary
      totalValueTrucks
      totalValueTrailers
      grossAnnualTruckRev
      annualTotalMileage
      haulsUnder50Miles
      haulsUnder200Miles
      haulsUnder500Miles
      haulsAbove500Miles
      currentlyInsured
      otherCoverages
      totalAdditionalInsureds
      totalNamedInsureds
      blanketAdditionalNeeded
      blanketWaiverSubrogations
      stateOrFederalFilingsReq
      snapshotPreview
      effectiveDate
      towingLaborStorageLimit
      trailerInterchangeLimit
      autoLiabilityLossCount
      autoLiabilityLossPaid
      physicalDamageLossCount
      physicalDamageLossPaid
      cargoLossCount
      cargoLossPaid
      truckerGenLiabLossCount
      truckerGenLiabLossPaid
      lossesConfirmedVsPending
      createdAt
      updatedAt
      isTest
      isThereRigging
      numberLosses3Years
      currentPage
      numberDrivers
      numberPowerUnits
      lobs
      avgRadOps
      domiciledWithin50Miles
      limitInsPerPowerUnit
      limitInsPerPowerUnitNonOwned
      perOccurenceLimit
      deductible
      spoilageFreezingCoverage
      mtcAdditionalCoverage
      pollutionCleanUp
      ownersGoodExtension
      nonOwnedTrailer
      expirationDate
      disclaimer
      status
      typeOfOps
      typeOfOpsDescriptionOther
      typeOfOpsFao
      glOrBop
      yearCurrentBusiness
      limitAl
      limitPd
      limitMtc
      limitGl
      limitNtl
      trailerInterchangeDeductible
      deductibleAl
      deductiblePd
      deductibleMtc
      deductibleGl
      deductibleNtl
      numberLossesPast3YearsGA
      # tarpsAndChains
      # reusablePackingContainers
      # subHaulerOwnerOrOperator
      # numberOfNonOwnedTrailers
      # debrisRemovalLimitGA
      # reloadingLimitGA
      # pollutionCleanUpLimitGA
      # earnedFreightLimitGA
      # fireDepartmentServiceChargesLimitGA
      # lossDataPreparationLimitGA
      # rewardCoverageLimitGA
      # reusablePackingContainersLimitGA
      # tarpsAndChainsLimitGA
      # annualValueShippedGA
      # totalCargoLossesInLast36MonthsGA
      continuousCoverage
      DOTObtain60Days
      typeOfTrucker
      ineligibleOperations
      vehicleHaulOilAndGas
      hazardousMaterialPlacard
      isCEODriver
      isCEOInvolvedInOperations
      bodilyInjuryLiabilityLimit
      otherGlOrBop
      priorInsuranceCancelledOrRenewed
      seriousViolationsWithinPast5Years
      ELDVendorName
      LossControlTechnology
      ELDRequiredToRecord
      commercialVehiclesInsuredOwns
      privatePassengerAutosInsured
      insuredVehiclesUsesInBusiness
      federal
      federalCargo
      progressiveState
      progressiveStateCargo
      progressiveOther
      progressiveEligibilityCriteria
      progressivePolicyState
      progressiveELDVendor
      ELDVendorAccess
      customerSpendLastYear
      nonOwnedVehiclesUsedInBusiness
      cargoCoverageForMobileHomes
      progressiveTrailerInterchangeCoverage
      trailerInterchangeFurnishAgreement
      nonOwnedTrailerPhysicalDamage
      MCS90
      isGAKickOut
      isCWKickOut
      cwLosses
      motorTruckCargoLimit
      towingLaborStorageLimitstr
      trailerInterchangeLimitstr
      trailerInterchangeLimitProgressive
      isProgKickOut
      vehiclesTowingOrHauling
      vehiclesHualSteel
      previousClaimCw
      totalAccidents
      totalNumberViolations
      totalSuspensions
      primaryAndNoncontributoryForTGL
      primaryAndNoncontributoryForAL
      requireFederalFillingForAL
      requireStateFillingForAL
      hauledRefrigeratedGoods
      ownerFname
      ownerLname
      ownerEmail
      ownerDob
      appStack
      appFlow
      insuredWithNICO
      policyNumbers
      effectiveDates
      newVenture
      towTruckRepossessions
      primaryBusiness
      ifNotPrimaryExplain
      Seasonal
      filedForBankruptcy
      whenFilledForBankruptcy
      explainForBankruptcy
      GrossReceiptsLastYear
      EstimateForComingYear
      BusinessForSale
      operateMoreThanOneState
      listStates
      largestCitiesEntered
      haulHazardousMaterialByEPA
      listingForChemicalMaterials
      hireVehicles
      driversCoveredCompensation
      ifYesNameOfCarrier
      minYearsDrivingExpRequired
      vehiclesOwnerDriven
      driversAllowedToTakeVehiclesHome
      maxDrivingHoursDaily
      maxDrivingHoursWeekly
      driverPayBasis
      lessorBeAdded
      nameAddressOfLessorForEachVehicle
      anyLossPayees
      nameAddressOfMortgageeForEachVehicle
      applicantAwareOfClaim
      provideCompleteDetails
      everBeenDeclined
      ifYesDateAndWhy
      additionalCoverageOptions
      intrastateFilingNeeded
      stateAndPermitNumber
      stateListForCargoFilings
      nameAndAddressPermitIssued
      MCS90EndorsementNeeded
      overWeightCommoditiesHauled
      ifFillingRequiredShowStates
      escortVehiclesTowed
      allowOthersToHaulUnderYourAuthority
      allowOthersToHaulHazardousCommodities
      everChangedYourOperatingName
      operateAnyOtherName
      leaseYourAuthority
      appointAgentsOnYourBehalf
      appliedForAuthorityPast3Years
      authorityWithdrawn
      evidenceRequired
      explainForAboveMultipleBooleanYes
      agreementsWithOtherCarriers
      withWhomAgreementHasBeenMade
      partiesNamedAutoMobilesLiability
      nameOfInsuranceCompanyAndLimitOfLiability
      whosePermitOtherPartiesOperate
      holdHarmlessInAgreement
      barterOrHireVehicles
      premiumBeFinanced
      cargoReqForMobileHomes
      cargoReqForBizDocsOrSecs
      fridgeBreakdownCoverage
      progMTCLimit
      compDeductible
      collDeductible
      forHireTruckingOps
      operateAWarehouse
      setUpOrInstallation
      frackingGasProd
      bizOutsideOfForHireOps
      progGLLimit
      vehicleLeaseProvidePrimaryLiability
      progNTLLimit
      progNonOwnedTrailerPDLimit
      progressive2ndPageKickOut
      filingState
      stateFilingsType
      proViewPolicyState
      snapShotProView
      proViewVehicleAnnualMileage
      proViewTermsAndConditions
      monthInBusiness
      typeOfOpsCW
      edited
      progCurrentPolicyexpirationDate

      indications {
        id
        carrier
        status
        estimatedPremium
      }
      File {
        id
        name
        description
        type
        url
      }

      quotes {
        effectiveDate
        expirationDate
        totalLimit
        totalPremium
        totalDeductible
        monthlyPayment
        downPaymentPercentage
        lob
        pfa
        id
        carrier
        ascendProgramId
        ascendProgramUrl
        ascendInvoiceId
        ascendInvoiceUrl
        ascendInvoiceNumber
        ascendInvoiceDueDate
        File {
          id
          name
          type
          url
        }
        products {
          File {
            id
            name
            type
            url
          }
          id
          status
          lob
          bindingPremium
          limit
          deductible
          quoteNumber
          invoicedBy
          admitted
          ascendQuoteId
          issuingCarrier
          billingCarrier
          quoteFileLocation
          quoteSigned
          quoteSignedFileLocation
          purchasedFlag
          oppStage
          downPaymentPercentage
          downPaymentAmount
          paymentType
          paymentSource
          applicationError
          declinedReason
          taxes
          platformFee
          PlatformFeeType
          policy {
            id
            policyNumber
          }
        }
      }

      company {
        accountOwner
      }
    }
  }
`;

export const MOCK_CW_RATER_MUTATION = gql`
  mutation mockCWRaterMutation($cwRaterInput: CWRaterInput) {
    mockCWRaterMutation(cwRaterInput: $cwRaterInput)
  }
`;

export const DRIVER_CREATE_MUTATION = gql`
  mutation CreateDriver($input: DriverInput) {
    createDriver(input: $input) {
      driver {
        id
        firstName
        lastName
        dob
        dlNumber
        dlState
        dlClassType
        dlYearsLicensed
        unitType
        yearsExperience
        dateOfHire
        past3YearsAccidents
        past3YearsAccidentDates
        past3YearsViolations
        past3YearsViolationDates
        past3YearsSuspensions
        eligibility
        address
        city
        state
        zip
        ssn
        past5YearsAccidentsViolations
        majorConvictions
        majorConvictionsDates
        employeeType
        isOwner
        phone
        createdAt
        lastUpdated
        isTest
        email
        mvrStatus
        mvrCheckDate
        isDeleted
        mvrDocumentLink
        mvrCandidateId
        zohoId
        licenseIssueDate
        cdl
        isSr22
        sr22CaseNumber
        maritalStatus
        status
        hasAnyViolationsInPast5Years
        past3YearMajorViolations
        totalMajorConvictions
        accidentsViolationsDetails
        totalMajorConvictionsDates
        endorsment {
          id
        }
      }
      token
    }
  }
`;

export const DRIVER_UPDATE_MUTATION = gql`
  mutation UpdateDriver($driverId: ID!, $input: DriverInput) {
    updateDriver(driverId: $driverId, input: $input) {
      id
      firstName
      lastName
      dob
      dlNumber
      dlState
      dlClassType
      dlYearsLicensed
      unitType
      yearsExperience
      dateOfHire
      past3YearsAccidents
      past3YearsAccidentDates
      past3YearsViolations
      past3YearsViolationDates
      past3YearsSuspensions
      eligibility
      address
      city
      state
      zip
      ssn
      past5YearsAccidentsViolations
      majorConvictions
      majorConvictionsDates
      employeeType
      isOwner
      phone
      createdAt
      lastUpdated
      isTest
      email
      mvrStatus
      mvrCheckDate
      isDeleted
      mvrDocumentLink
      mvrCandidateId
      zohoId
      licenseIssueDate
      cdl
      isSr22
      sr22CaseNumber
      maritalStatus
      status
      hasAnyViolationsInPast5Years
      past3YearMajorViolations
      totalMajorConvictions
      accidentsViolationsDetails
      totalMajorConvictionsDates
      endorsment {
        id
      }
    }
  }
`;

export const COMMODITY_CREATE_MUTATION = gql`
  mutation CreateCommodity($input: CommodityInput!) {
    createCommodity(input: $input) {
      commodity {
        id
        type
        subType
        minValue
        maxValue
        percentage
        status
        isDeleted
        endorsment {
          id
        }
      }
      token
    }
  }
`;

export const VEHICLE_CREATE_MUTATION = gql`
  mutation CreateVehicle($input: VehicleInput) {
    createVehicle(input: $input) {
      token
      vehicle {
        id
        company {
          id
        }
        endorsment {
          id
        }
        vehicleType
        modelYear
        vehicleMake
        vehicleModel
        subTruckType
        subTrailerType
        vehicleZip
        farthestDistance
        valuePermEquipment
        businessOrPersonal
        avgTripsPerDay
        value
        ownership
        vin
        gvw
        rearAxles
        garagingCity
        operationRadius
        annualMileage
        safetyFeatures
        limit
        createdAt
        lastUpdated
        isTest
        category
        bodyStyle
        radius
        needCoverage
        vehicleUse
        vehicleClass
        antiLockBrakes
        antiTheft
        airbag
        status
        typeOfTrailerHitch
        grossVehicleWeight
        vehicleTonnage
        numberOfDrivingWheels
        percentageOfRepossessionWork
        loanOrLease
        isDeleted
        numberOfRearAxles
        numberOfPassengerSeats
        vehicleEquippedWheelchair
        stretchLength
        typeOfSUV
        vehicleTankSize
        haulGoodsOnForHireBasis
        transportPassengersRevenue
        perimeterSeatingOrPartyBus
        numberOfStalls
        publicTransportationIndicator
        permanentFoundation
        lengthOfTrailer
        glassVinEtching
        collision
        comprehensive
        cwDeductibles
        inPossessionForMoreThan30Days
        auxiliaryRunning
        vehicleRegistrant
        loanCompany
        loanOtherCompanyName
        loanOtherCompanyMailing
        loanOtherCompanyCity
        loanOtherCompanyZip
        leaseCompany
        leaseOtherCompanyName
        leaseOtherCompanyMailing
        leaseOtherCompanyCity
        leaseOtherCompanyZip
      }
    }
  }
`;

export const VEHICLE_UPDATE_MUTATION = gql`
  mutation UpdateVehicle($vehicleId: ID!, $input: VehicleInput) {
    updateVehicle(vehicleId: $vehicleId, input: $input) {
      id
      company {
        id
      }
      endorsment {
        id
      }
      vehicleType
      modelYear
      vehicleMake
      subTruckType
      subTrailerType
      vehicleModel
      vehicleZip
      farthestDistance
      valuePermEquipment
      businessOrPersonal
      avgTripsPerDay
      value
      ownership
      vin
      gvw
      rearAxles
      garagingCity
      operationRadius
      annualMileage
      safetyFeatures
      limit
      createdAt
      lastUpdated
      isTest
      category
      bodyStyle
      radius
      needCoverage
      vehicleUse
      vehicleClass
      antiLockBrakes
      antiTheft
      airbag
      status
      typeOfTrailerHitch
      grossVehicleWeight
      vehicleTonnage
      numberOfDrivingWheels
      percentageOfRepossessionWork
      loanOrLease
      isDeleted
      numberOfRearAxles
      numberOfPassengerSeats
      vehicleEquippedWheelchair
      stretchLength
      typeOfSUV
      vehicleTankSize
      haulGoodsOnForHireBasis
      transportPassengersRevenue
      perimeterSeatingOrPartyBus
      numberOfStalls
      publicTransportationIndicator
      permanentFoundation
      lengthOfTrailer
      glassVinEtching
      collision
      comprehensive
      cwDeductibles
      inPossessionForMoreThan30Days
      auxiliaryRunning
      vehicleRegistrant
      loanCompany
      loanOtherCompanyName
      loanOtherCompanyMailing
      loanOtherCompanyCity
      loanOtherCompanyZip
      leaseCompany
      leaseOtherCompanyName
      leaseOtherCompanyMailing
      leaseOtherCompanyCity
      leaseOtherCompanyZip
    }
  }
`;

export const COMMODITY_UPDATE_MUTATION = gql`
  mutation UpdateCommodity($commodityId: ID!, $input: CommodityInput!) {
    updateCommodity(commodityId: $commodityId, input: $input) {
      id
      type
      subType
      minValue
      maxValue
      percentage
      status
      isDeleted
      endorsment {
        id
      }
    }
  }
`;

export const GENERATE_OTP_MUTATION = gql`
  mutation resendOtp($to: String!, $channel: String!) {
    resendOtp(to: $to, channel: $channel) {
      message
    }
  }
`;

export const UPDATE_USER_ROLE_MUTATION = gql`
  mutation updateUserRole($role: Role!, $userId: ID!) {
    updateUserRole(role: $role, userId: $userId) {
      phone
      email
      lastLogin
      role
      accessAllowed
    }
  }
`;

export const VERIFY_OTP_MUTATION = gql`
  mutation verifyOtp($to: String!, $otp: String!) {
    verifyOtp(to: $to, otp: $otp) {
      token
      auth {
        id
        phone
        email
        lastLogin
        role
        accessAllowed
      }
      lead {
        id
        firstName
        lastName
        email
        phone
        mobile
        parentLead {
          id
        }
      }
      account {
        id
        contacts {
          id
        }
        dayAMTransitionEdit
        policyChangeData
        recordImage
        accountManager
        accountName
        notes1
        activePendingList
        accountNumber
        owner
        accountShortName
        accountSite
        accountStage
        accountType
        activityLevel
        alternateContact
        annualPayroll
        annualRevenue
        garagingCity
        garagingCounty
        garagingMail
        garagingNumberOfUnits
        garagingState
        garagingStreet
        garagingZipCode
        businessEntityType
        cargo
        cargoLimits
        checkbox1
        claimsLink
        comm
        comments
        commodity
        additionalInsured
        lossPayee
        additionalInsuredSubFields
        uiiaAccount
        injuryProtectionAmount
        breakdownCoverage
        specialVerbiage
        partnerContact
        partnerContactEmail
        opportunityDescription
        partnerContactPhone
        contract
        createdBy
        dbaName
        dealTerms
        description
        dotNumber
        dotNumber1
        ebitda
        eldVendor
        employees
        eldProvider
        estimateAnnualPartnershipRevenue
        estimatedAnnualPartnershipRevenueToLT
        estimatedNumberOfLeadsToLT
        fax
        garagingCity
        garagingState
        garagingStreet
        garagingZipCode
        industry
        investmentThesis
        layout
        leadCost
        leadSource
        legacyAccount
        liabilityLimits
        limitsRequired
        maStage
        maType
        maxInvestment
        mcNumber
        minInvestment
        modifiedBy
        monetizationDescriptions
        naicCode
        numberOfEmployees
        numberOfUnits
        ownerYearsOfExperience
        ownership
        parentAccount
        partnershipType
        paymentBasedOn
        phone
        platformAccountID
        platformAccountUsers
        prioritization
        rating
        referedBy
        referredBy
        resourceAndInvestmentDescription
        resourceAndInvestmentNeeded
        revenueSize
        saferScore
        seriesPreference
        shippingCity
        shippingCode
        shippingCountry
        shippingState
        shippingStreet
        sicCode
        similarInvestments
        subform1
        tag
        tickerSymbol
        totalActivePremium
        underwritingCompanies
        web
        website
        yearsInBusiness
        city
        commonApplicationPending
        commonAuthorityStatus
        contractApplicationPending
        contractAuthorityStatus
        crashedFatal
        crashedInjury
        crashedTotal
        crashedTow
        dunsNumber
        inspectionsDriver
        inspectionsHazmat
        inspectionsLEP
        inspectionsVehicle
        insuranceHistoryListData
        mailZipCode
        mailingCity
        mailingCounty
        mailingState
        mailingStreet
        mcs150FormDate
        natlAverageDriver
        natlAverageHazmat
        natlAverageLEP
        natlAverageVehicle
        numberOfDrivers
        operatingStatus
        operationClassification
        outOfServiceDriver
        outOfServiceHazmat
        outOfServiceLEP
        outOfServicePercentageDriverr
        outOfServicePercentageHazmat
        outOfServicePercentageLEP
        outOfServicePercentageVehicle
        outOfServiceVehicle
        pendingApplicationList
        rejectionInsuranceList
        state
        street
        zipCode
        cargoApplicationPending
        cargoAuthorityStatus
        cargoCarried
        cargoInsuranceOnFile
        cargoInsuranceRequired
        carrierOperation
        bipdApplicationPending
        bipdAuthorityStatus
        bipdInsuranceOnFile
        bipdInsuranceRequired
        bondApplicationPending
        bondAuthorityStatus
        bondInsuranceOnFile
        bondInsuranceRequired
        brokerApplicationPending
        brokerAuthorityStatus
        createdAt
        updatedAt
        brokerAssisted
      }
      contact {
        id
        firstName
        lastName
        email
        phone
        mobile
        role
      }
    }
  }
`;

export const VERIFY_DISPATCHER_OTP_MUTATION = gql`
  mutation verifyDispatcherOTP($to: String!, $code: String!) {
    verifyDispatcherOTP(to: $to, code: $code) {
      token
      user {
        id
        name
        email
        phone
      }
    }
  }
`;

export const VERIFY_LT_OTP_MUTATION = gql`
  mutation verifyLtUserOtp($emailOtp: String!, $phoneOtp: String!) {
    verifyLtUserOtp(emailOtp: $emailOtp, phoneOtp: $phoneOtp) {
      token
      user {
        id
        name
        roles
        email
        phone
      }
    }
  }
`;

export const FETCH_ALL_COMPANIES_LIST_PAGINATED = gql`
  mutation fetchAllCompaniesPaginated($page: Int!, $org: Organizations) {
    fetchAllCompaniesPaginated(page: $page, org: $org) {
      count
      companies {
        id
        dotNumber
        dbaName
        company
      }
    }
  }
`;
export const SEARCH_COMPANIES_LIST_PAGINATED = gql`
  mutation fetchSearchCompaniesPaginated(
    $page: Int!
    $text: String!
    $org: Organizations
  ) {
    fetchSearchCompaniesPaginated(page: $page, text: $text, org: $org) {
      count
      companies {
        id
        dotNumber
        dbaName
        company
      }
    }
  }
`;

export const DISPATCHER_LOGIN_MUTATION = gql`
  mutation dispatcherLogin(
    $email: String!
    $password: String!
    $org: Organizations
  ) {
    dispatcherLogin(email: $email, password: $password, org: $org) {
      token
      user {
        id
        name
        email
        phone
      }
    }
  }
`;

export const UPDATE_DISPATCHER_PHONE_MUTATION = gql`
  mutation updateDispatcherPhone($phone: String!, $email: String!) {
    updateDispatcherPhone(phone: $phone, email: $email) {
      id
      name
      phone
      email
    }
  }
`;

export const COMPANY_LOGIN_BY_LT_USER_MUTATION = gql`
  mutation companyLoginByLTUser($companyId: String!) {
    companyLoginByLTUser(companyId: $companyId) {
      contact {
        id
        firstName
        lastName
        email
        phone
        mobile
        role
      }
      auth {
        id
        contactId
        leadId
        email
        role
        socialProvider
        providerId
        phoneVerified
        isAuthorized
        accessAllowed
        lastLogin
        phone
        org
      }
      account {
        id
        contacts {
          id
        }
        dayAMTransitionEdit
        policyChangeData
        recordImage
        accountManager
        accountName
        notes1
        activePendingList
        accountNumber
        owner
        accountShortName
        accountSite
        accountStage
        accountType
        activityLevel
        alternateContact
        annualPayroll
        annualRevenue
        garagingCity
        garagingCounty
        garagingMail
        garagingNumberOfUnits
        garagingState
        garagingStreet
        garagingZipCode
        businessEntityType
        cargo
        cargoLimits
        checkbox1
        claimsLink
        comm
        comments
        commodity
        additionalInsured
        lossPayee
        additionalInsuredSubFields
        uiiaAccount
        injuryProtectionAmount
        breakdownCoverage
        specialVerbiage
        partnerContact
        partnerContactEmail
        opportunityDescription
        partnerContactPhone
        contract
        createdBy
        dbaName
        dealTerms
        description
        dotNumber
        dotNumber1
        ebitda
        eldVendor
        employees
        eldProvider
        estimateAnnualPartnershipRevenue
        estimatedAnnualPartnershipRevenueToLT
        estimatedNumberOfLeadsToLT
        fax
        garagingCity
        garagingState
        garagingStreet
        garagingZipCode
        industry
        investmentThesis
        layout
        leadCost
        leadSource
        legacyAccount
        liabilityLimits
        limitsRequired
        maStage
        maType
        maxInvestment
        mcNumber
        minInvestment
        modifiedBy
        monetizationDescriptions
        naicCode
        numberOfEmployees
        numberOfUnits
        ownerYearsOfExperience
        ownership
        parentAccount
        partnershipType
        paymentBasedOn
        phone
        platformAccountID
        platformAccountUsers
        prioritization
        rating
        referedBy
        referredBy
        resourceAndInvestmentDescription
        resourceAndInvestmentNeeded
        revenueSize
        saferScore
        seriesPreference
        shippingCity
        shippingCode
        shippingCountry
        shippingState
        shippingStreet
        sicCode
        similarInvestments
        subform1
        tag
        tickerSymbol
        totalActivePremium
        underwritingCompanies
        web
        website
        yearsInBusiness
        city
        commonApplicationPending
        commonAuthorityStatus
        contractApplicationPending
        contractAuthorityStatus
        crashedFatal
        crashedInjury
        crashedTotal
        crashedTow
        dunsNumber
        inspectionsDriver
        inspectionsHazmat
        inspectionsLEP
        inspectionsVehicle
        insuranceHistoryListData
        mailZipCode
        mailingCity
        mailingCounty
        mailingState
        mailingStreet
        mcs150FormDate
        natlAverageDriver
        natlAverageHazmat
        natlAverageLEP
        natlAverageVehicle
        numberOfDrivers
        operatingStatus
        operationClassification
        outOfServiceDriver
        outOfServiceHazmat
        outOfServiceLEP
        outOfServicePercentageDriverr
        outOfServicePercentageHazmat
        outOfServicePercentageLEP
        outOfServicePercentageVehicle
        outOfServiceVehicle
        pendingApplicationList
        rejectionInsuranceList
        state
        street
        zipCode
        cargoApplicationPending
        cargoAuthorityStatus
        cargoCarried
        cargoInsuranceOnFile
        cargoInsuranceRequired
        carrierOperation
        bipdApplicationPending
        bipdAuthorityStatus
        bipdInsuranceOnFile
        bipdInsuranceRequired
        bondApplicationPending
        bondAuthorityStatus
        bondInsuranceOnFile
        bondInsuranceRequired
        brokerApplicationPending
        brokerAuthorityStatus
        createdAt
        updatedAt
        brokerAssisted
      }
      lead {
        id
        zohoId
        account {
          id
          accountManager
          leadId
          dayAMTransitionEdit
          policyChangeData
          recordImage
          accountName
          notes1
          activePendingList
          accountNumber
          owner
          accountShortName
          accountSite
          accountStage
          accountType
          activityLevel
          alternateContact
          annualPayroll
          annualRevenue
          garagingCity
          garagingCounty
          garagingMail
          garagingNumberOfUnits
          garagingState
          garagingStreet
          garagingZipCode
          businessEntityType
          cargo
          cargoLimits
          claimsLink
          comments
          commodity
          createdAt
          additionalInsured
          lossPayee
          additionalInsuredSubFields
          uiiaAccount
          injuryProtectionAmount
          breakdownCoverage
          specialVerbiage
          brokerAuthorityStatus
          brokerApplicationPending
          bondInsuranceRequired
          bondInsuranceOnFile
          bondAuthorityStatus
          bondApplicationPending
          bipdInsuranceRequired
          bipdInsuranceOnFile
          bipdAuthorityStatus
          bipdApplicationPending
          carrierOperation
          cargoInsuranceRequired
          cargoInsuranceOnFile
          cargoCarried
          eldProvider
          cargoAuthorityStatus
          cargoApplicationPending
          zipCode
          street
          state
          rejectionInsuranceList
          pendingApplicationList
          outOfServiceVehicle
          outOfServicePercentageVehicle
          outOfServicePercentageLEP
          outOfServicePercentageHazmat
          outOfServicePercentageDriverr
          outOfServiceLEP
          outOfServiceHazmat
          outOfServiceDriver
          operationClassification
          operatingStatus
          numberOfDrivers
          natlAverageVehicle
          natlAverageLEP
          natlAverageHazmat
          natlAverageDriver
          mcs150FormDate
          mailingState
          mailingCounty
          mailingCity
          mailZipCode
          insuranceHistoryListData
          inspectionsVehicle
          inspectionsLEP
          inspectionsHazmat
          inspectionsDriver
          dunsNumber
          crashedTow
          crashedTotal
          crashedInjury
          crashedFatal
          contractAuthorityStatus
          contractApplicationPending
          commonAuthorityStatus
          commonApplicationPending
          city
          yearsInBusiness
          website
          web
          underwritingCompanies
          totalActivePremium
          tickerSymbol
          tag
          subform1
          similarInvestments
          sicCode
          shippingStreet
          shippingState
          shippingCountry
          shippingCode
          shippingCity
          seriesPreference
          saferScore
          revenueSize
          resourceAndInvestmentNeeded
          resourceAndInvestmentDescription
          referredBy
          referedBy
          rating
          prioritization
          platformAccountUsers
          platformAccountID
          phone
          paymentBasedOn
          partnershipType
          parentAccount
          ownership
          ownerYearsOfExperience
          numberOfUnits
          numberOfEmployees
          naicCode
          monetizationDescriptions
          modifiedBy
          minInvestment
          mcNumber
          partnerContact
          partnerContactEmail
          opportunityDescription
          partnerContactPhone
          contract
          createdBy
          dbaName
          dealTerms
          description
          dotNumber
          dotNumber1
          ebitda
          eldVendor
          employees
          estimateAnnualPartnershipRevenue
          estimatedAnnualPartnershipRevenueToLT
          estimatedNumberOfLeadsToLT
          fax
          garagingCity
          garagingState
          garagingStreet
          garagingZipCode
          industry
          investmentThesis
          layout
          leadCost
          leadSource
          legacyAccount
          liabilityLimits
          limitsRequired
          maType
          maStage
          maxInvestment
          commodities {
            id
            type
            subType
            minValue
            maxValue
            percentage
            endorsment {
              id
            }
            status
            isDeleted
          }
          trucks {
            id
            endorsment {
              id
            }
            vehicleType
            modelYear
            vehicleMake
            subTruckType
            subTrailerType
            vehicleModel
            vehicleZip
            farthestDistance
            valuePermEquipment
            businessOrPersonal
            avgTripsPerDay
            value
            ownership
            vin
            gvw
            rearAxles
            garagingCity
            operationRadius
            annualMileage
            safetyFeatures
            limit
            createdAt
            lastUpdated
            isTest
            category
            bodyStyle
            radius
            needCoverage
            vehicleUse
            vehicleClass
            antiLockBrakes
            antiTheft
            airbag
            status
            typeOfTrailerHitch
            grossVehicleWeight
            vehicleTonnage
            numberOfDrivingWheels
            percentageOfRepossessionWork
            loanOrLease
            isDeleted
            numberOfRearAxles
            numberOfPassengerSeats
            vehicleEquippedWheelchair
            stretchLength
            typeOfSUV
            vehicleTankSize
            haulGoodsOnForHireBasis
            transportPassengersRevenue
            perimeterSeatingOrPartyBus
            numberOfStalls
            publicTransportationIndicator
            permanentFoundation
            lengthOfTrailer
            glassVinEtching
            collision
            comprehensive
            cwDeductibles
            inPossessionForMoreThan30Days
            auxiliaryRunning
            vehicleRegistrant
            loanCompany
            loanOtherCompanyName
            loanOtherCompanyMailing
            loanOtherCompanyCity
            loanOtherCompanyZip
            leaseCompany
            leaseOtherCompanyName
            leaseOtherCompanyMailing
            leaseOtherCompanyCity
            leaseOtherCompanyZip
          }
          drivers {
            id
            endorsment {
              id
            }
            firstName
            lastName
            dob
            dlNumber
            dlState
            dlClassType
            dlYearsLicensed
            unitType
            yearsExperience
            dateOfHire
            past3YearsAccidents
            past3YearsAccidentDates
            past3YearsViolations
            past3YearsViolationDates
            past3YearsSuspensions
            eligibility
            address
            city
            state
            zip
            ssn
            past5YearsAccidentsViolations
            majorConvictions
            majorConvictionsDates
            employeeType
            isOwner
            phone
            createdAt
            lastUpdated
            isTest
            email
            mvrStatus
            mvrCheckDate
            isDeleted
            MotorVehicleRecord {
              id
              reportId
              reportUri
              mvrId
              createdAt
              updatedAt
              turnaround_time
              license_number
              license_state
              license_status
              license_type
              license_class
              expiration_date
              issued_date
              first_issued_date
              inferred_issued_date
              restrictions
              custom_rules
              previous_license_number
              previous_license_state
              not_found
              experience_failed
              result
              violations
              accidents
              endorsements
              documentUri
              File {
                id
                name
                description
                type
                url
                zohoId
                createdAt
                lastUpdated
              }
            }
            mvrDocumentLink
            mvrCandidateId
            zohoId
            licenseIssueDate
            cdl
            isSr22
            sr22CaseNumber
            maritalStatus
            status
            hasAnyViolationsInPast5Years
            past3YearMajorViolations
            totalMajorConvictions
            accidentsViolationsDetails
            totalMajorConvictionsDates
          }
        }
        contact {
          id
        }
        otherLeads {
          id
        }
        parentLeadId
        parentLead {
          id
        }
        activePendingList
        annualPayroll
        annualRevenue
        authorityHistoryList
        avgTimeSpent
        ascendInsuredId
        garagingCity
        garagingCounty
        garagingMail
        garagingNumberOfUnits
        garagingState
        garagingStreet
        garagingZipCode
        bipdInsuranceOnFile
        bipdInsuranceRequired
        bondInsuranceOnFile
        bondInsuranceRequired
        brokerAppPending
        brokerAuthStatus
        businessDescription
        businessEntityType
        cargo
        cargoCarried
        cargoCarrier
        cargoInsuranceOnFile
        cargoInsuranceRequired
        cargoLimits
        carrierOperation
        city
        commonAppPending
        commonAuthStatus
        company
        contactTitle
        contractAppPending
        contractAuthStatus
        convertedAccount
        convertedContact
        convertedDeal
        county
        crashedFatal
        crashedInjury
        crashedTotal
        crashedTow
        createdBy
        daysVisited
        dbaName
        designation
        dotNumber
        dotRegistration
        dunsNumber
        email
        emailNotValid
        emailOptOut
        expiringInsuranceCompany
        fax
        firstName
        firstPageVisited
        firstVisit
        fullTimeEmployees
        inspectionsDriver
        inspectionsHazmat
        inspectionsLep
        InspectionsVehicle
        insuranceHistoryListData
        insuranceType
        lastKnownAutoExpirationDate
        lastKnownCargoExpirationDate
        lastKnownWcExpirationDate
        lastName
        layout
        leadCost
        leadOwner
        leadSource
        leadSourceLeadId
        leadStatus
        leadType
        liabilityCarrier
        liabilityLimits
        limitsRequired
        listCampaignType
        mailZipCode
        mailingCity
        mailingCounty
        mailingMail
        mailingNumberOfUnits
        mailingState
        mailingStreet
        marketingAutoScore
        maximumRadiusOfOperations
        mcNumber
        mcs150FormDate
        mobile
        modifiedBy
        mostRecentVisit
        naicCode
        natlAverageDriver
        natlAverageHazmat
        natlAverageLep
        natlAverageVehicle
        noOfEmployees
        numberOfChats
        numberOfDrivers
        numberOfUnits
        operatingStatus
        operationClassification
        outOfServiceDriver
        outOfServiceHazmat
        outOfServiceLep
        outOfServicePercentageDriver
        outOfServicePercentageHazmat
        outOfServicePercentageLep
        outOfServicePercentageVehicle
        outOfServiceVehicle
        ownerYearsOfExperience
        partTimeEmployees
        pendingApplicationList
        phone
        physicalMail
        policyNumber
        referrer
        rejectionInsuranceList
        revocationListData
        sdrLead
        sicCode
        spId
        spTier
        spType
        state
        street
        subContractorCosts
        visitorScore
        website
        yearsInBusiness
        zipCode
        org
        auth {
          id
        }
        createdAt
        updatedAt
      }
      token
    }
  }
`;

export const ZOHO_SESSION_FOR_LT_USER_MUTATION = gql`
  mutation zohoSessionForLtUser($companyId: String!, $entityType: String!) {
    zohoSessionForLtUser(companyId: $companyId, entityType: $entityType) {
      contact {
        id
        firstName
        lastName
        email
        phone
        mobile
        role
      }
      ltUser {
        id
        isAdmin
        name
        email
        phone
      }
      auth {
        id
        contactId
        leadId
        email
        role
        socialProvider
        providerId
        phoneVerified
        isAuthorized
        accessAllowed
        lastLogin
        phone
        org
      }
      account {
        id
        leadId
        contacts {
          id
        }
        dayAMTransitionEdit
        policyChangeData
        recordImage
        havePrevAuthority
        accountManager
        accountName
        notes1
        activePendingList
        accountNumber
        owner
        accountShortName
        accountSite
        accountStage
        accountType
        activityLevel
        alternateContact
        annualPayroll
        annualRevenue
        garagingCity
        garagingCounty
        garagingMail
        garagingNumberOfUnits
        garagingState
        garagingStreet
        garagingZipCode
        businessEntityType
        cargo
        cargoLimits
        checkbox1
        claimsLink
        comm
        comments
        commodity
        additionalInsured
        lossPayee
        additionalInsuredSubFields
        uiiaAccount
        injuryProtectionAmount
        breakdownCoverage
        specialVerbiage
        partnerContact
        partnerContactEmail
        opportunityDescription
        partnerContactPhone
        contract
        createdBy
        dbaName
        dealTerms
        description
        dotNumber
        dotNumber1
        ebitda
        eldVendor
        employees
        eldProvider
        estimateAnnualPartnershipRevenue
        estimatedAnnualPartnershipRevenueToLT
        estimatedNumberOfLeadsToLT
        fax
        garagingCity
        garagingState
        garagingStreet
        garagingZipCode
        industry
        investmentThesis
        layout
        leadCost
        leadSource
        legacyAccount
        liabilityLimits
        limitsRequired
        maStage
        maType
        maxInvestment
        mcNumber
        minInvestment
        modifiedBy
        monetizationDescriptions
        naicCode
        numberOfEmployees
        numberOfUnits
        ownerYearsOfExperience
        ownership
        parentAccount
        partnershipType
        paymentBasedOn
        phone
        platformAccountID
        platformAccountUsers
        prioritization
        rating
        referedBy
        referredBy
        resourceAndInvestmentDescription
        resourceAndInvestmentNeeded
        revenueSize
        saferScore
        seriesPreference
        shippingCity
        shippingCode
        shippingCountry
        shippingState
        shippingStreet
        sicCode
        similarInvestments
        subform1
        tag
        tickerSymbol
        totalActivePremium
        underwritingCompanies
        web
        website
        yearsInBusiness
        city
        commonApplicationPending
        commonAuthorityStatus
        contractApplicationPending
        contractAuthorityStatus
        crashedFatal
        crashedInjury
        crashedTotal
        crashedTow
        dunsNumber
        inspectionsDriver
        inspectionsHazmat
        inspectionsLEP
        inspectionsVehicle
        insuranceHistoryListData
        mailZipCode
        mailingCity
        mailingCounty
        mailingState
        mcs150FormDate
        natlAverageDriver
        natlAverageHazmat
        natlAverageLEP
        natlAverageVehicle
        numberOfDrivers
        operatingStatus
        operationClassification
        outOfServiceDriver
        outOfServiceHazmat
        outOfServiceLEP
        outOfServicePercentageDriverr
        outOfServicePercentageHazmat
        outOfServicePercentageLEP
        outOfServicePercentageVehicle
        outOfServiceVehicle
        pendingApplicationList
        rejectionInsuranceList
        state
        street
        zipCode
        cargoApplicationPending
        cargoAuthorityStatus
        cargoCarried
        cargoInsuranceOnFile
        cargoInsuranceRequired
        carrierOperation
        bipdApplicationPending
        bipdAuthorityStatus
        bipdInsuranceOnFile
        bipdInsuranceRequired
        bondApplicationPending
        bondAuthorityStatus
        bondInsuranceOnFile
        bondInsuranceRequired
        brokerApplicationPending
        brokerAuthorityStatus
        createdAt
        updatedAt
        brokerAssisted
      }
      lead {
        id
        zohoId
        account {
          id
          accountManager
          leadId
          dayAMTransitionEdit
          policyChangeData
          recordImage
          accountName
          havePrevAuthority
          notes1
          activePendingList
          accountNumber
          owner
          accountShortName
          accountSite
          accountStage
          accountType
          activityLevel
          alternateContact
          annualPayroll
          annualRevenue
          garagingCity
          garagingCounty
          garagingMail
          garagingNumberOfUnits
          garagingState
          garagingStreet
          garagingZipCode
          businessEntityType
          cargo
          cargoLimits
          claimsLink
          comments
          commodity
          createdAt
          additionalInsured
          lossPayee
          additionalInsuredSubFields
          uiiaAccount
          injuryProtectionAmount
          breakdownCoverage
          specialVerbiage
          brokerAuthorityStatus
          brokerApplicationPending
          bondInsuranceRequired
          bondInsuranceOnFile
          bondAuthorityStatus
          bondApplicationPending
          bipdInsuranceRequired
          bipdInsuranceOnFile
          bipdAuthorityStatus
          bipdApplicationPending
          carrierOperation
          cargoInsuranceRequired
          cargoInsuranceOnFile
          cargoCarried
          cargoAuthorityStatus
          cargoApplicationPending
          zipCode
          street
          state
          rejectionInsuranceList
          pendingApplicationList
          outOfServiceVehicle
          outOfServicePercentageVehicle
          outOfServicePercentageLEP
          outOfServicePercentageHazmat
          outOfServicePercentageDriverr
          outOfServiceLEP
          outOfServiceHazmat
          outOfServiceDriver
          operationClassification
          operatingStatus
          numberOfDrivers
          natlAverageVehicle
          natlAverageLEP
          natlAverageHazmat
          natlAverageDriver
          mcs150FormDate
          mailingState
          mailingCounty
          mailingCity
          mailZipCode
          eldProvider
          insuranceHistoryListData
          inspectionsVehicle
          inspectionsLEP
          inspectionsHazmat
          inspectionsDriver
          dunsNumber
          crashedTow
          crashedTotal
          crashedInjury
          crashedFatal
          contractAuthorityStatus
          contractApplicationPending
          commonAuthorityStatus
          commonApplicationPending
          city
          yearsInBusiness
          website
          web
          underwritingCompanies
          totalActivePremium
          tickerSymbol
          tag
          subform1
          similarInvestments
          sicCode
          shippingStreet
          shippingState
          shippingCountry
          shippingCode
          shippingCity
          seriesPreference
          saferScore
          revenueSize
          resourceAndInvestmentNeeded
          resourceAndInvestmentDescription
          referredBy
          referedBy
          rating
          prioritization
          platformAccountUsers
          platformAccountID
          phone
          paymentBasedOn
          partnershipType
          parentAccount
          ownership
          ownerYearsOfExperience
          numberOfUnits
          numberOfEmployees
          naicCode
          monetizationDescriptions
          modifiedBy
          minInvestment
          mcNumber
          partnerContact
          partnerContactEmail
          opportunityDescription
          partnerContactPhone
          contract
          createdBy
          dbaName
          dealTerms
          description
          dotNumber
          dotNumber1
          ebitda
          eldVendor
          employees
          estimateAnnualPartnershipRevenue
          estimatedAnnualPartnershipRevenueToLT
          estimatedNumberOfLeadsToLT
          fax
          garagingCity
          garagingState
          garagingStreet
          garagingZipCode
          industry
          investmentThesis
          layout
          leadCost
          leadSource
          legacyAccount
          liabilityLimits
          limitsRequired
          maType
          maStage
          maxInvestment
          commodities {
            id
            type
            subType
            minValue
            maxValue
            percentage
            endorsment {
              id
            }
            status
            isDeleted
          }
          trucks {
            id
            endorsment {
              id
            }
            vehicleType
            modelYear
            vehicleMake
            vehicleModel
            subTruckType
            subTrailerType
            vehicleZip
            farthestDistance
            valuePermEquipment
            businessOrPersonal
            avgTripsPerDay
            value
            ownership
            vin
            gvw
            rearAxles
            garagingCity
            operationRadius
            annualMileage
            safetyFeatures
            limit
            createdAt
            lastUpdated
            isTest
            category
            bodyStyle
            radius
            needCoverage
            vehicleUse
            vehicleClass
            antiLockBrakes
            antiTheft
            airbag
            status
            typeOfTrailerHitch
            grossVehicleWeight
            vehicleTonnage
            numberOfDrivingWheels
            percentageOfRepossessionWork
            loanOrLease
            isDeleted
            numberOfRearAxles
            numberOfPassengerSeats
            vehicleEquippedWheelchair
            stretchLength
            typeOfSUV
            vehicleTankSize
            haulGoodsOnForHireBasis
            transportPassengersRevenue
            perimeterSeatingOrPartyBus
            numberOfStalls
            publicTransportationIndicator
            permanentFoundation
            lengthOfTrailer
            glassVinEtching
            collision
            comprehensive
            cwDeductibles
            inPossessionForMoreThan30Days
            auxiliaryRunning
            vehicleRegistrant
            loanCompany
            loanOtherCompanyName
            loanOtherCompanyMailing
            loanOtherCompanyCity
            loanOtherCompanyZip
            leaseCompany
            leaseOtherCompanyName
            leaseOtherCompanyMailing
            leaseOtherCompanyCity
            leaseOtherCompanyZip
          }
          drivers {
            id
            endorsment {
              id
            }
            firstName
            lastName
            dob
            dlNumber
            dlState
            dlClassType
            dlYearsLicensed
            unitType
            yearsExperience
            dateOfHire
            past3YearsAccidents
            past3YearsAccidentDates
            past3YearsViolations
            past3YearsViolationDates
            past3YearsSuspensions
            eligibility
            address
            city
            state
            zip
            ssn
            past5YearsAccidentsViolations
            majorConvictions
            majorConvictionsDates
            employeeType
            isOwner
            phone
            createdAt
            lastUpdated
            isTest
            email
            mvrStatus
            mvrCheckDate
            isDeleted
            MotorVehicleRecord {
              id
              reportId
              reportUri
              mvrId
              createdAt
              updatedAt
              turnaround_time
              license_number
              license_state
              license_status
              license_type
              license_class
              expiration_date
              issued_date
              first_issued_date
              inferred_issued_date
              restrictions
              custom_rules
              previous_license_number
              previous_license_state
              not_found
              experience_failed
              result
              violations
              accidents
              endorsements
              documentUri
              File {
                id
                name
                description
                type
                url
                zohoId
                createdAt
                lastUpdated
              }
            }
            mvrDocumentLink
            mvrCandidateId
            zohoId
            licenseIssueDate
            cdl
            isSr22
            sr22CaseNumber
            maritalStatus
            status
            hasAnyViolationsInPast5Years
            past3YearMajorViolations
            totalMajorConvictions
            accidentsViolationsDetails
            totalMajorConvictionsDates
          }
        }
        contact {
          id
        }
        otherLeads {
          id
        }
        parentLeadId
        parentLead {
          id
        }
        activePendingList
        annualPayroll
        annualRevenue
        authorityHistoryList
        avgTimeSpent
        ascendInsuredId
        garagingCity
        garagingCounty
        garagingMail
        garagingNumberOfUnits
        garagingState
        garagingStreet
        garagingZipCode
        bipdInsuranceOnFile
        bipdInsuranceRequired
        bondInsuranceOnFile
        bondInsuranceRequired
        brokerAppPending
        brokerAuthStatus
        businessDescription
        businessEntityType
        cargo
        cargoCarried
        cargoCarrier
        cargoInsuranceOnFile
        cargoInsuranceRequired
        cargoLimits
        carrierOperation
        city
        commonAppPending
        commonAuthStatus
        company
        contactTitle
        contractAppPending
        contractAuthStatus
        convertedAccount
        convertedContact
        convertedDeal
        county
        crashedFatal
        crashedInjury
        crashedTotal
        crashedTow
        createdBy
        daysVisited
        dbaName
        designation
        dotNumber
        dotRegistration
        dunsNumber
        email
        emailNotValid
        emailOptOut
        expiringInsuranceCompany
        fax
        firstName
        firstPageVisited
        firstVisit
        fullTimeEmployees
        inspectionsDriver
        inspectionsHazmat
        inspectionsLep
        InspectionsVehicle
        insuranceHistoryListData
        insuranceType
        lastKnownAutoExpirationDate
        lastKnownCargoExpirationDate
        lastKnownWcExpirationDate
        lastName
        layout
        leadCost
        leadOwner
        leadSource
        leadSourceLeadId
        leadStatus
        leadType
        liabilityCarrier
        liabilityLimits
        limitsRequired
        listCampaignType
        mailZipCode
        mailingCity
        mailingCounty
        mailingMail
        mailingNumberOfUnits
        mailingState
        mailingStreet
        marketingAutoScore
        maximumRadiusOfOperations
        mcNumber
        mcs150FormDate
        mobile
        modifiedBy
        mostRecentVisit
        naicCode
        natlAverageDriver
        natlAverageHazmat
        natlAverageLep
        natlAverageVehicle
        noOfEmployees
        numberOfChats
        numberOfDrivers
        numberOfUnits
        operatingStatus
        operationClassification
        outOfServiceDriver
        outOfServiceHazmat
        outOfServiceLep
        outOfServicePercentageDriver
        outOfServicePercentageHazmat
        outOfServicePercentageLep
        outOfServicePercentageVehicle
        outOfServiceVehicle
        ownerYearsOfExperience
        partTimeEmployees
        pendingApplicationList
        phone
        physicalMail
        policyNumber
        referrer
        rejectionInsuranceList
        revocationListData
        sdrLead
        sicCode
        spId
        spTier
        spType
        state
        street
        subContractorCosts
        visitorScore
        website
        yearsInBusiness
        zipCode
        org
        auth {
          id
        }
        createdAt
        updatedAt
      }
      token
    }
  }
`;

export const SEND_COI_EMAIL_MUTATION = gql`
  mutation sendCoiEmails(
    $emails: [String]!
    $body: String!
    $subject: String!
  ) {
    sendCoiEmails(emails: $emails, body: $body, subject: $subject) {
      message
    }
  }
`;

export const COMPANY_INFO_BY_DOT_MUTATION = gql`
  mutation getCompanyInfoByDOT($dot: String!, $org: Organizations) {
    getCompanyInfoByDOT(dot: $dot, org: $org) {
      exists
      safer {
        businessEntityType
        operatingStatus
        dbaName
        phone
        legalName
        mailingAddress
        mailingCity
        mailZipCode
        mailingState
        mailingCounty
        mailingStreet
        garagingCity
        garagingState
        garagingStreet
        garagingZipCode
        garagingCounty
        dotNumber
        mcNumber
        dunsNumber
        mcs150FormDate
        yearsInBusiness
        operationClassification
        carrierOperation
        cargoCarried
        inspectionsDriver
        inspectionsHazmat
        inspectionsLep
        InspectionsVehicle
        crashedFatal
        crashedInjury
        crashedTotal
        crashedTow
      }
      lead {
        id
        zohoId
        account {
          id
        }
        contact {
          id
        }
        otherLeads {
          id
        }
        parentLeadId
        parentLead {
          id
        }
        activePendingList
        annualPayroll
        havePrevAuthority
        annualRevenue
        authorityHistoryList
        avgTimeSpent
        garagingCity
        garagingCounty
        garagingMail
        garagingNumberOfUnits
        garagingState
        garagingStreet
        garagingZipCode
        bipdInsuranceOnFile
        bipdInsuranceRequired
        bondInsuranceOnFile
        bondInsuranceRequired
        brokerAppPending
        brokerAuthStatus
        businessDescription
        businessEntityType
        cargo
        cargoCarried
        cargoCarrier
        cargoInsuranceOnFile
        cargoInsuranceRequired
        cargoLimits
        carrierOperation
        city
        commonAppPending
        commonAuthStatus
        company
        contactTitle
        contractAppPending
        contractAuthStatus
        convertedAccount
        convertedContact
        convertedDeal
        county
        crashedFatal
        crashedInjury
        crashedTotal
        crashedTow
        createdBy
        daysVisited
        dbaName
        designation
        dotNumber
        dotRegistration
        dunsNumber
        email
        emailNotValid
        emailOptOut
        expiringInsuranceCompany
        fax
        firstName
        firstPageVisited
        firstVisit
        fullTimeEmployees
        inspectionsDriver
        inspectionsHazmat
        inspectionsLep
        InspectionsVehicle
        insuranceHistoryListData
        insuranceType
        lastKnownAutoExpirationDate
        lastKnownCargoExpirationDate
        lastKnownWcExpirationDate
        lastName
        layout
        leadCost
        leadOwner
        leadSource
        leadSourceLeadId
        leadStatus
        leadType
        liabilityCarrier
        liabilityLimits
        limitsRequired
        listCampaignType
        mailZipCode
        mailingCity
        mailingCounty
        mailingMail
        mailingNumberOfUnits
        mailingState
        mailingStreet
        marketingAutoScore
        maximumRadiusOfOperations
        mcNumber
        mcs150FormDate
        mobile
        modifiedBy
        mostRecentVisit
        naicCode
        natlAverageDriver
        natlAverageHazmat
        natlAverageLep
        natlAverageVehicle
        noOfEmployees
        numberOfChats
        numberOfDrivers
        numberOfUnits
        operatingStatus
        operationClassification
        outOfServiceDriver
        outOfServiceHazmat
        outOfServiceLep
        outOfServicePercentageDriver
        outOfServicePercentageHazmat
        outOfServicePercentageLep
        outOfServicePercentageVehicle
        outOfServiceVehicle
        ownerYearsOfExperience
        partTimeEmployees
        pendingApplicationList
        phone
        physicalMail
        policyNumber
        referrer
        rejectionInsuranceList
        revocationListData
        sdrLead
        sicCode
        spId
        spTier
        spType
        state
        street
        subContractorCosts
        visitorScore
        website
        yearsInBusiness
        zipCode
        org
        auth {
          id
        }
        createdAt
        updatedAt
      }
      account {
        id
        leadId
        lead {
          id
        }
        contacts {
          id
        }
        dayAMTransitionEdit
        policyChangeData
        recordImage
        accountManager
        accountName
        havePrevAuthority
        notes1
        activePendingList
        accountNumber
        owner
        accountShortName
        accountSite
        accountStage
        accountType
        activityLevel
        alternateContact
        annualPayroll
        annualRevenue
        garagingCity
        garagingCounty
        garagingMail
        garagingNumberOfUnits
        garagingState
        garagingStreet
        garagingZipCode
        businessEntityType
        cargo
        cargoLimits
        checkbox1
        claimsLink
        comm
        comments
        commodity
        eldProvider
        partnerContact
        partnerContactEmail
        opportunityDescription
        partnerContactPhone
        contract
        createdBy
        dbaName
        dealTerms
        description
        dotNumber
        dotNumber1
        ebitda
        eldVendor
        employees
        additionalInsured
        lossPayee
        additionalInsuredSubFields
        uiiaAccount
        injuryProtectionAmount
        breakdownCoverage
        specialVerbiage
        estimateAnnualPartnershipRevenue
        estimatedAnnualPartnershipRevenueToLT
        estimatedNumberOfLeadsToLT
        fax
        garagingCity
        garagingState
        garagingStreet
        garagingZipCode
        industry
        investmentThesis
        layout
        leadCost
        leadSource
        legacyAccount
        liabilityLimits
        limitsRequired
        maStage
        maType
        maxInvestment
        mcNumber
        minInvestment
        modifiedBy
        monetizationDescriptions
        naicCode
        numberOfEmployees
        numberOfUnits
        ownerYearsOfExperience
        ownership
        parentAccount
        partnershipType
        paymentBasedOn
        phone
        platformAccountID
        platformAccountUsers
        prioritization
        rating
        referedBy
        referredBy
        resourceAndInvestmentDescription
        resourceAndInvestmentNeeded
        revenueSize
        saferScore
        seriesPreference
        shippingCity
        shippingCode
        shippingCountry
        shippingState
        shippingStreet
        sicCode
        similarInvestments
        subform1
        tag
        tickerSymbol
        totalActivePremium
        underwritingCompanies
        web
        website
        yearsInBusiness
        city
        commonApplicationPending
        commonAuthorityStatus
        contractApplicationPending
        contractAuthorityStatus
        crashedFatal
        crashedInjury
        crashedTotal
        crashedTow
        dunsNumber
        inspectionsDriver
        inspectionsHazmat
        inspectionsLEP
        inspectionsVehicle
        insuranceHistoryListData
        mailZipCode
        mailingCity
        mailingCounty
        mailingState
        mcs150FormDate
        natlAverageDriver
        natlAverageHazmat
        natlAverageLEP
        natlAverageVehicle
        numberOfDrivers
        operatingStatus
        operationClassification
        outOfServiceDriver
        outOfServiceHazmat
        outOfServiceLEP
        outOfServicePercentageDriverr
        outOfServicePercentageHazmat
        outOfServicePercentageLEP
        outOfServicePercentageVehicle
        outOfServiceVehicle
        pendingApplicationList
        rejectionInsuranceList
        state
        street
        zipCode
        cargoApplicationPending
        cargoAuthorityStatus
        cargoCarried
        cargoInsuranceOnFile
        cargoInsuranceRequired
        carrierOperation
        bipdApplicationPending
        bipdAuthorityStatus
        bipdInsuranceOnFile
        bipdInsuranceRequired
        bondApplicationPending
        bondAuthorityStatus
        bondInsuranceOnFile
        bondInsuranceRequired
        brokerApplicationPending
        brokerAuthorityStatus
        createdAt
        updatedAt
        brokerAssisted
      }
    }
  }
`;

export const COMPANY_NOTES_MUTATION = gql`
  mutation updateCompanyNotes($notes: String!) {
    updateCompanyNotes(notes: $notes) {
      id
      accountName
      garagingState
      mcNumber
      garagingCity
      garagingCounty
      mailingCity
      mailingState
      phone
      yearsInBusiness
      eldProvider
      notes1
    }
  }
`;

export const SEND_USER_SMS_MUTATION = gql`
  mutation sendSms($phone: String!, $body: String!) {
    sendSms(phone: $phone, body: $body) {
      message
    }
  }
`;

export const UPDATE_USER_ACCESS_MUTATION = gql`
  mutation updateUserAccess(
    $userId: ID!
    $role: Role!
    $accessAllowed: Boolean!
  ) {
    updateUserAccess(
      userId: $userId
      role: $role
      accessAllowed: $accessAllowed
    ) {
      message
    }
  }
`;

export const DELETE_VEHICLE_MUTATION = gql`
  mutation DeleteVehicle($vehicleId: ID!) {
    deleteVehicle(vehicleId: $vehicleId) {
      id
      vehicleType
      modelYear
      vehicleMake
      vehicleModel
      limit
      createdAt
      ownership
      bodyStyle
      value
      vehicleClass
      radius
      vehicleUse
      needCoverage
      vehicleZip
      antiTheft
      antiLockBrakes
      airbag
      status
      isDeleted
      vin
      endorsment {
        id
      }
      company {
        id
      }
    }
  }
`;

export const RESTORE_VEHICLE_MUTATION = gql`
  mutation RestoreVehicle($vehicleId: ID!) {
    restoreVehicle(vehicleId: $vehicleId) {
      id
      vehicleType
      modelYear
      vehicleMake
      vehicleModel
      limit
      createdAt
      ownership
      bodyStyle
      value
      vehicleClass
      radius
      vehicleUse
      needCoverage
      vehicleZip
      antiTheft
      antiLockBrakes
      airbag
      status
      isDeleted
      vin
      endorsment {
        id
      }
      company {
        id
      }
    }
  }
`;

export const DELETE_COI_MUTATION = gql`
  mutation deleteCoi($coiId: ID!) {
    deleteCoi(coiId: $coiId) {
      id
    }
  }
`;

export const DELETE_DRIVER_MUTATION = gql`
  mutation DeleteDriver($driverId: ID!) {
    deleteDriver(driverId: $driverId) {
      id
      firstName
      lastName
      dob
      dlNumber
      dlState
      dlClassType
      dlYearsLicensed
      unitType
      yearsExperience
      dateOfHire
      past3YearsAccidents
      past3YearsAccidentDates
      past3YearsViolations
      past3YearsViolationDates
      past3YearsSuspensions
      eligibility
      address
      city
      state
      zip
      ssn
      past5YearsAccidentsViolations
      majorConvictions
      majorConvictionsDates
      employeeType
      isOwner
      phone
      createdAt
      lastUpdated
      isTest
      email
      mvrStatus
      mvrCheckDate
      isDeleted
      mvrDocumentLink
      mvrCandidateId
      zohoId
      licenseIssueDate
      cdl
      isSr22
      sr22CaseNumber
      maritalStatus
      status
      hasAnyViolationsInPast5Years
      past3YearMajorViolations
      totalMajorConvictions
      accidentsViolationsDetails
      totalMajorConvictionsDates
    }
  }
`;

export const DELETE_COMMODITY_MUTATION = gql`
  mutation DeleteCommodity($commodityId: ID!) {
    deleteCommodity(commodityId: $commodityId) {
      id
    }
  }
`;

export const DELETE_QUOTE_MUTATION = gql`
  mutation deleteQuote($quoteId: ID!) {
    deleteQuote(quoteId: $quoteId) {
      id
    }
  }
`;

export const DELETE_PACKAGE_MUTATION = gql`
  mutation deletePackage($packageId: ID!) {
    deletePackage(packageId: $packageId) {
      id
    }
  }
`;

export const MANUAL_CHECKER_REQUEST_MUTATION = gql`
  mutation fetchCheckrMutation($checkrData: CheckrInput) {
    fetchCheckrMutation(checkrData: $checkrData) {
      mvrStatus
      id
    }
  }
`;

export const SUBSCRIPTION_NOTIFICATION = gql`
  subscription MVRCheckrSubscription($companyId: ID!) {
    MVRCheckrSubscription(companyId: $companyId) {
      status
      severity
      lastUpdated
      payload
      createdAt
      type
    }
  }
`;

export const GET_COMPANY_NOTIFICATIONS_MUTATION = gql`
  mutation notificationsList($page: Float!) {
    notificationsList(page: $page) {
      notifications {
        id
        type
        status
        createdAt
        lastUpdated
        payload
        severity
        seenBy
      }
      count
    }
  }
`;

export const GET_USER_UNSEEN_NOTIFICATIONS_MUTATION = gql`
  mutation userUnseenNotificationsList($page: Float!) {
    userUnseenNotificationsList(page: $page) {
      notifications {
        id
        type
        status
        createdAt
        lastUpdated
        payload
        severity
        seenBy
      }
      count
    }
  }
`;

export const UPDATE_USER_BY_EMAIL_MUTATION = gql`
  mutation updateNewUserPhone($email: String!, $phone: String!) {
    updateNewUserPhone(email: $email, phone: $phone) {
      message
    }
  }
`;

export const UPSERT_COI_MUTATION = gql`
  mutation upsertCoi($coiId: ID!, $policies: [ID], $input: CoiInput) {
    upsertCoi(coiId: $coiId, policies: $policies, input: $input) {
      holderName
      holderEmail
      certificateNumber
      expiration_date
    }
  }
`;

export const UPDATE_USER_MUTATION = gql`
  mutation updateUser(
    $id: ID!
    $email: String
    $phone: String
    $role: Role
    $accessAllowed: Boolean
    $name: String
  ) {
    updateUser(
      id: $id
      email: $email
      phone: $phone
      role: $role
      accessAllowed: $accessAllowed
      name: $name
    ) {
      id
      phone
      email
      role
      accessAllowed
      name
    }
  }
`;

export const DISPATCHER_COMPANIES_LIST_MUTATION = gql`
  mutation dispatcherCompanies($page: Int!) {
    dispatcherCompanies(page: $page) {
      count
      dispatcherOfCompanies {
        company {
          id
          dotNumber
          accountName
        }
        accessAllowed
        role
      }
    }
  }
`;

export const LOGIN_DISPATCHER_IN_COMPANY = gql`
  mutation loginDispatcherInCompany($companyId: String!) {
    loginDispatcherInCompany(companyId: $companyId) {
      token
      user {
        id
        name
        phone
        email
        lastLogin
        role
        accessAllowed
        company {
          id
          garagingState
          dot
          name
          mailingAddress
          mailingState
          mailingCity
          mailingZip
          yearsInBusiness
          eldProvider
          notes
        }
      }
    }
  }
`;

export const SEARCH_COMPANIES_FOR_DISPATCHER_MUTATION = gql`
  mutation searchCompanyForDispatcher($text: String!, $org: Organizations) {
    searchCompanyForDispatcher(text: $text, org: $org) {
      id
      accountName
      dotNumber
      contacts {
        id
        firstName
        lastName
        email
      }
      dispatchers {
        accessAllowed
        role
        dispatcher {
          id
          name
          email
        }
      }
    }
  }
`;

export const GET_COMPANY_FOLDERS_LIST_MUTATION = gql`
  mutation getCompanyDocumentsFoldersList {
    getCompanyDocumentsFoldersList
  }
`;

export const GET_COMPANY_FOLDER_FILES_LIST_MUTATION = gql`
  mutation getCompanyDocumentsFolderFilesList($folderName: String!) {
    getCompanyDocumentsFolderFilesList(folderName: $folderName) {
      name
      key
    }
  }
`;

export const CHECK_COMPANY_WITH_DOT_MUTATION = gql`
  mutation checkCompanyWithDOT($dot: String!) {
    checkCompanyWithDOT(dot: $dot)
  }
`;

export const SIGNUP_USER_WITH_COMPANY_MUTATION = gql`
  mutation singUpUserWithCompany(
    $dot: String!
    $email: String!
    $password: String!
    $role: Role!
    $phone: String!
    $accessAllowed: Boolean!
    $name: String!
  ) {
    singUpUserWithCompany(
      dot: $dot
      email: $email
      password: $password
      role: $role
      phone: $phone
      accessAllowed: $accessAllowed
      name: $name
    ) {
      message
    }
  }
`;

export const CREATE_USER_AND_CONNECT_WITH_COMPANY_MUTATION = gql`
  mutation createNewUserAndConnectWithCompany(
    $email: String!
    $role: Role!
    $phone: String!
    $accessAllowed: Boolean
    $firstName: String!
    $lastName: String!
  ) {
    createNewUserAndConnectWithCompany(
      email: $email
      role: $role
      phone: $phone
      accessAllowed: $accessAllowed
      firstName: $firstName
      lastName: $lastName
    ) {
      id
      email
      role
      phone
      accessAllowed
    }
  }
`;

export const UPDATE_USER_ROLE_AND_CONNECT_WITH_COMPANY = gql`
  mutation UserSignUpForExistingCompany($dot: String!) {
    userSignUpForExistingCompany(dot: $dot) {
      message
    }
  }
`;

export const RESTORE_DELETED_DERIVER_MUTATION = gql`
  mutation RestoreDriver($driverId: ID!) {
    restoreDriver(driverId: $driverId) {
      id
      firstName
      lastName
      dob
      dlNumber
      dlState
      dlClassType
      dlYearsLicensed
      unitType
      yearsExperience
      dateOfHire
      past3YearsAccidents
      past3YearsAccidentDates
      past3YearsViolations
      past3YearsViolationDates
      past3YearsSuspensions
      eligibility
      address
      city
      state
      zip
      ssn
      past5YearsAccidentsViolations
      majorConvictions
      majorConvictionsDates
      employeeType
      isOwner
      phone
      createdAt
      lastUpdated
      isTest
      email
      mvrStatus
      mvrCheckDate
      isDeleted
      mvrDocumentLink
      mvrCandidateId
      zohoId
      licenseIssueDate
      cdl
      isSr22
      sr22CaseNumber
      maritalStatus
      status
      hasAnyViolationsInPast5Years
      past3YearMajorViolations
      totalMajorConvictions
      accidentsViolationsDetails
      totalMajorConvictionsDates
    }
  }
`;

export const FORGOT_PASSWORD_EMAIL_VERIFICATION = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email) {
      message
    }
  }
`;

export const FORGOT_LT_PASSWORD_EMAIL_VERIFICATION = gql`
  mutation forgotLtUserPassword($email: String!) {
    forgotLtUserPassword(email: $email) {
      token
      user {
        id
        name
        roles
        email
        phone
      }
    }
  }
`;

export const FORGOT_PASSWORD_RESET = gql`
  mutation resetPassword($resetToken: String!, $password: String!) {
    resetPassword(resetToken: $resetToken, password: $password) {
      token
      auth {
        id
        role
        email
        phone
        accessAllowed
      }
    }
  }
`;

export const FORGOT_LT_PASSWORD_RESET = gql`
  mutation resetLtUserPassword($password: String!) {
    resetLtUserPassword(password: $password) {
      token
      user {
        id
        name
        phone
        email
      }
    }
  }
`;

export const FORGOT_DISPATCHER_PASSWORD_EMAIL_VERIFICATION = gql`
  mutation forgotDispatcherPassword($email: String!) {
    forgotDispatcherPassword(email: $email) {
      message
    }
  }
`;

export const FORGOT_DISPATCHER_PASSWORD_RESET = gql`
  mutation resetDispatcherPassword($resetToken: String!, $password: String!) {
    resetDispatcherPassword(resetToken: $resetToken, password: $password) {
      token
      user {
        id
        name
        email
        phone
      }
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword(
    $email: String!
    $password: String!
    $oldPassword: String!
  ) {
    changePassword(
      email: $email
      password: $password
      oldPassword: $oldPassword
    ) {
      message
    }
  }
`;

export const CHANGE_DISPATCHER_PASSWORD = gql`
  mutation changeDispatcherPassword(
    $email: String!
    $password: String!
    $oldPassword: String!
  ) {
    changeDispatcherPassword(
      email: $email
      password: $password
      oldPassword: $oldPassword
    ) {
      message
    }
  }
`;

export const CHANGE_LT_USER_PASSWORD = gql`
  mutation changeLtUserPassword(
    $email: String!
    $password: String!
    $oldPassword: String!
  ) {
    changeLtUserPassword(
      email: $email
      password: $password
      oldPassword: $oldPassword
    ) {
      message
    }
  }
`;

export const GOOGLE_LOGIN = gql`
  mutation userLoginGoogle($input: AuthInput!) {
    userLoginGoogle(input: $input) {
      token
      auth {
        id
        role
        accessAllowed
        email
        phone
      }
      lead {
        id
        zohoId
        account {
          id
        }
        contact {
          id
        }
        otherLeads {
          id
        }
        parentLeadId
        parentLead {
          id
        }
        activePendingList
        annualPayroll
        annualRevenue
        havePrevAuthority
        authorityHistoryList
        avgTimeSpent
        garagingCity
        garagingCounty
        garagingMail
        garagingNumberOfUnits
        garagingState
        garagingStreet
        garagingZipCode
        bipdInsuranceOnFile
        bipdInsuranceRequired
        bondInsuranceOnFile
        bondInsuranceRequired
        brokerAppPending
        brokerAuthStatus
        businessDescription
        businessEntityType
        cargo
        cargoCarried
        cargoCarrier
        cargoInsuranceOnFile
        cargoInsuranceRequired
        cargoLimits
        carrierOperation
        city
        commonAppPending
        commonAuthStatus
        company
        contactTitle
        contractAppPending
        contractAuthStatus
        convertedAccount
        convertedContact
        convertedDeal
        county
        crashedFatal
        crashedInjury
        crashedTotal
        crashedTow
        createdBy
        daysVisited
        dbaName
        designation
        dotNumber
        dotRegistration
        dunsNumber
        email
        emailNotValid
        emailOptOut
        expiringInsuranceCompany
        fax
        firstName
        firstPageVisited
        firstVisit
        fullTimeEmployees
        inspectionsDriver
        inspectionsHazmat
        inspectionsLep
        InspectionsVehicle
        insuranceHistoryListData
        insuranceType
        lastKnownAutoExpirationDate
        lastKnownCargoExpirationDate
        lastKnownWcExpirationDate
        lastName
        layout
        leadCost
        leadOwner
        leadSource
        leadSourceLeadId
        leadStatus
        leadType
        liabilityCarrier
        liabilityLimits
        limitsRequired
        listCampaignType
        mailZipCode
        mailingCity
        mailingCounty
        mailingMail
        mailingNumberOfUnits
        mailingState
        garagingCity
        garagingState
        garagingStreet
        garagingZipCode
        mailingStreet
        marketingAutoScore
        maximumRadiusOfOperations
        mcNumber
        mcs150FormDate
        mobile
        modifiedBy
        mostRecentVisit
        naicCode
        natlAverageDriver
        natlAverageHazmat
        natlAverageLep
        natlAverageVehicle
        noOfEmployees
        numberOfChats
        numberOfDrivers
        numberOfUnits
        operatingStatus
        operationClassification
        outOfServiceDriver
        outOfServiceHazmat
        outOfServiceLep
        outOfServicePercentageDriver
        outOfServicePercentageHazmat
        outOfServicePercentageLep
        outOfServicePercentageVehicle
        outOfServiceVehicle
        ownerYearsOfExperience
        partTimeEmployees
        pendingApplicationList
        phone
        physicalMail
        policyNumber
        referrer
        rejectionInsuranceList
        revocationListData
        sdrLead
        sicCode
        spId
        spTier
        spType
        state
        street
        subContractorCosts
        visitorScore
        website
        yearsInBusiness
        zipCode
        org
        auth {
          id
        }
        createdAt
        updatedAt
      }
      contact {
        id
        firstName
        lastName
        email
        phone
        mobile
        role
      }
      account {
        id
        accountManager
        leadId
        dayAMTransitionEdit
        policyChangeData
        havePrevAuthority
        recordImage
        accountName
        notes1
        activePendingList
        accountNumber
        owner
        accountShortName
        accountSite
        accountStage
        accountType
        activityLevel
        alternateContact
        annualPayroll
        annualRevenue
        garagingCity
        garagingCounty
        garagingMail
        garagingNumberOfUnits
        garagingState
        garagingStreet
        garagingZipCode
        businessEntityType
        cargo
        cargoLimits
        claimsLink
        comments
        commodity
        createdAt
        additionalInsured
        lossPayee
        eldProvider
        additionalInsuredSubFields
        uiiaAccount
        injuryProtectionAmount
        breakdownCoverage
        specialVerbiage
        brokerAuthorityStatus
        brokerApplicationPending
        bondInsuranceRequired
        bondInsuranceOnFile
        bondAuthorityStatus
        bondApplicationPending
        bipdInsuranceRequired
        bipdInsuranceOnFile
        bipdAuthorityStatus
        bipdApplicationPending
        carrierOperation
        cargoInsuranceRequired
        cargoInsuranceOnFile
        cargoCarried
        cargoAuthorityStatus
        cargoApplicationPending
        zipCode
        street
        state
        rejectionInsuranceList
        pendingApplicationList
        outOfServiceVehicle
        outOfServicePercentageVehicle
        outOfServicePercentageLEP
        outOfServicePercentageHazmat
        outOfServicePercentageDriverr
        outOfServiceLEP
        outOfServiceHazmat
        outOfServiceDriver
        operationClassification
        operatingStatus
        numberOfDrivers
        natlAverageVehicle
        natlAverageLEP
        natlAverageHazmat
        natlAverageDriver
        mcs150FormDate
        mailingState
        mailingCounty
        mailingCity
        mailZipCode
        insuranceHistoryListData
        inspectionsVehicle
        inspectionsLEP
        inspectionsHazmat
        inspectionsDriver
        dunsNumber
        crashedTow
        crashedTotal
        crashedInjury
        crashedFatal
        contractAuthorityStatus
        contractApplicationPending
        commonAuthorityStatus
        commonApplicationPending
        city
        yearsInBusiness
        website
        web
        underwritingCompanies
        totalActivePremium
        tickerSymbol
        tag
        subform1
        similarInvestments
        sicCode
        shippingStreet
        shippingState
        shippingCountry
        shippingCode
        shippingCity
        seriesPreference
        saferScore
        revenueSize
        resourceAndInvestmentNeeded
        resourceAndInvestmentDescription
        referredBy
        referedBy
        rating
        prioritization
        platformAccountUsers
        platformAccountID
        phone
        paymentBasedOn
        partnershipType
        parentAccount
        ownership
        ownerYearsOfExperience
        numberOfUnits
        numberOfEmployees
        naicCode
        monetizationDescriptions
        modifiedBy
        minInvestment
        mcNumber
        partnerContact
        partnerContactEmail
        opportunityDescription
        partnerContactPhone
        contract
        createdBy
        dbaName
        dealTerms
        description
        dotNumber
        dotNumber1
        ebitda
        eldVendor
        employees
        estimateAnnualPartnershipRevenue
        estimatedAnnualPartnershipRevenueToLT
        estimatedNumberOfLeadsToLT
        fax
        garagingCity
        garagingState
        garagingStreet
        garagingZipCode
        industry
        investmentThesis
        layout
        leadCost
        leadSource
        legacyAccount
        liabilityLimits
        limitsRequired
        maType
        maStage
        maxInvestment
      }
    }
  }
`;

export const FLEXPORT_GOOGLE_LOGIN = gql`
  mutation flexportUserLoginGoogle($input: AuthInput!) {
    flexportUserLoginGoogle(input: $input) {
      token
      user {
        id
        name
        phone
        email
        lastLogin
        role
        accessAllowed
        company {
          id
          garagingState
          dot
          name
          mailingAddress
          mailingState
          mailingCity
          mailingZip
          mcNumber
          eldProvider
          notes
        }
      }
    }
  }
`;

export const FACEBOOK_LOGIN = gql`
  mutation userLoginFacebook($input: AuthInput!) {
    userLoginFacebook(input: $input) {
      token
      user {
        id
        name
        phone
        email
        lastLogin
        role
        accessAllowed
        company {
          id
          garagingState
          dot
          name
          mailingAddress
          mailingState
          mailingCity
          mailingZip
          mcNumber
          eldProvider
          notes
        }
      }
    }
  }
`;

export const FLEXPORT_FACEBOOK_LOGIN = gql`
  mutation flexportUserLoginFacebook($input: AuthInput!) {
    flexportUserLoginFacebook(input: $input) {
      token
      user {
        id
        name
        phone
        email
        lastLogin
        role
        accessAllowed
        company {
          id
          garagingState
          dot
          name
          mailingAddress
          mailingState
          mailingCity
          mailingZip
          mcNumber
          eldProvider
          notes
        }
      }
    }
  }
`;

export const UPSERT_PACKAGE = gql`
  mutation upsertPackage($input: PackageInput!, $packageId: ID!) {
    upsertPackage(input: $input, packageId: $packageId) {
      id
      application {
        id
      }
      products {
        id
      }
    }
  }
`;

export const FETCH_COMPLETE_COMPANY_INFO = gql`
  mutation fetchCompanyCompleteInfoForDispatcher($companyId: ID!) {
    fetchCompanyCompleteInfoForDispatcher(companyId: $companyId) {
      id
      accountManager
      leadId
      dayAMTransitionEdit
      recordImage
      policyChangeData
      havePrevAuthority
      accountName
      additionalInsured
      lossPayee
      additionalInsuredSubFields
      uiiaAccount
      injuryProtectionAmount
      breakdownCoverage
      specialVerbiage
      driversSnapShot
      vehiclesSnapShot
      commoditiesSnapShot
      contacts {
        id
        firstName
        lastName
        email
        phone
        mobile
      }
      notes1
      activePendingList
      accountNumber
      owner
      accountShortName
      accountSite
      accountStage
      accountType
      activityLevel
      alternateContact
      annualPayroll
      annualRevenue
      garagingCity
      garagingCounty
      garagingMail
      garagingNumberOfUnits
      garagingState
      garagingStreet
      garagingZipCode
      businessEntityType
      eldProvider
      cargo
      cargoLimits
      claimsLink
      comments
      commodity
      createdAt
      additionalInsured
      lossPayee
      additionalInsuredSubFields
      uiiaAccount
      injuryProtectionAmount
      breakdownCoverage
      specialVerbiage
      brokerAuthorityStatus
      brokerApplicationPending
      bondInsuranceRequired
      bondInsuranceOnFile
      bondAuthorityStatus
      bondApplicationPending
      bipdInsuranceRequired
      bipdInsuranceOnFile
      bipdAuthorityStatus
      bipdApplicationPending
      carrierOperation
      cargoInsuranceRequired
      cargoInsuranceOnFile
      cargoCarried
      cargoAuthorityStatus
      cargoApplicationPending
      zipCode
      street
      state
      rejectionInsuranceList
      pendingApplicationList
      outOfServiceVehicle
      outOfServicePercentageVehicle
      outOfServicePercentageLEP
      outOfServicePercentageHazmat
      outOfServicePercentageDriverr
      outOfServiceLEP
      outOfServiceHazmat
      outOfServiceDriver
      operationClassification
      operatingStatus
      numberOfDrivers
      natlAverageVehicle
      natlAverageLEP
      natlAverageHazmat
      natlAverageDriver
      mcs150FormDate
      mailingState
      mailingCounty
      mailingCity
      mailZipCode
      mailingStreet
      insuranceHistoryListData
      inspectionsVehicle
      inspectionsLEP
      inspectionsHazmat
      inspectionsDriver
      dunsNumber
      crashedTow
      crashedTotal
      crashedInjury
      crashedFatal
      contractAuthorityStatus
      contractApplicationPending
      commonAuthorityStatus
      commonApplicationPending
      city
      yearsInBusiness
      website
      web
      underwritingCompanies
      totalActivePremium
      tickerSymbol
      tag
      subform1
      similarInvestments
      sicCode
      shippingStreet
      shippingState
      shippingCountry
      shippingCode
      shippingCity
      seriesPreference
      saferScore
      revenueSize
      resourceAndInvestmentNeeded
      resourceAndInvestmentDescription
      referredBy
      referedBy
      rating
      prioritization
      platformAccountUsers
      platformAccountID
      phone
      paymentBasedOn
      partnershipType
      parentAccount
      ownership
      ownerYearsOfExperience
      numberOfUnits
      numberOfEmployees
      naicCode
      monetizationDescriptions
      modifiedBy
      minInvestment
      mcNumber
      partnerContact
      partnerContactEmail
      opportunityDescription
      partnerContactPhone
      contract
      createdBy
      dbaName
      dealTerms
      description
      dotNumber
      dotNumber1
      ebitda
      eldVendor
      employees
      estimateAnnualPartnershipRevenue
      estimatedAnnualPartnershipRevenueToLT
      estimatedNumberOfLeadsToLT
      fax
      garagingCity
      garagingState
      garagingStreet
      garagingZipCode
      industry
      investmentThesis
      layout
      leadCost
      leadSource
      legacyAccount
      liabilityLimits
      limitsRequired
      maType
      maStage
      maxInvestment
      commodities {
        id
        type
        subType
        minValue
        maxValue
        percentage
        endorsment {
          id
        }
        status
        isDeleted
      }
      trucks {
        id
        endorsment {
          id
        }
        vehicleType
        modelYear
        vehicleMake
        vehicleModel
        subTruckType
        subTrailerType
        vehicleZip
        farthestDistance
        valuePermEquipment
        businessOrPersonal
        avgTripsPerDay
        value
        ownership
        vin
        gvw
        rearAxles
        garagingCity
        operationRadius
        annualMileage
        safetyFeatures
        limit
        createdAt
        lastUpdated
        isTest
        category
        bodyStyle
        radius
        needCoverage
        vehicleUse
        vehicleClass
        antiLockBrakes
        antiTheft
        airbag
        status
        typeOfTrailerHitch
        grossVehicleWeight
        vehicleTonnage
        numberOfDrivingWheels
        percentageOfRepossessionWork
        loanOrLease
        isDeleted
        numberOfRearAxles
        numberOfPassengerSeats
        vehicleEquippedWheelchair
        stretchLength
        typeOfSUV
        vehicleTankSize
        haulGoodsOnForHireBasis
        transportPassengersRevenue
        perimeterSeatingOrPartyBus
        numberOfStalls
        publicTransportationIndicator
        permanentFoundation
        lengthOfTrailer
        glassVinEtching
        collision
        comprehensive
        cwDeductibles
        inPossessionForMoreThan30Days
        auxiliaryRunning
        vehicleRegistrant
        loanCompany
        loanOtherCompanyName
        loanOtherCompanyMailing
        loanOtherCompanyCity
        loanOtherCompanyZip
        leaseCompany
        leaseOtherCompanyName
        leaseOtherCompanyMailing
        leaseOtherCompanyCity
        leaseOtherCompanyZip
      }
      drivers {
        id
        endorsment {
          id
        }
        firstName
        lastName
        dob
        dlNumber
        dlState
        dlClassType
        dlYearsLicensed
        unitType
        yearsExperience
        dateOfHire
        past3YearsAccidents
        past3YearsAccidentDates
        past3YearsViolations
        past3YearsViolationDates
        past3YearsSuspensions
        eligibility
        address
        city
        state
        zip
        ssn
        past5YearsAccidentsViolations
        majorConvictions
        majorConvictionsDates
        employeeType
        isOwner
        phone
        createdAt
        lastUpdated
        isTest
        email
        mvrStatus
        mvrCheckDate
        isDeleted
        MotorVehicleRecord {
          id
          reportId
          reportUri
          mvrId
          createdAt
          updatedAt
          turnaround_time
          license_number
          license_state
          license_status
          license_type
          license_class
          expiration_date
          issued_date
          first_issued_date
          inferred_issued_date
          restrictions
          custom_rules
          previous_license_number
          previous_license_state
          not_found
          experience_failed
          result
          violations
          accidents
          endorsements
          documentUri
          File {
            id
            name
            description
            type
            url
            zohoId
            createdAt
            lastUpdated
          }
        }
        mvrDocumentLink
        mvrCandidateId
        zohoId
        licenseIssueDate
        cdl
        isSr22
        sr22CaseNumber
        maritalStatus
        status
        hasAnyViolationsInPast5Years
        past3YearMajorViolations
        totalMajorConvictions
        accidentsViolationsDetails
        totalMajorConvictionsDates
      }
      policies {
        id
        status
        policyNumber
        totalPremium
        endorsmentsPremium
        File {
          id
          name
          type
          url
        }
        issuingCarrier
        billingCarrier
        effectiveDate
        expirationDate
        accountManager
        lob
        glEachOccurrence
        alCombinedSingleLimit
        alBodilyInjuryPerPerson
        alBodilyInjuryPerAccident
        alPropertyDamagePerAccident
        onhookLimits
        onhookDeductibles
        garagekeepersCollisionLimit
        garagekeepersCollisionOTCLimit
        garagekeepersCollisionDeductible
        garagekeepersCollisionOTCDeductible
        taxes
        platformFee
        PlatformFeeType
        #EXTRA KEYS ADDED FOR COI Aug,19,2022
        physicalDamageLimit
        trailerInterchangeLimit
        motorTruckCargoLimit
        ntlLimit
        deductibleAl
        deductiblePd
        deductibleMtc
        deductibleGl
        deductibleNtl
        trailerInterchangeDeductible
        physicalDamageCollisionDeductible
        physicalDamageComprehensiveDeductible

        limitsDeductiblesChange

        paymentSource
        paymentType
        downPaymentAmount
        downPaymentPercentage
        endorsments {
          id
          name
          status
          Operations {
            id
            action
            subject
            subjectKey
          }
        }
        quote {
          id
          totalLimit
          totalDeductible
          totalPremium
          totalAmountPaid
          pfa
          File {
            id
            name
            type
            url
          }
          ascendProgramId
          ascendProgramUrl
          ascendInvoiceId
          ascendInvoiceUrl
          ascendInvoiceNumber
          ascendInvoiceDueDate
        }
        product {
          id
          status
          lob
          bindingPremium
          limit
          ascendQuoteId
          deductible
          quoteNumber
          invoicedBy
          admitted
          issuingCarrier
          billingCarrier
          effectiveDate
          expirationDate
          application {
            id
            stage
            activeAuth
            averageViolations
            doTheseApply
            doTheseApplyCw
            preferedCarrier
            priorityStatus
            operationsDescription
            driversAbove23
            cdlExperience
            anySeriousViolations
            currentProgCust
            businessStructure
            driverSummary
            totalValueTrucks
            totalValueTrailers
            grossAnnualTruckRev
            annualTotalMileage
            haulsUnder50Miles
            haulsUnder200Miles
            haulsUnder500Miles
            haulsAbove500Miles
            currentlyInsured
            otherCoverages
            totalAdditionalInsureds
            totalNamedInsureds
            blanketAdditionalNeeded
            blanketWaiverSubrogations
            stateOrFederalFilingsReq
            snapshotPreview
            effectiveDate
            towingLaborStorageLimit
            trailerInterchangeLimit
            autoLiabilityLossCount
            autoLiabilityLossPaid
            physicalDamageLossCount
            physicalDamageLossPaid
            cargoLossCount
            cargoLossPaid
            truckerGenLiabLossCount
            truckerGenLiabLossPaid
            lossesConfirmedVsPending
            createdAt
            updatedAt
            isTest
            isThereRigging
            numberLosses3Years
            currentPage
            numberDrivers
            numberPowerUnits
            lobs
            avgRadOps
            domiciledWithin50Miles
            limitInsPerPowerUnit
            limitInsPerPowerUnitNonOwned
            perOccurenceLimit
            deductible
            spoilageFreezingCoverage
            mtcAdditionalCoverage
            pollutionCleanUp
            ownersGoodExtension
            nonOwnedTrailer
            expirationDate
            disclaimer
            status
            typeOfOps
            typeOfOpsDescriptionOther
            glOrBop
            yearCurrentBusiness
            limitAl
            limitPd
            limitMtc
            limitGl
            limitNtl
            trailerInterchangeDeductible
            deductibleAl
            deductiblePd
            deductibleMtc
            deductibleGl
            deductibleNtl
            numberLossesPast3YearsGA
          }
        }
      }
      applications {
        id
        stage
        activeAuth
        averageViolations
        doTheseApply
        doTheseApplyCw
        preferedCarrier
        priorityStatus
        operationsDescription
        driversAbove23
        cdlExperience
        anySeriousViolations
        currentProgCust
        businessStructure
        driverSummary
        totalValueTrucks
        totalValueTrailers
        grossAnnualTruckRev
        annualTotalMileage
        haulsUnder50Miles
        haulsUnder200Miles
        haulsUnder500Miles
        haulsAbove500Miles
        currentlyInsured
        otherCoverages
        totalAdditionalInsureds
        totalNamedInsureds
        blanketAdditionalNeeded
        blanketWaiverSubrogations
        stateOrFederalFilingsReq
        snapshotPreview
        effectiveDate
        towingLaborStorageLimit
        trailerInterchangeLimit
        autoLiabilityLossCount
        autoLiabilityLossPaid
        physicalDamageLossCount
        physicalDamageLossPaid
        cargoLossCount
        cargoLossPaid
        truckerGenLiabLossCount
        truckerGenLiabLossPaid
        lossesConfirmedVsPending
        createdAt
        updatedAt
        isTest
        isThereRigging
        numberLosses3Years
        currentPage
        numberDrivers
        numberPowerUnits
        lobs
        avgRadOps
        domiciledWithin50Miles
        limitInsPerPowerUnit
        limitInsPerPowerUnitNonOwned
        perOccurenceLimit
        deductible
        spoilageFreezingCoverage
        mtcAdditionalCoverage
        pollutionCleanUp
        ownersGoodExtension
        nonOwnedTrailer
        expirationDate
        disclaimer
        status
        typeOfOps
        typeOfOpsDescriptionOther
        typeOfOpsFao
        glOrBop
        yearCurrentBusiness
        limitAl
        limitPd
        limitMtc
        limitGl
        limitNtl
        trailerInterchangeDeductible
        deductibleAl
        deductiblePd
        deductibleMtc
        deductibleGl
        deductibleNtl
        numberLossesPast3YearsGA
        continuousCoverage
        DOTObtain60Days
        typeOfTrucker
        ineligibleOperations
        vehicleHaulOilAndGas
        hazardousMaterialPlacard
        isCEODriver
        isCEOInvolvedInOperations
        bodilyInjuryLiabilityLimit
        otherGlOrBop
        priorInsuranceCancelledOrRenewed
        seriousViolationsWithinPast5Years
        ELDVendorName
        LossControlTechnology
        ELDRequiredToRecord
        commercialVehiclesInsuredOwns
        privatePassengerAutosInsured
        insuredVehiclesUsesInBusiness
        federal
        federalCargo
        progressiveState
        progressiveStateCargo
        progressiveOther
        progressiveEligibilityCriteria
        progressivePolicyState
        progressiveELDVendor
        ELDVendorAccess
        customerSpendLastYear
        nonOwnedVehiclesUsedInBusiness
        cargoCoverageForMobileHomes
        progressiveTrailerInterchangeCoverage
        trailerInterchangeFurnishAgreement
        nonOwnedTrailerPhysicalDamage
        MCS90
        isGAKickOut
        isCWKickOut
        cwLosses
        motorTruckCargoLimit
        towingLaborStorageLimitstr
        trailerInterchangeLimitstr
        trailerInterchangeLimitProgressive
        isProgKickOut
        vehiclesTowingOrHauling
        vehiclesHualSteel
        previousClaimCw
        totalAccidents
        totalNumberViolations
        totalSuspensions
        primaryAndNoncontributoryForTGL
        primaryAndNoncontributoryForAL
        requireFederalFillingForAL
        requireStateFillingForAL
        hauledRefrigeratedGoods
        ownerFname
        ownerLname
        ownerEmail
        ownerDob
        appStack
        appFlow
        insuredWithNICO
        policyNumbers
        effectiveDates
        newVenture
        towTruckRepossessions
        primaryBusiness
        ifNotPrimaryExplain
        Seasonal
        filedForBankruptcy
        whenFilledForBankruptcy
        explainForBankruptcy
        GrossReceiptsLastYear
        EstimateForComingYear
        BusinessForSale
        operateMoreThanOneState
        listStates
        largestCitiesEntered
        haulHazardousMaterialByEPA
        listingForChemicalMaterials
        hireVehicles
        driversCoveredCompensation
        ifYesNameOfCarrier
        minYearsDrivingExpRequired
        vehiclesOwnerDriven
        driversAllowedToTakeVehiclesHome
        maxDrivingHoursDaily
        maxDrivingHoursWeekly
        driverPayBasis
        lessorBeAdded
        nameAddressOfLessorForEachVehicle
        anyLossPayees
        nameAddressOfMortgageeForEachVehicle
        applicantAwareOfClaim
        provideCompleteDetails
        everBeenDeclined
        ifYesDateAndWhy
        additionalCoverageOptions
        intrastateFilingNeeded
        stateAndPermitNumber
        stateListForCargoFilings
        nameAndAddressPermitIssued
        MCS90EndorsementNeeded
        overWeightCommoditiesHauled
        ifFillingRequiredShowStates
        escortVehiclesTowed
        allowOthersToHaulUnderYourAuthority
        allowOthersToHaulHazardousCommodities
        everChangedYourOperatingName
        operateAnyOtherName
        leaseYourAuthority
        appointAgentsOnYourBehalf
        appliedForAuthorityPast3Years
        authorityWithdrawn
        evidenceRequired
        explainForAboveMultipleBooleanYes
        agreementsWithOtherCarriers
        withWhomAgreementHasBeenMade
        partiesNamedAutoMobilesLiability
        nameOfInsuranceCompanyAndLimitOfLiability
        whosePermitOtherPartiesOperate
        holdHarmlessInAgreement
        barterOrHireVehicles
        premiumBeFinanced
        cargoReqForMobileHomes
        cargoReqForBizDocsOrSecs
        fridgeBreakdownCoverage
        progMTCLimit
        compDeductible
        collDeductible
        forHireTruckingOps
        operateAWarehouse
        setUpOrInstallation
        frackingGasProd
        bizOutsideOfForHireOps
        progGLLimit
        vehicleLeaseProvidePrimaryLiability
        progNTLLimit
        progNonOwnedTrailerPDLimit
        progressive2ndPageKickOut
        filingState
        stateFilingsType
        proViewPolicyState
        snapShotProView
        proViewVehicleAnnualMileage
        proViewTermsAndConditions
        monthInBusiness
        typeOfOpsCW
        edited
        progCurrentPolicyexpirationDate
        coverWhaleOptAlUm
        coverWhaleOptAlPip
        authorityNumber
        indications {
          id
          carrier
          status
          estimatedPremium
        }
        File {
          url
          name
        }

        quotes {
          effectiveDate
          expirationDate
          totalLimit
          totalPremium
          totalDeductible
          monthlyPayment
          downPaymentPercentage
          lob
          pfa
          id
          carrier
          ascendProgramId
          ascendProgramUrl
          ascendInvoiceId
          ascendInvoiceUrl
          ascendInvoiceNumber
          ascendInvoiceDueDate
          File {
            id
            name
            type
            url
          }
          products {
            id
            status
            lob
            bindingPremium
            limit
            deductible
            quoteNumber
            invoicedBy
            admitted
            ascendQuoteId
            issuingCarrier
            billingCarrier
            quoteFileLocation
            quoteSigned
            quoteSignedFileLocation
            purchasedFlag
            oppStage
            downPaymentPercentage
            downPaymentAmount
            paymentType
            paymentSource
            applicationError
            declinedReason
            taxes
            platformFee
            PlatformFeeType
            File {
              id
              name
              type
              url
            }
            policy {
              id
              policyNumber
            }
          }
        }
        company {
          accountOwner
        }
      }
      EldProvider {
        id
        providerName
        isConnected
      }
    }
  }
`;

export const FETCH_SINGLE_POLICY_MUTATION = gql`
  mutation fetchSinglePolicy($policyId: ID) {
    fetchSinglePolicy(policyId: $policyId) {
      id
      status
      policyNumber
      totalPremium
      endorsmentsPremium
      issuingCarrier
      billingCarrier
      effectiveDate
      expirationDate
      accountManager
      lob
      endorsments {
        id
        name
        status
        Operations {
          id
          action
          subject
          subjectKey
        }
      }
      quote {
        id
        totalLimit
        totalDeductible
        totalPremium
        totalAmountPaid
        pfa
      }
      product {
        id
        status
        lob
        bindingPremium
        limit
        ascendQuoteId
        deductible
        quoteNumber
        invoicedBy
        admitted
        issuingCarrier
        billingCarrier
        effectiveDate
        expirationDate
        application {
          id
          stage
          activeAuth
          averageViolations
          doTheseApply
          doTheseApplyCw
          preferedCarrier
          priorityStatus
          operationsDescription
          driversAbove23
          cdlExperience
          anySeriousViolations
          currentProgCust
          businessStructure
          driverSummary
          totalValueTrucks
          totalValueTrailers
          grossAnnualTruckRev
          annualTotalMileage
          haulsUnder50Miles
          haulsUnder200Miles
          haulsUnder500Miles
          haulsAbove500Miles
          currentlyInsured
          otherCoverages
          totalAdditionalInsureds
          totalNamedInsureds
          blanketAdditionalNeeded
          blanketWaiverSubrogations
          stateOrFederalFilingsReq
          snapshotPreview
          effectiveDate
          towingLaborStorageLimit
          trailerInterchangeLimit
          autoLiabilityLossCount
          autoLiabilityLossPaid
          physicalDamageLossCount
          physicalDamageLossPaid
          cargoLossCount
          cargoLossPaid
          truckerGenLiabLossCount
          truckerGenLiabLossPaid
          lossesConfirmedVsPending
          createdAt
          updatedAt
          isTest
          isThereRigging
          numberLosses3Years
          currentPage
          numberDrivers
          numberPowerUnits
          lobs
          avgRadOps
          domiciledWithin50Miles
          limitInsPerPowerUnit
          limitInsPerPowerUnitNonOwned
          perOccurenceLimit
          deductible
          spoilageFreezingCoverage
          mtcAdditionalCoverage
          pollutionCleanUp
          ownersGoodExtension
          nonOwnedTrailer
          expirationDate
          disclaimer
          status
          typeOfOps
          typeOfOpsDescriptionOther
          typeOfOpsFao
          glOrBop
          yearCurrentBusiness
          limitAl
          limitPd
          limitMtc
          limitGl
          limitNtl
          trailerInterchangeDeductible
          deductibleAl
          deductiblePd
          deductibleMtc
          deductibleGl
          deductibleNtl
          numberLossesPast3YearsGA
          # tarpsAndChains
          # reusablePackingContainers
          # subHaulerOwnerOrOperator
          # numberOfNonOwnedTrailers
          # debrisRemovalLimitGA
          # reloadingLimitGA
          # pollutionCleanUpLimitGA
          # earnedFreightLimitGA
          # fireDepartmentServiceChargesLimitGA
          # lossDataPreparationLimitGA
          # rewardCoverageLimitGA
          # reusablePackingContainersLimitGA
          # tarpsAndChainsLimitGA
          # annualValueShippedGA
          # totalCargoLossesInLast36MonthsGA
        }
      }
    }
  }
`;

export const FETCH_COMPANY_POLICIES = gql`
  mutation fetchCompanyPolicies {
    fetchCompanyPolicies {
      id
      status
      policyNumber
      totalPremium
      endorsmentsPremium
      issuingCarrier
      billingCarrier
      effectiveDate
      expirationDate
      accountManager
      lob
      endorsments {
        id
        name
        status
        Operations {
          id
          action
          subject
          subjectKey
        }
      }
      quote {
        id
        totalLimit
        totalDeductible
        totalPremium
        totalAmountPaid
        pfa
      }
      product {
        id
        status
        lob
        bindingPremium
        limit
        ascendQuoteId
        deductible
        quoteNumber
        invoicedBy
        admitted
        issuingCarrier
        billingCarrier
        effectiveDate
        expirationDate
        application {
          id
          stage
          activeAuth
          averageViolations
          doTheseApply
          doTheseApplyCw
          preferedCarrier
          priorityStatus
          operationsDescription
          driversAbove23
          cdlExperience
          anySeriousViolations
          currentProgCust
          businessStructure
          driverSummary
          totalValueTrucks
          totalValueTrailers
          grossAnnualTruckRev
          annualTotalMileage
          haulsUnder50Miles
          haulsUnder200Miles
          haulsUnder500Miles
          haulsAbove500Miles
          currentlyInsured
          otherCoverages
          totalAdditionalInsureds
          totalNamedInsureds
          blanketAdditionalNeeded
          blanketWaiverSubrogations
          stateOrFederalFilingsReq
          snapshotPreview
          effectiveDate
          towingLaborStorageLimit
          trailerInterchangeLimit
          autoLiabilityLossCount
          autoLiabilityLossPaid
          physicalDamageLossCount
          physicalDamageLossPaid
          cargoLossCount
          cargoLossPaid
          truckerGenLiabLossCount
          truckerGenLiabLossPaid
          lossesConfirmedVsPending
          createdAt
          updatedAt
          isTest
          isThereRigging
          numberLosses3Years
          currentPage
          numberDrivers
          numberPowerUnits
          lobs
          avgRadOps
          domiciledWithin50Miles
          limitInsPerPowerUnit
          limitInsPerPowerUnitNonOwned
          perOccurenceLimit
          deductible
          spoilageFreezingCoverage
          mtcAdditionalCoverage
          pollutionCleanUp
          ownersGoodExtension
          nonOwnedTrailer
          expirationDate
          disclaimer
          status
          typeOfOps
          typeOfOpsDescriptionOther
          typeOfOpsFao
          glOrBop
          yearCurrentBusiness
          limitAl
          limitPd
          limitMtc
          limitGl
          limitNtl
          trailerInterchangeDeductible
          deductibleAl
          deductiblePd
          deductibleMtc
          deductibleGl
          deductibleNtl
          numberLossesPast3YearsGA
          # tarpsAndChains
          # reusablePackingContainers
          # subHaulerOwnerOrOperator
          # numberOfNonOwnedTrailers
          # debrisRemovalLimitGA
          # reloadingLimitGA
          # pollutionCleanUpLimitGA
          # earnedFreightLimitGA
          # fireDepartmentServiceChargesLimitGA
          # lossDataPreparationLimitGA
          # rewardCoverageLimitGA
          # reusablePackingContainersLimitGA
          # tarpsAndChainsLimitGA
          # annualValueShippedGA
          # totalCargoLossesInLast36MonthsGA
        }
      }
    }
  }
`;

export const GET_COMPANY_REFFERAL_STRING = gql`
  mutation getCompanyRefferalLink {
    getCompanyRefferalLink
  }
`;

export const GET_ACCOUNT_INFO = gql`
  mutation GetAccountInfo {
    getAccountInfo {
      token
      lead {
        id
        isConverted
        zohoId
        account {
          id
          accountManager
          leadId
          dayAMTransitionEdit
          recordImage
          policyChangeData
          havePrevAuthority
          accountName
          additionalInsured
          lossPayee
          additionalInsuredSubFields
          uiiaAccount
          injuryProtectionAmount
          breakdownCoverage
          specialVerbiage
          driversSnapShot
          vehiclesSnapShot
          commoditiesSnapShot
          contacts {
            id
            firstName
            lastName
            email
            phone
            mobile
          }
          notes1
          activePendingList
          accountNumber
          owner
          accountShortName
          accountSite
          accountStage
          accountType
          activityLevel
          alternateContact
          annualPayroll
          annualRevenue
          garagingCity
          garagingCounty
          garagingMail
          garagingNumberOfUnits
          garagingState
          garagingStreet
          garagingZipCode
          businessEntityType
          eldProvider
          cargo
          cargoLimits
          claimsLink
          comments
          commodity
          createdAt
          additionalInsured
          lossPayee
          additionalInsuredSubFields
          uiiaAccount
          injuryProtectionAmount
          breakdownCoverage
          specialVerbiage
          brokerAuthorityStatus
          brokerApplicationPending
          bondInsuranceRequired
          bondInsuranceOnFile
          bondAuthorityStatus
          bondApplicationPending
          bipdInsuranceRequired
          bipdInsuranceOnFile
          bipdAuthorityStatus
          bipdApplicationPending
          carrierOperation
          cargoInsuranceRequired
          cargoInsuranceOnFile
          cargoCarried
          cargoAuthorityStatus
          cargoApplicationPending
          zipCode
          street
          state
          rejectionInsuranceList
          pendingApplicationList
          outOfServiceVehicle
          outOfServicePercentageVehicle
          outOfServicePercentageLEP
          outOfServicePercentageHazmat
          outOfServicePercentageDriverr
          outOfServiceLEP
          outOfServiceHazmat
          outOfServiceDriver
          operationClassification
          operatingStatus
          numberOfDrivers
          natlAverageVehicle
          natlAverageLEP
          natlAverageHazmat
          natlAverageDriver
          mcs150FormDate
          mailingState
          mailingCounty
          mailingCity
          mailZipCode
          mailingStreet
          insuranceHistoryListData
          inspectionsVehicle
          inspectionsLEP
          inspectionsHazmat
          inspectionsDriver
          dunsNumber
          crashedTow
          crashedTotal
          crashedInjury
          crashedFatal
          contractAuthorityStatus
          contractApplicationPending
          commonAuthorityStatus
          commonApplicationPending
          city
          yearsInBusiness
          website
          web
          underwritingCompanies
          totalActivePremium
          tickerSymbol
          tag
          subform1
          similarInvestments
          sicCode
          shippingStreet
          shippingState
          shippingCountry
          shippingCode
          shippingCity
          seriesPreference
          saferScore
          revenueSize
          resourceAndInvestmentNeeded
          resourceAndInvestmentDescription
          referredBy
          referedBy
          rating
          prioritization
          platformAccountUsers
          platformAccountID
          phone
          paymentBasedOn
          partnershipType
          parentAccount
          ownership
          ownerYearsOfExperience
          numberOfUnits
          numberOfEmployees
          naicCode
          monetizationDescriptions
          modifiedBy
          minInvestment
          mcNumber
          partnerContact
          partnerContactEmail
          opportunityDescription
          partnerContactPhone
          contract
          createdBy
          dbaName
          dealTerms
          description
          dotNumber
          dotNumber1
          ebitda
          eldVendor
          employees
          estimateAnnualPartnershipRevenue
          estimatedAnnualPartnershipRevenueToLT
          estimatedNumberOfLeadsToLT
          fax
          garagingCity
          garagingState
          garagingStreet
          garagingZipCode
          industry
          investmentThesis
          layout
          leadCost
          leadSource
          legacyAccount
          liabilityLimits
          limitsRequired
          maType
          maStage
          maxInvestment
          commodities {
            id
            type
            subType
            minValue
            maxValue
            percentage
            endorsment {
              id
            }
            status
            isDeleted
          }
          trucks {
            id
            endorsment {
              id
            }
            vehicleType
            modelYear
            vehicleMake
            vehicleModel
            subTruckType
            subTrailerType
            vehicleZip
            farthestDistance
            valuePermEquipment
            businessOrPersonal
            avgTripsPerDay
            value
            ownership
            vin
            gvw
            rearAxles
            garagingCity
            operationRadius
            annualMileage
            safetyFeatures
            limit
            createdAt
            lastUpdated
            isTest
            category
            bodyStyle
            radius
            needCoverage
            vehicleUse
            vehicleClass
            antiLockBrakes
            antiTheft
            airbag
            status
            typeOfTrailerHitch
            grossVehicleWeight
            vehicleTonnage
            numberOfDrivingWheels
            percentageOfRepossessionWork
            loanOrLease
            isDeleted
            numberOfRearAxles
            numberOfPassengerSeats
            vehicleEquippedWheelchair
            stretchLength
            typeOfSUV
            vehicleTankSize
            haulGoodsOnForHireBasis
            transportPassengersRevenue
            perimeterSeatingOrPartyBus
            numberOfStalls
            publicTransportationIndicator
            permanentFoundation
            lengthOfTrailer
            glassVinEtching
            collision
            comprehensive
            cwDeductibles
            inPossessionForMoreThan30Days
            auxiliaryRunning
            vehicleRegistrant
            loanCompany
            loanOtherCompanyName
            loanOtherCompanyMailing
            loanOtherCompanyCity
            loanOtherCompanyZip
            leaseCompany
            leaseOtherCompanyName
            leaseOtherCompanyMailing
            leaseOtherCompanyCity
            leaseOtherCompanyZip
          }
          drivers {
            id
            endorsment {
              id
            }
            firstName
            lastName
            dob
            dlNumber
            dlState
            dlClassType
            dlYearsLicensed
            unitType
            yearsExperience
            dateOfHire
            past3YearsAccidents
            past3YearsAccidentDates
            past3YearsViolations
            past3YearsViolationDates
            past3YearsSuspensions
            eligibility
            address
            city
            state
            zip
            ssn
            past5YearsAccidentsViolations
            majorConvictions
            majorConvictionsDates
            employeeType
            isOwner
            phone
            createdAt
            lastUpdated
            isTest
            email
            mvrStatus
            mvrCheckDate
            isDeleted
            MotorVehicleRecord {
              id
              reportId
              reportUri
              mvrId
              createdAt
              updatedAt
              turnaround_time
              license_number
              license_state
              license_status
              license_type
              license_class
              expiration_date
              issued_date
              first_issued_date
              inferred_issued_date
              restrictions
              custom_rules
              previous_license_number
              previous_license_state
              not_found
              experience_failed
              result
              violations
              accidents
              endorsements
              documentUri
              File {
                id
                name
                description
                type
                url
                zohoId
                createdAt
                lastUpdated
              }
            }
            mvrDocumentLink
            mvrCandidateId
            zohoId
            licenseIssueDate
            cdl
            isSr22
            sr22CaseNumber
            maritalStatus
            status
            hasAnyViolationsInPast5Years
            past3YearMajorViolations
            totalMajorConvictions
            accidentsViolationsDetails
            totalMajorConvictionsDates
          }
          policies {
            id
            status
            policyNumber
            totalPremium
            endorsmentsPremium
            refeerBreakdownCoverage
            File {
              id
              name
              type
              url
            }
            issuingCarrier
            billingCarrier
            effectiveDate
            expirationDate
            accountManager
            lob
            glEachOccurrence
            alCombinedSingleLimit
            alBodilyInjuryPerPerson
            alBodilyInjuryPerAccident
            alPropertyDamagePerAccident
            onhookLimits
            onhookDeductibles
            garagekeepersCollisionLimit
            garagekeepersCollisionOTCLimit
            garagekeepersCollisionDeductible
            garagekeepersCollisionOTCDeductible
            taxes
            platformFee
            PlatformFeeType
            #EXTRA KEYS ADDED FOR COI Aug,19,2022
            physicalDamageLimit
            trailerInterchangeLimit
            motorTruckCargoLimit
            ntlLimit
            deductibleAl
            deductiblePd
            deductibleMtc
            deductibleGl
            deductibleNtl
            trailerInterchangeDeductible
            physicalDamageCollisionDeductible
            physicalDamageComprehensiveDeductible

            limitsDeductiblesChange
            additionalInsured

            paymentSource
            paymentType
            downPaymentAmount
            downPaymentPercentage
            endorsments {
              id
              name
              status
              Operations {
                id
                action
                subject
                subjectKey
              }
            }
            quote {
              id
              totalLimit
              totalDeductible
              totalPremium
              totalAmountPaid
              pfa
              File {
                id
                name
                type
                url
              }
              ascendProgramId
              ascendProgramUrl
              ascendInvoiceId
              ascendInvoiceUrl
              ascendInvoiceNumber
              ascendInvoiceDueDate
            }
            product {
              id
              status
              lob
              bindingPremium
              limit
              ascendQuoteId
              deductible
              quoteNumber
              invoicedBy
              admitted
              issuingCarrier
              billingCarrier
              effectiveDate
              expirationDate
              application {
                id
                stage
                activeAuth
                averageViolations
                doTheseApply
                doTheseApplyCw
                preferedCarrier
                priorityStatus
                operationsDescription
                driversAbove23
                cdlExperience
                anySeriousViolations
                currentProgCust
                businessStructure
                driverSummary
                totalValueTrucks
                totalValueTrailers
                grossAnnualTruckRev
                annualTotalMileage
                haulsUnder50Miles
                haulsUnder200Miles
                haulsUnder500Miles
                haulsAbove500Miles
                currentlyInsured
                otherCoverages
                totalAdditionalInsureds
                totalNamedInsureds
                blanketAdditionalNeeded
                blanketWaiverSubrogations
                stateOrFederalFilingsReq
                snapshotPreview
                effectiveDate
                towingLaborStorageLimit
                trailerInterchangeLimit
                autoLiabilityLossCount
                autoLiabilityLossPaid
                physicalDamageLossCount
                physicalDamageLossPaid
                cargoLossCount
                cargoLossPaid
                truckerGenLiabLossCount
                truckerGenLiabLossPaid
                lossesConfirmedVsPending
                createdAt
                updatedAt
                isTest
                isThereRigging
                numberLosses3Years
                currentPage
                numberDrivers
                numberPowerUnits
                lobs
                avgRadOps
                domiciledWithin50Miles
                limitInsPerPowerUnit
                limitInsPerPowerUnitNonOwned
                perOccurenceLimit
                deductible
                spoilageFreezingCoverage
                mtcAdditionalCoverage
                pollutionCleanUp
                ownersGoodExtension
                nonOwnedTrailer
                expirationDate
                disclaimer
                status
                typeOfOps
                typeOfOpsDescriptionOther
                glOrBop
                yearCurrentBusiness
                limitAl
                limitPd
                limitMtc
                limitGl
                limitNtl
                trailerInterchangeDeductible
                deductibleAl
                deductiblePd
                deductibleMtc
                deductibleGl
                deductibleNtl
                numberLossesPast3YearsGA
                # tarpsAndChains
                # reusablePackingContainers
                # subHaulerOwnerOrOperator
                # numberOfNonOwnedTrailers
                # debrisRemovalLimitGA
                # reloadingLimitGA
                # pollutionCleanUpLimitGA
                # earnedFreightLimitGA
                # fireDepartmentServiceChargesLimitGA
                # lossDataPreparationLimitGA
                # rewardCoverageLimitGA
                # reusablePackingContainersLimitGA
                # tarpsAndChainsLimitGA
                # annualValueShippedGA
                # totalCargoLossesInLast36MonthsGA
              }
            }
          }
          applications {
            id
            stage
            activeAuth
            averageViolations
            doTheseApply
            doTheseApplyCw
            preferedCarrier
            priorityStatus
            operationsDescription
            driversAbove23
            cdlExperience
            anySeriousViolations
            currentProgCust
            businessStructure
            driverSummary
            totalValueTrucks
            totalValueTrailers
            grossAnnualTruckRev
            annualTotalMileage
            haulsUnder50Miles
            haulsUnder200Miles
            haulsUnder500Miles
            haulsAbove500Miles
            currentlyInsured
            otherCoverages
            totalAdditionalInsureds
            totalNamedInsureds
            blanketAdditionalNeeded
            blanketWaiverSubrogations
            stateOrFederalFilingsReq
            snapshotPreview
            effectiveDate
            towingLaborStorageLimit
            trailerInterchangeLimit
            autoLiabilityLossCount
            autoLiabilityLossPaid
            physicalDamageLossCount
            physicalDamageLossPaid
            cargoLossCount
            cargoLossPaid
            truckerGenLiabLossCount
            truckerGenLiabLossPaid
            lossesConfirmedVsPending
            createdAt
            updatedAt
            isTest
            isThereRigging
            numberLosses3Years
            currentPage
            numberDrivers
            numberPowerUnits
            lobs
            avgRadOps
            domiciledWithin50Miles
            limitInsPerPowerUnit
            limitInsPerPowerUnitNonOwned
            perOccurenceLimit
            deductible
            spoilageFreezingCoverage
            mtcAdditionalCoverage
            pollutionCleanUp
            ownersGoodExtension
            nonOwnedTrailer
            expirationDate
            disclaimer
            status
            typeOfOps
            typeOfOpsDescriptionOther
            typeOfOpsFao
            glOrBop
            yearCurrentBusiness
            limitAl
            limitPd
            limitMtc
            limitGl
            limitNtl
            trailerInterchangeDeductible
            deductibleAl
            deductiblePd
            deductibleMtc
            deductibleGl
            deductibleNtl
            numberLossesPast3YearsGA
            # tarpsAndChains
            # reusablePackingContainers
            # subHaulerOwnerOrOperator
            # numberOfNonOwnedTrailers
            # debrisRemovalLimitGA
            # reloadingLimitGA
            # pollutionCleanUpLimitGA
            # earnedFreightLimitGA
            # fireDepartmentServiceChargesLimitGA
            # lossDataPreparationLimitGA
            # rewardCoverageLimitGA
            # reusablePackingContainersLimitGA
            # tarpsAndChainsLimitGA
            # annualValueShippedGA
            # totalCargoLossesInLast36MonthsGA
            continuousCoverage
            DOTObtain60Days
            typeOfTrucker
            ineligibleOperations
            vehicleHaulOilAndGas
            hazardousMaterialPlacard
            isCEODriver
            isCEOInvolvedInOperations
            bodilyInjuryLiabilityLimit
            otherGlOrBop
            priorInsuranceCancelledOrRenewed
            seriousViolationsWithinPast5Years
            ELDVendorName
            LossControlTechnology
            ELDRequiredToRecord
            commercialVehiclesInsuredOwns
            privatePassengerAutosInsured
            insuredVehiclesUsesInBusiness
            federal
            federalCargo
            progressiveState
            progressiveStateCargo
            progressiveOther
            progressiveEligibilityCriteria
            progressivePolicyState
            progressiveELDVendor
            ELDVendorAccess
            customerSpendLastYear
            nonOwnedVehiclesUsedInBusiness
            cargoCoverageForMobileHomes
            progressiveTrailerInterchangeCoverage
            trailerInterchangeFurnishAgreement
            nonOwnedTrailerPhysicalDamage
            MCS90
            isGAKickOut
            isCWKickOut
            cwLosses
            motorTruckCargoLimit
            towingLaborStorageLimitstr
            trailerInterchangeLimitstr
            trailerInterchangeLimitProgressive
            isProgKickOut
            vehiclesTowingOrHauling
            vehiclesHualSteel
            previousClaimCw
            totalAccidents
            totalNumberViolations
            totalSuspensions
            primaryAndNoncontributoryForTGL
            primaryAndNoncontributoryForAL
            requireFederalFillingForAL
            requireStateFillingForAL
            hauledRefrigeratedGoods
            ownerFname
            ownerLname
            ownerEmail
            ownerDob
            appStack
            appFlow
            insuredWithNICO
            policyNumbers
            effectiveDates
            newVenture
            towTruckRepossessions
            primaryBusiness
            ifNotPrimaryExplain
            Seasonal
            filedForBankruptcy
            whenFilledForBankruptcy
            explainForBankruptcy
            GrossReceiptsLastYear
            EstimateForComingYear
            BusinessForSale
            operateMoreThanOneState
            listStates
            largestCitiesEntered
            haulHazardousMaterialByEPA
            listingForChemicalMaterials
            hireVehicles
            driversCoveredCompensation
            ifYesNameOfCarrier
            minYearsDrivingExpRequired
            vehiclesOwnerDriven
            driversAllowedToTakeVehiclesHome
            maxDrivingHoursDaily
            maxDrivingHoursWeekly
            driverPayBasis
            lessorBeAdded
            nameAddressOfLessorForEachVehicle
            anyLossPayees
            nameAddressOfMortgageeForEachVehicle
            applicantAwareOfClaim
            provideCompleteDetails
            everBeenDeclined
            ifYesDateAndWhy
            additionalCoverageOptions
            intrastateFilingNeeded
            stateAndPermitNumber
            stateListForCargoFilings
            nameAndAddressPermitIssued
            MCS90EndorsementNeeded
            overWeightCommoditiesHauled
            ifFillingRequiredShowStates
            escortVehiclesTowed
            allowOthersToHaulUnderYourAuthority
            allowOthersToHaulHazardousCommodities
            everChangedYourOperatingName
            operateAnyOtherName
            leaseYourAuthority
            appointAgentsOnYourBehalf
            appliedForAuthorityPast3Years
            authorityWithdrawn
            evidenceRequired
            explainForAboveMultipleBooleanYes
            agreementsWithOtherCarriers
            withWhomAgreementHasBeenMade
            partiesNamedAutoMobilesLiability
            nameOfInsuranceCompanyAndLimitOfLiability
            whosePermitOtherPartiesOperate
            holdHarmlessInAgreement
            barterOrHireVehicles
            premiumBeFinanced
            cargoReqForMobileHomes
            cargoReqForBizDocsOrSecs
            fridgeBreakdownCoverage
            progMTCLimit
            compDeductible
            collDeductible
            forHireTruckingOps
            operateAWarehouse
            setUpOrInstallation
            frackingGasProd
            bizOutsideOfForHireOps
            progGLLimit
            vehicleLeaseProvidePrimaryLiability
            progNTLLimit
            progNonOwnedTrailerPDLimit
            progressive2ndPageKickOut
            filingState
            stateFilingsType
            proViewPolicyState
            snapShotProView
            proViewVehicleAnnualMileage
            proViewTermsAndConditions
            monthInBusiness
            typeOfOpsCW
            edited
            progCurrentPolicyexpirationDate
            coverWhaleOptAlUm
            coverWhaleOptAlPip
            authorityNumber
            indications {
              id
              carrier
              status
              estimatedPremium
            }
            File {
              url
              name
            }

            quotes {
              effectiveDate
              expirationDate
              totalLimit
              totalPremium
              totalDeductible
              monthlyPayment
              downPaymentPercentage
              lob
              pfa
              id
              carrier
              ascendProgramId
              ascendProgramUrl
              ascendInvoiceId
              ascendInvoiceUrl
              ascendInvoiceNumber
              ascendInvoiceDueDate
              File {
                id
                name
                type
                url
              }
              products {
                id
                status
                lob
                bindingPremium
                limit
                deductible
                quoteNumber
                invoicedBy
                admitted
                ascendQuoteId
                issuingCarrier
                billingCarrier
                quoteFileLocation
                quoteSigned
                quoteSignedFileLocation
                purchasedFlag
                oppStage
                downPaymentPercentage
                downPaymentAmount
                paymentType
                paymentSource
                applicationError
                declinedReason
                taxes
                platformFee
                PlatformFeeType
                File {
                  id
                  name
                  type
                  url
                }
                policy {
                  id
                  policyNumber
                }
              }
            }
            company {
              accountOwner
            }
          }
          EldProvider {
            id
            providerName
            isConnected
          }
          dispatchers {
            accessAllowed
            role
            dispatcher {
              id
              name
              email
              phone
            }
          }
        }
        contact {
          id
        }
        otherLeads {
          id
        }
        parentLeadId
        parentLead {
          id
        }
        eldProvider
        driversSnapShot
        vehiclesSnapShot
        commoditiesSnapShot
        activePendingList
        annualPayroll
        annualRevenue
        authorityHistoryList
        avgTimeSpent
        ascendInsuredId
        garagingCity
        garagingCounty
        garagingMail
        garagingNumberOfUnits
        garagingState
        garagingStreet
        garagingZipCode
        bipdInsuranceOnFile
        bipdInsuranceRequired
        bondInsuranceOnFile
        bondInsuranceRequired
        brokerAppPending
        brokerAuthStatus
        businessDescription
        businessEntityType
        cargo
        cargoCarried
        cargoCarrier
        cargoInsuranceOnFile
        cargoInsuranceRequired
        cargoLimits
        carrierOperation
        city
        commonAppPending
        commonAuthStatus
        company
        contactTitle
        contractAppPending
        contractAuthStatus
        convertedAccount
        convertedContact
        convertedDeal
        county
        crashedFatal
        crashedInjury
        crashedTotal
        crashedTow
        createdBy
        daysVisited
        dbaName
        designation
        dotNumber
        dotRegistration
        dunsNumber
        email
        emailNotValid
        emailOptOut
        expiringInsuranceCompany
        fax
        firstName
        firstPageVisited
        firstVisit
        fullTimeEmployees
        inspectionsDriver
        inspectionsHazmat
        inspectionsLep
        InspectionsVehicle
        insuranceHistoryListData
        insuranceType
        lastKnownAutoExpirationDate
        lastKnownCargoExpirationDate
        lastKnownWcExpirationDate
        lastName
        layout
        leadCost
        leadOwner
        leadSource
        leadSourceLeadId
        leadStatus
        leadType
        liabilityCarrier
        liabilityLimits
        limitsRequired
        listCampaignType
        mailZipCode
        mailingCity
        mailingCounty
        mailingMail
        mailingNumberOfUnits
        mailingState
        mailingStreet
        marketingAutoScore
        maximumRadiusOfOperations
        mcNumber
        mcs150FormDate
        mobile
        modifiedBy
        mostRecentVisit
        naicCode
        natlAverageDriver
        natlAverageHazmat
        natlAverageLep
        natlAverageVehicle
        noOfEmployees
        numberOfChats
        numberOfDrivers
        numberOfUnits
        operatingStatus
        operationClassification
        outOfServiceDriver
        outOfServiceHazmat
        outOfServiceLep
        outOfServicePercentageDriver
        outOfServicePercentageHazmat
        outOfServicePercentageLep
        outOfServicePercentageVehicle
        outOfServiceVehicle
        ownerYearsOfExperience
        partTimeEmployees
        pendingApplicationList
        phone
        physicalMail
        policyNumber
        referrer
        rejectionInsuranceList
        revocationListData
        sdrLead
        sicCode
        spId
        spTier
        spType
        state
        street
        subContractorCosts
        visitorScore
        website
        havePrevAuthority
        yearsInBusiness
        zipCode
        org
        auth {
          id
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const BROKER_USER_COMPANY_CONNENCTION = gql`
  mutation brokerUserCompanyConnection(
    $email: String!
    $name: String!
    $phone: String!
    $companyId: ID!
  ) {
    brokerUserCompanyConnection(
      email: $email
      name: $name
      phone: $phone
      companyId: $companyId
    ) {
      token
      user {
        id
        name
        phone
        email
        lastLogin
        role
        accessAllowed
        company {
          id
          garagingState
          dot
          name
          mailingAddress
          mailingState
          mailingCity
          mailingZip
          yearsInBusiness
          eldProvider
          notes
        }
      }
    }
  }
`;

export const FETCH_SINGLE_POLICY_QUERY = gql`
  mutation policyInfo($policyId: String!) {
    policyInfo(policyId: $policyId) {
      status
      policyNumber
      totalPremium
      endorsmentsPremium
      issuingCarrier
      billingCarrier
      effectiveDate
      expirationDate
      accountManager
      paymentSource
      paymentType
      downPaymentAmount
      downPaymentPercentage
      taxes
      platformFee
      PlatformFeeType
      lob
      quote {
        totalLimit
        totalDeductible
      }
      product {
        id
        status
        lob
        bindingPremium
        limit
        ascendQuoteId
        deductible
        quoteNumber
        invoicedBy
        admitted
        issuingCarrier
        billingCarrier
        effectiveDate
        expirationDate
        application {
          id
          stage
          preferedCarrier
          effectiveDate
          createdAt
          lobs
          domiciledWithin50Miles
          limitInsPerPowerUnit
          limitInsPerPowerUnitNonOwned
          perOccurenceLimit
          deductible
          expirationDate
          status
          limitAl
          limitPd
          limitMtc
          limitGl
          limitNtl
          trailerInterchangeDeductible
          deductibleAl
          deductiblePd
          deductibleMtc
          deductibleGl
          deductibleNtl
        }
      }
    }
  }
`;

export const SEND_MESSAGE_TO_BROKER_MUTATION = gql`
  mutation userMessageToBroker($message: String) {
    userMessageToBroker(message: $message) {
      message
    }
  }
`;

export const SEND_MESSAGE_TO_SLACK_BY_CHANNEL_NAME_MUTATION = gql`
  mutation slackMessageByChannelName($message: String!, $channelName: String!) {
    slackMessageByChannelName(message: $message, channelName: $channelName) {
      message
    }
  }
`;

export const CREATE_ELD_PROVIDER_MUTATION = gql`
  mutation createNewEldProviderWithCompany(
    $companyId: ID!
    $token: String!
    $provider: String!
  ) {
    createNewEldProviderWithCompany(
      companyId: $companyId
      token: $token
      provider: $provider
    ) {
      lead {
        id
      }
      account {
        id
        dotNumber
        dbaName
        mailingStreet
        garagingStreet
        garagingCounty
        mailingCounty
        mcNumber
        mailingCity
        garagingCity
        mailZipCode
        garagingZipCode
        yearsInBusiness
        mailingState
        garagingState
        phone
        policyChangeData
        eldProvider
        trucks {
          id
          vin
          vehicleType
          modelYear
          vehicleMake
          vehicleModel
          subTruckType
          subTrailerType
          gvw
          rearAxles
          garagingCity
          operationRadius
          annualMileage
          safetyFeatures
          limit
          createdAt
          ownership
          bodyStyle
          value
          rearAxles
          vehicleClass
          radius
          vehicleUse
          needCoverage
          vehicleZip
          loanOrLease
          antiTheft
          antiLockBrakes
          airbag
          isDeleted
          valuePermEquipment
          typeOfTrailerHitch
          grossVehicleWeight
          vehicleTonnage
          numberOfDrivingWheels
          percentageOfRepossessionWork
          numberOfRearAxles
          numberOfPassengerSeats
          vehicleEquippedWheelchair
          stretchLength
          typeOfSUV
          vehicleTankSize
          haulGoodsOnForHireBasis
          glassVinEtching
          transportPassengersRevenue
          perimeterSeatingOrPartyBus
          numberOfStalls
          publicTransportationIndicator
          permanentFoundation
          lengthOfTrailer
          collision
          comprehensive
          cwDeductibles
          inPossessionForMoreThan30Days
          auxiliaryRunning
          vehicleRegistrant
          loanCompany
          leaseCompany
          loanOtherCompanyName
          loanOtherCompanyMailing
          loanOtherCompanyCity
          loanOtherCompanyZip
          leaseOtherCompanyName
          leaseOtherCompanyMailing
          leaseOtherCompanyCity
          leaseOtherCompanyZip
          isDeleted
          status
        }
        drivers {
          id
          firstName
          lastName
          dob
          dlNumber
          dlState
          dateOfHire
          phone
          dlYearsLicensed
          mvrStatus
          email
          isDeleted
          mvrCheckDate
          address
          city
          state
          zip
          isOwner
          eligibility
          yearsExperience
          isSr22
          hasAnyViolationsInPast5Years
          licenseIssueDate
          past3YearMajorViolations
          past3YearsAccidents
          past3YearsViolations
          past3YearsSuspensions
          accidentsViolationsDetails
          totalMajorConvictionsDates
          totalMajorConvictions
          maritalStatus
          status
        }
        EldProvider {
          id
          providerName
          isConnected
        }
      }
    }
  }
`;

export const DELETE_ELD_PROVIDER_MUTATION = gql`
  mutation deleteEldById($id: ID!) {
    deleteEldById(id: $id) {
      id
    }
  }
`;

export const SYNC_ELD_PROVIDER_MUTATION = gql`
  mutation syncEldById($id: ID!, $companyId: ID!) {
    syncEldById(id: $id, companyId: $companyId) {
      id
      dotNumber
      dbaName
      mailingStreet
      garagingStreet
      garagingCounty
      mailingCounty
      mcNumber
      mailingCity
      garagingCity
      mailZipCode
      garagingZipCode
      yearsInBusiness
      mailingState
      garagingState
      phone
      policyChangeData
      eldProvider
      trucks {
        id
        vin
        vehicleType
        modelYear
        vehicleMake
        vehicleModel
        subTruckType
        subTrailerType
        gvw
        rearAxles
        garagingCity
        operationRadius
        annualMileage
        safetyFeatures
        limit
        createdAt
        ownership
        bodyStyle
        value
        valuePermEquipment
        rearAxles
        vehicleClass
        radius
        vehicleUse
        needCoverage
        vehicleZip
        loanOrLease
        antiTheft
        antiLockBrakes
        airbag
        isDeleted
        typeOfTrailerHitch
        grossVehicleWeight
        vehicleTonnage
        numberOfDrivingWheels
        percentageOfRepossessionWork
        numberOfRearAxles
        numberOfPassengerSeats
        vehicleEquippedWheelchair
        stretchLength
        typeOfSUV
        vehicleTankSize
        haulGoodsOnForHireBasis
        glassVinEtching
        transportPassengersRevenue
        perimeterSeatingOrPartyBus
        numberOfStalls
        publicTransportationIndicator
        permanentFoundation
        lengthOfTrailer
        auxiliaryRunning
        vehicleRegistrant
        loanCompany
        leaseCompany
        loanOtherCompanyName
        loanOtherCompanyMailing
        loanOtherCompanyCity
        loanOtherCompanyZip
        leaseOtherCompanyName
        leaseOtherCompanyMailing
        leaseOtherCompanyCity
        leaseOtherCompanyZip
        isDeleted
        status
      }
      drivers {
        id
        firstName
        lastName
        dob
        dlNumber
        dlState
        dateOfHire
        phone
        dlYearsLicensed
        mvrStatus
        email
        isDeleted
        mvrCheckDate
        address
        city
        state
        zip
        isOwner
        eligibility
        yearsExperience
        isSr22
        hasAnyViolationsInPast5Years
        licenseIssueDate
        past3YearMajorViolations
        past3YearsAccidents
        past3YearsViolations
        past3YearsSuspensions
        accidentsViolationsDetails
        totalMajorConvictionsDates
        totalMajorConvictions
        maritalStatus
        status
      }
      EldProvider {
        id
        providerName
        isConnected
      }
    }
  }
`;

export const ERROR_MESSAGE_TO_SLACK = gql`
  mutation errorMessageToSlack(
    $normalUser: Boolean!
    $message: String!
    $ltUserName: String
  ) {
    errorMessageToSlack(
      normalUser: $normalUser
      message: $message
      ltUserName: $ltUserName
    )
  }
`;

export const QUOTE_PERCENTAGE_QUERY = gql`
  mutation upsertQuotePercentage($packageId: ID!, $percentage: Float!) {
    upsertQuotePercentage(packageId: $packageId, percentage: $percentage) {
      effectiveDate
      expirationDate
      totalLimit
      totalPremium
      totalDeductible
      monthlyPayment
      downPaymentPercentage
      lob
      pfa
      id
      carrier
      ascendProgramId
      ascendProgramUrl
      ascendInvoiceId
      ascendInvoiceUrl
      ascendInvoiceNumber
      ascendInvoiceDueDate
      File {
        id
        name
        type
        url
      }
      quote {
        id
        status
        lob
        bindingPremium
        limit
        deductible
        quoteNumber
        invoicedBy
        admitted
        ascendQuoteId
        issuingCarrier
        billingCarrier
        quoteFileLocation
        quoteSigned
        quoteSignedFileLocation
        purchasedFlag
        oppStage
        downPaymentPercentage
        downPaymentAmount
        paymentType
        paymentSource
        applicationError
        declinedReason
        taxes
        platformFee
        PlatformFeeType
        File {
          id
          name
          type
          url
        }
        policy {
          id
          policyNumber
        }
      }
    }
  }
`;

export const CLOSE_ENDORSEMENT_MUTATION = gql`
  mutation closeEndorsement(
    $endorsementId: ID!
    $endorsement: EndorsmentInput!
  ) {
    closeEndorsement(endorsementId: $endorsementId, endorsement: $endorsement) {
      id
      companyId
      name
      type
      amount
      dateComplete
      endorsementEffectiveDate
      status
      note
    }
  }
`;

export const UPDATE_LIMIT_AND_DEDUCTIBLE_MUTATION = gql`
  mutation updateLimitsAndDeductiblesPolicy(
    $policyId: ID!
    $policy: PolicyInput!
  ) {
    updateLimitsAndDeductiblesPolicy(policyId: $policyId, policy: $policy) {
      id
      limitsDeductiblesChange
    }
  }
`;

export const UPDATE_ACCOUNT_SNAPSHOT_MUTATION = gql`
  mutation updateAccountSnapshot($snapShotData: JSON, $type: String!) {
    updateAccountSnapshot(snapShotData: $snapShotData, type: $type) {
      driversSnapShot
      vehiclesSnapShot
      commoditiesSnapShot
    }
  }
`;

export const RESET_ACCOUNT_SNAPSHOT_MUTATION = gql`
  mutation resetAccountSnapshot($type: String!) {
    resetAccountSnapshot(type: $type) {
      driversSnapShot
      vehiclesSnapShot
      commoditiesSnapShot
    }
  }
`;

export const GET_POWER_SESSION_MUTATION = gql`
  mutation getPowerSessionURL($userId: String) {
    getPowerSessionURL(userId: $userId)
  }
`;

export const CHECK_ACCOUNT_EXISTING_POLICIES_MUTATION = gql`
  mutation checkAccountActivePolicies($lobs: String!, $operation: String!) {
    checkAccountActivePolicies(lobs: $lobs, operation: $operation)
  }
`;

export const GET_USER_REFERRAL_LINK = gql`
  mutation getUserReferralLink {
    getUserReferralLink
  }
`;

export const REQUEST_EDIT_COI = gql`
  mutation requestEditCOI($userType: String, $accountId: String) {
    requestEditCOI(userType: $userType, accountId: $accountId) {
      message
    }
  }
`;

export const FETCH_ALL_PAGINATED_REFERRAL_PROGRAM = gql`
  mutation fetchAllReferralProgramPaginated(
    $page: Int!
    $text: String!
    $status: ReferralStatus
  ) {
    fetchAllReferralProgramPaginated(
      page: $page
      text: $text
      status: $status
    ) {
      referralProgram {
        id
        status
        referByLead {
          id
          phone
          mobile
          email
          company
          firstName
          lastName
        }
        referByContact {
          id
          email
          phone
          mobile
          firstName
          lastName
        }
        referToLead {
          id
          company
          firstName
          lastName
          phone
          mobile
          email
        }
        referToAccount {
          id
          accountName
        }
        referToPhone
        statusReason
        referToEmail
        incentiveCodeReferBy
        incentiveCodeReferTo
        referToAccountProgress
      }
      count
    }
  }
`;

export const UPDATE_ELIGIBLE_REFERRAL = gql`
  mutation updateEligibleReferral(
    $referralId: String!
    $statusReason: String
    $status: ReferralStatus!
    $incentiveCodeReferTo: String
    $incentiveCodeReferBy: String
  ) {
    updateEligibleReferral(
      status: $status
      referralId: $referralId
      statusReason: $statusReason
      incentiveCodeReferTo: $incentiveCodeReferTo
      incentiveCodeReferBy: $incentiveCodeReferBy
    ) {
      id
      status
      referByContact {
        id
        phone
        email
        firstName
        lastName
      }
      referByLead {
        id
        phone
        email
        firstName
        lastName
      }
      referToLead {
        id
        phone
        email
        firstName
        lastName
      }
      referToPhone
      statusReason
      referToEmail
      incentiveCodeReferBy
      incentiveCodeReferTo
      referToAccountProgress
    }
  }
`;

export const SEND_REFERRAL_INVITE = gql`
  mutation sendReferralInvites(
    $name: String
    $email: String
    $phone: String
    $type: String!
  ) {
    sendReferralInvites(
      name: $name
      email: $email
      phone: $phone
      type: $type
    ) {
      id
      status
      referByLead {
        id
        firstName
        lastName
        phone
        email
      }
      referByContact {
        id
      }
      referToLead {
        id
        firstName
        lastName
        phone
        email
      }
      referToAccount {
        id
      }
      referToPhone
      statusReason
      referToEmail
      incentiveCodeReferBy
      incentiveCodeReferTo
      referToAccountProgress
    }
  }
`;

export const NO_DOT_SLACK_MESSAGE = gql`
  mutation noDOTSlackMessage {
    noDOTSlackMessage {
      message
    }
  }
`;

export const NO_DOT_COMPLETE_SLACK_MESSAGE = gql`
  mutation noDOTCompleteSlackMessage {
    noDOTCompleteSlackMessage {
      message
    }
  }
`;

export const GET_NOTES = gql`
  mutation getNotes($module: String!, $moduleId: ID!) {
    getNotes(module: $module, moduleId: $moduleId) {
      id
      title
      content
      ownedByName
      ownedById
      ownedByEmail
      createdByName
      createdById
      createdByEmail
      modifiedByName
      modifiedById
      modifiedByEmail
      createdAt
      updatedAt
      files {
        id
        name
        description
        type
        url
        zohoId
      }
      account {
        id
      }
      lead {
        id
      }
    }
  }
`;

export const INSERT_NOTE = gql`
  mutation insertNote($note: NoteInput) {
    insertNote(note: $note) {
      id
      title
      content
      ownedByName
      ownedById
      ownedByEmail
      createdByName
      createdById
      createdByEmail
      modifiedByName
      modifiedById
      modifiedByEmail
      createdAt
      updatedAt
      account {
        id
      }
      lead {
        id
      }
    }
  }
`;

export const CREATE_DISPATCHER_AND_CONNECT_WITH_COMPANY_MUTATION = gql`
  mutation createAuthenticatedDispatcher(
    $name: String!
    $email: String!
    $phone: String!
  ) {
    createAuthenticatedDispatcher(name: $name, email: $email, phone: $phone) {
      accessAllowed
      role
      dispatcher {
        id
        name
        email
        phone
      }
    }
  }
`;

export const GET_TOW_APPLICATION = gql`
  mutation getTowApplication($type: String!, $lobs: String!) {
    getTowApplication(type: $type, lobs: $lobs) {
      kbk {
        id
        date
        applicantInfo
        Account_Name
        Effective_Date
        Mailing_Street
        Mailing_City
        Mailing_State
        Mail_Zip_Code
        Email
        businessType
        Years_in_Business
        Phone
        federalId
        locationInfo
        Mobile
        ELD_Vendor_Name
        BusinessIndividual
        BusinessCorp
        BusinessLLc
        BusinessPartnership
        BusinessJointVenture
        otherDescribeSection1
        repossessionsVoluntary
        repossessionsInvoluntary
        repossessionsEachMonth
        nameAndDescribe
        numberOfMeetingMonthly
        conductSafetyProgram
        vehicleMaintenanceFlag
        yesDescribe10b
        vehicleAttachedScheduleFlag
        noExplain10d
        customized11Text1
        iccFilingsFlag
        listBelow12c
        address12c
        applicantComplyByDotFlag
        AuthorityGrantedName
        applicantOperatePermitFlag
        dealerPlates13
        ids_13
        dealerPlatesAttachedFlag
        descriptionOfVehicle14a
        dealerPlatesUsedFor
        personalUseOfPlatesFlag
        Monthly_Hauls_Over_50_Miles
        Monthly_Hauls_Over_200_Miles
        Largest_Cities_Entered
        applicantCarryWorkersCompensationFlag
        policyPeriod
        InsuranceCo
        Number_of_Power_Units
        applicantSubsidiariesFlag
        applicantOperateFlag
        towingContractFlag
        ELD_Vendor_AccessFlag
        applicantRepossessionFlag
        formalSafetyFlag
        vehicleLeasedLoanedFlag
        customized11Text2
        pucFilingsFlag
        Is_MCS_90_endorsement_neededFlag
        applicantCarryWorkers
        applicantOperationFlag
        priorHiringFlag
        safetyOperationFlag
        applicantPickTowingFlag
        radiusOfOperation
        tireSalesFlag
        yesReceipt19b
        applicantDisposeTires
        applicantSellNewTires
        applicantSellUsedTires
        recappingOrRetreadingFlag
        whereWeldingPerformed
        protectiveScreensFlag
        selfService
        fullService
        serviceStationBoth
        applicantOperate
        cStore
        carWash
        gallonsSold
        applicantPump
        applicantPollutionLiabilityFlag
        numberOfUnitsAnnually
        ownCrushingMachineFlag
        yesDescribe24d
        removedFromVehiclesFlag
        yesExplain25b
        publicParkingChargeFlag
        numberPerMonth
        monthlyReceipt26c
        dogsOnPremisesFlag
        yesNumber27b
        breed
        trainedGuardDogsFlag
        applicantOwnOrSponsorFlag
        sprayPaintingPerformedFlag
        applicantULApprovalFlag
        WeldingPerformedFlag
        serviceStationFlag
        applicantDismantlingOrSalvageFlag
        publicSalvageAreaFlag
        vehicleCompanyBusinessFlag
        dogSignsFlag
        dogsPennedFlag
        monOperation
        tueOperation
        wedOperation
        thuOperation
        friOperation
        satOperation
        sunOperation
        storingCars30
        changeInVehicleOperationFlag
        yesExplain31b
        yesWhy32b
        applicantRequireWrittenFlag
        listTowingSchool
        Driver_Pay_Basis
        safeDrivingProgramFlag
        yesExplain35b
        driversApplicantEmployeesFlag
        nameOfContractor
        applicantUserOwnerOperatorFlag
        includingIncidentalFlag
        driverHandle
        airBagsTowingFlag
        yesHowManyBags
        applicantAlwaysSafetyChainsFlag
        applicantPlowFor
        Have_you_ever_been_declined_cancelled_or_non_ren
        applicantCheckDrivingFlag
        snowplowingFlag
        coveragesAndLimits
        medicalPayment1000
        medicalPayment2000
        medicalPayment5000
        medicalPayment
        personalInjury
        limitRequiredByLaw
        comprehensiveDeductible
        collisionDeductible
        csl
        limitPerPerson5000
        limitLocation1
        limitLocation2
        limitLocation3
        limitLocation4
        gkDeductible
        onHookCargo
        include
        exclude
        noCoverageUntilCompanyDate
        applicantSignature
        insuredName
        vehicleDate
        membersOfHouseholdFlag
        # // table mapping
        locationInformation
        operationInformation
        largestClientInformation
        itemHauledInformation
        insuranceCompaniesInformation
        locationFencedInformation
      }
      scottsdale {
        id
        ApplicantName
        agencyName
        ApplicantName1
        agentNo
        locationAddress1
        agentPhone
        locationAddress2
        fromDate
        toDate
        applicantHaul1
        applicantHaul2
        numberOfVehiclesOwned
        numberOfVehiclesLeased
        numberOfVehiclesNonOwned
        vehicleLicensed2
        overweightPermits2
        privateWarehouseDescription
        maintenanceProgram2
        ScottsdaleInsuranceFlag
        scottsdaleSurplusFlag
        scottsdaleIdemnityFlag
        establishedEquipmentMaintenanceFlag
        applicantWarehouseFlag
        overweightPermitsFlag
        vehicleLicensedFlag
        commonCarrierFlag
        contractCarrierFlag
        allLocation1
        radiusLoc1
        radiusLoc2
        radiusLoc3
        radiusLoc4
        radiusLoc5
        stageApplication1
        stageApplication2
        stageApplication3
        stageApplication4
        stageApplication5
        fuelStored
        fuelForPrivate
        numberOfGallons
        indicateOperations
        courierDelivered
        iceCreamTrucks
        trucksGrossSales
        SandSaltDispensingRoadways
        SandSaltDispensingPayroll
        sandwichCatering
        sandwichCateringGrossSales
        snowIceRemovalPayroll
        applicantOperateMobile_Text
        commoditiesHauled
        snowIceRemoval_Text
        commoditiesHauledAsphaltFlag
        commoditiesHauledMedicalFlag
        commoditiesHauledMobileFlag
        commoditiesHauledChemicalFlag
        commoditiesHauledCoalFlag
        commoditiesHauledFertilizerFlag
        commoditiesHauledFlammableFlag
        commoditiesHauledOthersFlag
        commoditiesHauledOilFieldFlag
        commoditiesHauledTiresFlag
        commoditiesHauledToxicWasteFlag
        commoditiesHauledGarbageCommercialFlag
        commoditiesHauledGarbageResidentialFlag
        commoditiesHauledHouseholdFlag
        commoditiesHauledLodgingFlag
        commoditiesHauledLPGFlag
        commoditiesHauledMarijuanaFlag
        commoditiesHauledFuelOilFlag
        commoditiesHauledLoadFlag
        applicantOperateMobileFlag
        bicycleMessengerFlag
        courierDeliveredFlag
        craneServicesFlag
        craftingUnCraftingFlag
        debrisRemovalFlag
        escortVehiclesFlag
        houseMovingFlag
        IcecreamTrucksFlag
        sandOrSaltFlag
        sandwichCateringFlag
        snowIceRemovalFlag
        towingServiceRepairFlag
        towingRepairFlag
        truckBrokeringFlag
        excavationGradingFlag
        publicLiveryFlag
        hydraulicFracturing2
        hydraulicFracturing3
        applicationRigging2
        applicationRigging3
        applicationRigging4
        applicantDumpster2
        assemblyInstallation2
        assemblyInstallation3
        serviceRepair2
        serviceRepair3
        nonOwnedVehicle2
        operationOthers1
        descriptionOfOperations
        annualCost
        generalLiabilityLimit
        holdHarmlessAgreements
        autoLiabilityPolicyNumber
        MTCPolicyNumber
        autoLiabilityInsuranceCarrier
        MTCInsuranceCarrier
        AutoLiabilityLimit
        MTCLimit
        autoLiabilityExpirationDate
        MTCExpirationDate
        businessVentures
        businessVentures2
        hydraulicFracturingFlag
        applicationRiggingFlag
        useAircraftFlag
        applicantDumpsterFlag
        operateLandfillFlag
        assemblyInstallationFlag
        serviceRepairFlag
        repossessionOperationsFlag
        nonOwnedVehicleFlag
        applicantSubcontractFlag
        generalLiabilityLimitsFlag
        workersCompensationFlag
        COIRequiredFlag
        additionalInsuredFlag
        harmlessAgreementsFlag
        businessVenturesFlag
        riskEngage2
        riskEngage3
        riskEngageFlag
        oklahomaApplicants
        applicantSignature1
        applicantSignature2
        coApplicantSignature
        coApplicantSignature1
        producerSignature
        producerSignatureDate
        agentName
        agentLicenseNumber
        IowaLicensed
        locationInformation
        ListOfOffices
      }
      tumiTow {
        id
        Account_Name
        Effective_Date
        Mailing_Street
        Mailing_City
        Mailing_County
        Mailing_State
        Mail_Zip_Code
        otherDescribeSection1
        Years_in_Business
        user_name
        Mobile
        federalId
        alLimit300000
        alLimit500000
        alLimit750000
        alLimit1000000
        noFaultPIP
        medicalPaymentsTumi
        uninsuredMotoist
        textUninsuredMotoist
        hiredCar
        propertyProtectionOnly
        propertyProtectionOnlyText
        medicalPaymentsTextTumi
        underinsuredMotorist
        nonOwnedAuto
        noOfTags
        tagNumber1
        tagNumber2
        tagNumber3
        tagNumber4
        useOfEachTag
        totalGAS
        section4CheckTowing
        section4PercentTowing
        section4PercentRightText
        section4CheckboxVoluntary
        section4VolReposs
        section4PercentOfRevenueRightText2
        section4InvReposs
        section4PercentOfRevenue3
        section4PercentOfRevenueRightText3
        section4CheckboxAutoTransport
        section4PercentOfRevenue4
        section4PercentOfRevenueRightText4
        section4CompleteSec17
        section4CheckboxEH
        section4PercentOfRevenue5
        section4PercentOfRevenueRightText5
        section4CompleteSec18
        section4CheckboxTrucking
        section4OperationTruckingOther
        section4PercentOfRevenue6
        section4PercentOfRevenueRightText6
        section4CheckboxRepairShop
        section4PercentOfRevenue7
        section4PercentOfRevenueRightText7
        section4CheckboxBodyShop
        section4PercentOfRevenue8
        section4PercentOfRevenueRightText8
        section4CheckboxSS
        section4PercentOfRevenue9
        section4PercentOfRevenueRightText9
        section4CheckboxRentalOperations
        section4CheckboxSalvageUsedPart
        section4PercentOfRevenue10
        section4PercentOfRevenueRightText10
        section4CheckboxTireSales
        section4PercentOfRevenue11
        section4PercentOfRevenueRightText11
        section4CheckboxFIreworks
        section4PercentOfRevenue12
        section4PercentOfRevenue13
        section4PercentageOfRevenueRightText12NEW
        section4PercentageOfRevenueRightText13Used
        section4PercentageOfRevenueRightText14
        section4CheckboxLienCarSales
        section4PercentOfRevenue14
        section4PercentageOfRevenueRightText15
        section4CheckboxAuctions
        section4CheckboxPartsSalesNew
        section4PercentOfRevenue15
        section4PercentOfRevenue16
        Section4CompleteSection18_2
        section4CompleteSection19
        Section4CompleteSection20
        Section4CompleteSection14
        Section4CompleteSection13
        Section4CompleteSection16
        Section4CompleteSectionGrossSalesText1
        Section4CompleteSectionGrossSalesText2
        Section4CompleteSectionNumberOfCarsSoldPerMonth
        section4PercentageOfRevenueRightText16
        section4CompleteSectionNumberAnnually
        Section4CompleteSectionPartsSalesNew
        section4CheckboxParkingStorage
        section4PercentOfRevenue17
        Section4CompleteSectionParkingStorage
        section4CheckboxOtherSpecify
        section4OperationTextOtherSpecify
        section4PercentOfRevenue18
        section4PercentageOfRevenueRightText19
        section4CompleteSectionOtherSpecifyText1
        section4CompleteSectionOtherSpecifyText2
        Other_describe_text
        Phone
        section4PercentageOfRevenueRightText17
        section4PercentageOfRevenueRightText18
        Section4FinancialHistoryFlag
        nameAndTitle
        weeklyTumi
        monthlyTumi
        quarterlyTumi
        section5AreAllTrcuksEquippedFlag
        section5DoYouHaveWrittenDriverFlag
        section5DoYouHaveWrittenVehicleFlag
        section5DoYouHaveDrugTestingFlag
        section6DoYouHaveMaintainFlag
        section6PerformRoutineMaintenanceFlag
        section6ProfessionallyCertifiedFlag
        section6AreDriversInAnyWayResponsibleFlag
        section6DriversPerformDailyFlag
        section6AreYourVehiclesSubjectToAnnualFlag
        section7DoYouObtainMVRFlag
        section7RequireJobRefFlag
        section7ChekcJobReferencesPrioirToHiringFlag
        section7RoadTestAllDriversFlag
        section7ObtainMVRAnnualBasisFlag
        section7MaintainDriverFilesFlag
        section7allDriverFederalStateFlag
        section7WrittenDisciplinaryPolicyFlag
        section7WrittenAccidentReviewFlag
        section7IssueIndependentContractorFlag
        section7HourlyWage
        section7Salary
        section7Commission
        section7_1099
        section7CarryWorkerCompFlag
        numberOfDriversFiredLastYr
        numberOfDriversHireLastYr
        driversTraining
        section7YourDriversToTakeTrainingCoursesFlag
        section8LeaseVehiclesFromOtherFlag
        section8LeaseVehiclesToOtherFlag
        section8OwnOrLeaseCranesFlag
        section8HireSubConteactorsFlag
        section8AdditionalVehiclesOwnedOrLeasedFlag
        section8VehicelsTravelOutside200Flag
        section8SafetyChainOnEveryTowFlag
        section8WheelLiftStrapsOnEveryTowFlag
        section8VehiclesTowingLightsOneveryTowFlag
        section9RequireDOTFlag
        section9MCS90Flag
        section9PerformSecondaryTowsOfHazardousMaterialsFlag
        section9HazardousMaterialsOnPrimaryHaulFlag
        section9MCORDOTNUMBERText
        section9STATE
        section9AreAnyAdditionalFillingsRequired
        doYouHaveAWrittenSafetyProgramFlag
        section6IfNotPerformMaintenanceText
        section8miles050Text
        section8miles51_200Text
        section8milesOver201Text
        section10OccurrenceLimit300000
        section10OccurrenceLimit500000
        section10OccurrenceLimit750000
        section10OccurrenceLimit1000000
        section10AggregateLimit
        section11GKLegalLiability
        section11DirectPrimary
        section12HowManyTowsDoYouPerform
        section12HowManyEmployeesInvolved
        section12PrivatePropertFlag
        section12MunicipalityFlag
        section12VoluntaryRepossessionFlag
        section12HeavyDuty7Flag
        section12MotorClub8Flag
        section12TowingForBanks9Flag
        section12TowingForYourOwnGarage10Flag
        section12InvoluntaryRepossessions11Flag
        section12equippedWithScanners12Flag
        section12ParticipateInAnyChase13Flag
        section12RequireEachTowPerformed14Flag
        section13RentalOrLeasingFlag
        section13GLPolicyInPlaceCoveringFlag
        section14GlPolicyCoveringThisoperation1Flag
        section14UndergroundGasStorage2Flag
        section14MaintainPollutionCoverage3Flag
        section14AlcoholicBeverages4Flag
        section15WhoissueAssignment1Text
        section15performInvoluntaryRepossessions3Flag
        section17ListAllDestIFTA
        section17AnyFreightBrokeringFlag
        section17HaulBoatsFlag
        section17UseTrailerInterchangeFlag
        section17HaulCampersFlag
        section17AnyDriveAwayWorkFlag
        section17HaulOtherFeightFlag
        section17TransporterPlatesFlag
        section17AutoDealerFlag
        section17AnyVehicleLeasedFlag
        section17AutoRepairBodySHopFlag
        section17AnyEmployeesNotListedFlag
        section17OwnOrSponsorFlag
        section17AnyOwnedVehiclesNotListedFlag
        section17StorageLotFlag
        section17TruckerPayrollText1
        section17TruckerPayrollText2
        section18ContractsToHaulGoods1Flag
        section18ClassALicensedDrivers2Flag
        section18DoYouHaulTransport3Flag
        section15DebtorsNotified2Flag
        section18MaximumDistanceTraveledText
        section18numberDriversAreInvolvedInThisOperation
        section18WhatCommoditiesDoYouHaul
        section18LargeText
        section19AnnualGrossSales
        section19DoYouSellAutoPartsNew
        section19DoYouSellAutoPartsUsed
        section19DoYousellAndInstallTiresNEW
        section19DoYousellAndInstallTiresUsed
        section19MinorRepairs
        section19MajorRepairs
        section19PrivatePassenger
        section19CommercialAuto
        section19CommercialEquipment
        section19NumberOfThoseBays
        section19SellOr
        section19ServiceAny
        section19ATVs
        section19Motorcycles
        section19GrayMarketVehicles
        section19Boats
        section19KitCars
        section19RVs
        section19VanConversion
        section19StretchVehicles
        section20WhatCompanyManufacturedTheFrame
        applicantTextDrivers
        driversTableNoLeftText
        section21Tag4
        section22DateOfNonRenewal3
        section22NonPaymentOfpremium1Flag
        section22NonRenewed2Flag
        section22ApplicantHaveWC5Flag
        section22RequiredToCarryWC4Flag
        section22IfYESWhatareCurrrentExp
        section21HowManyTags
        section21Tag1
        section21Tag2
        section21Tag3
        section22HaveLossRUnsFlag
        dateOfLossText
        driverCheckBoxFlag
        section10GLSLocation1OtherSpecifyText
        section10GLSLocation2OtherSpecifyText
        section10GLSLocation3OtherSpecifyText
        howManyServicebays24
        driverApplicant
        # // table mapping
        locationInformation
        generalLiabilityInformation
        storageFacilityInformation
        coverageInformation
        serviceGarageAutoRepair
        OperationsLocationInformation
        salvageDismantler
        serviceGarageAutoBody
        LossHistory
        previousInsuranceCarriers
      }
      application {
        id
        stage
        activeAuth
        averageViolations
        doTheseApply
        doTheseApplyCw
        preferedCarrier
        priorityStatus
        operationsDescription
        driversAbove23
        cdlExperience
        anySeriousViolations
        currentProgCust
        businessStructure
        driverSummary
        totalValueTrucks
        totalValueTrailers
        grossAnnualTruckRev
        annualTotalMileage
        haulsUnder50Miles
        haulsUnder200Miles
        haulsUnder500Miles
        haulsAbove500Miles
        currentlyInsured
        otherCoverages
        totalAdditionalInsureds
        totalNamedInsureds
        blanketAdditionalNeeded
        blanketWaiverSubrogations
        stateOrFederalFilingsReq
        snapshotPreview
        effectiveDate
        towingLaborStorageLimit
        trailerInterchangeLimit
        autoLiabilityLossCount
        autoLiabilityLossPaid
        physicalDamageLossCount
        physicalDamageLossPaid
        cargoLossCount
        cargoLossPaid
        truckerGenLiabLossCount
        truckerGenLiabLossPaid
        lossesConfirmedVsPending
        createdAt
        updatedAt
        isTest
        isThereRigging
        numberLosses3Years
        currentPage
        numberDrivers
        numberPowerUnits
        lobs
        avgRadOps
        domiciledWithin50Miles
        limitInsPerPowerUnit
        limitInsPerPowerUnitNonOwned
        perOccurenceLimit
        deductible
        spoilageFreezingCoverage
        mtcAdditionalCoverage
        pollutionCleanUp
        ownersGoodExtension
        nonOwnedTrailer
        expirationDate
        disclaimer
        status
        typeOfOps
        typeOfOpsDescriptionOther
        typeOfOpsFao
        glOrBop
        yearCurrentBusiness
        limitAl
        limitPd
        limitMtc
        limitGl
        limitNtl
        trailerInterchangeDeductible
        deductibleAl
        deductiblePd
        deductibleMtc
        deductibleGl
        deductibleNtl
        numberLossesPast3YearsGA
        continuousCoverage
        DOTObtain60Days
        typeOfTrucker
        ineligibleOperations
        vehicleHaulOilAndGas
        hazardousMaterialPlacard
        isCEODriver
        isCEOInvolvedInOperations
        bodilyInjuryLiabilityLimit
        otherGlOrBop
        priorInsuranceCancelledOrRenewed
        seriousViolationsWithinPast5Years
        ELDVendorName
        LossControlTechnology
        ELDRequiredToRecord
        commercialVehiclesInsuredOwns
        privatePassengerAutosInsured
        insuredVehiclesUsesInBusiness
        federal
        federalCargo
        progressiveState
        progressiveStateCargo
        progressiveOther
        progressiveEligibilityCriteria
        progressivePolicyState
        progressiveELDVendor
        ELDVendorAccess
        customerSpendLastYear
        nonOwnedVehiclesUsedInBusiness
        cargoCoverageForMobileHomes
        progressiveTrailerInterchangeCoverage
        trailerInterchangeFurnishAgreement
        nonOwnedTrailerPhysicalDamage
        MCS90
        isGAKickOut
        isCWKickOut
        cwLosses
        motorTruckCargoLimit
        towingLaborStorageLimitstr
        trailerInterchangeLimitstr
        trailerInterchangeLimitProgressive
        isProgKickOut
        vehiclesTowingOrHauling
        vehiclesHualSteel
        previousClaimCw
        totalAccidents
        totalNumberViolations
        totalSuspensions
        primaryAndNoncontributoryForTGL
        primaryAndNoncontributoryForAL
        requireFederalFillingForAL
        requireStateFillingForAL
        hauledRefrigeratedGoods
      }
    }
  }
`;

export const SAVE_TOW_APP = gql`
  mutation saveTowApp($dataMapping: JSON, $applicationType: String) {
    saveTowApp(dataMapping: $dataMapping, applicationType: $applicationType) {
      message
    }
  }
`;

export const FULL_DIGITAL_MARKING = gql`
  mutation fullyDigitalMarking($quoteId: ID!, $purchaseBy: String!) {
    fullyDigitalMarking(quoteId: $quoteId, purchaseBy: $purchaseBy)
  }
`;
export const EFFECTIVE_WITH_IN_NEXT_48_HOURS = gql`
  mutation effectiveWithInNext48Hours($quoteId: ID!) {
    effectiveWithInNext48Hours(quoteId: $quoteId)
  }
`;
