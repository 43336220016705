import { UPDATE_SIDEBAR, UPDATE_LT_SIDEBAR } from "../Types";

export const setMenu = (status) => ({
  type: UPDATE_SIDEBAR,
  payload: status,
});

export const setLTMenu = (status) => ({
  type: UPDATE_LT_SIDEBAR,
  payload: status,
});
