const INITIAL_STATE = {
  companies: [],
  LTuser: null,
  totalCompanies: [],
  totalSearchedCompanies: [],
  listPage: 0,
  LtLoggedIn: false,
  fetchMore: false,
  isBackPressed: false,
  listCount: 0,
  viewMoreLoader: false,
  search: false,
  platformFeeData: null,
};

import {
  SET_LTUSER_COMPANIES,
  UPDATE_LTUSER_COMPANIES_PAYLOAD,
  SET_LTUSER,
  SET_LT_LOGGED_IN,
  CLEAR_DATA,
  CLEAR_DATA_LT,
  SET_LTUSER_COMPANY_SEARCH_RESULTS,
  UPDATE_LTUSER_COMPANY_SEARCH_RESULTS_PAYLOAD,
  SET_BACK_PRESSED_WITH_SEARCH,
  SET_BACK_PRESSED,
  CLEAR_DATA_WITHOUT_USER,
  SET_PLATFORM_FEE_DATA,
} from "../Types";

import {
  updateViewMore,
  updateListPage,
  updateLTUserPayload,
  updateBackButtonCompanies,
  updateBackButtonWithSearchCompanies,
  updateLTUserSearchPayload,
  updateLTUserPayloadOnBack,
} from "../Utils/ltUserUtils";

const ltUserReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LTUSER:
      return { ...state, search: false, LTuser: action.payload };
    case SET_LT_LOGGED_IN:
      return { ...state, LtLoggedIn: action.payload };
    case SET_LTUSER_COMPANIES:
      return {
        ...state,
        companies: action?.payload?.companies,
        totalCompanies: action?.payload?.companies,
        listCount: action.payload.count,
        totalSearchedCompanies: [],
        isBackPressed: false,
        fetchMore: updateViewMore(
          action.payload.companies?.length,
          action.payload.count
        ),
        listPage: updateListPage(0, action.payload.companies),
      };
    case SET_BACK_PRESSED:
      return {
        ...state,
        search: false,
        totalCompanies: updateLTUserPayloadOnBack(state),
        companies: updateBackButtonCompanies(
          state.totalCompanies,
          state.listPage
        ),
        isBackPressed: action.payload,
        listPage: state.listPage - 1,
        fetchMore: true,
      };
    case SET_BACK_PRESSED_WITH_SEARCH:
      return {
        ...state,
        companies: updateBackButtonWithSearchCompanies(
          state.totalSearchedCompanies,
          state.listPage
        ),
        isBackPressed: action.payload,
        listPage: state.listPage - 1,
        fetchMore: true,
      };

    case UPDATE_LTUSER_COMPANIES_PAYLOAD:
      return {
        ...state,
        totalCompanies: updateLTUserPayload(state, action.payload),
        companies: action.payload.companies,
        listCount: action.payload.count,
        fetchMore: updateViewMore(
          action.payload.companies?.length + state.companies?.length,
          action.payload.count
        ),
        listPage: updateListPage(state.listPage, action.payload.companies),
        viewMoreLoader: false,
        search: false,
        isBackPressed: false,
      };
    case SET_LTUSER_COMPANY_SEARCH_RESULTS:
      return {
        ...state,
        totalSearchedCompanies: action.payload.companies,
        companies: action.payload.companies,
        listCount: action.payload.count,
        fetchMore: updateViewMore(
          action.payload.companies?.length,
          action.payload.count
        ),
        listPage: updateListPage(0, action.payload.companies),
        search: true,
      };
    case UPDATE_LTUSER_COMPANY_SEARCH_RESULTS_PAYLOAD:
      return {
        ...state,
        totalSearchedCompanies: updateLTUserSearchPayload(
          state,
          action.payload
        ),
        companies: action.payload.companies,
        listCount: action.payload.count,
        fetchMore: updateViewMore(
          action.payload.companies?.length + state.companies?.length,
          action.payload.count
        ),
        listPage: updateListPage(state.listPage, action.payload.companies),
        viewMoreLoader: false,
      };
    case CLEAR_DATA_LT:
      return { ...INITIAL_STATE };
    case CLEAR_DATA_WITHOUT_USER:
      return { ...INITIAL_STATE };

    case SET_PLATFORM_FEE_DATA:
      return { ...state, platformFeeData: action.payload };
    default:
      return state;
  }
};

export default ltUserReducer;
