const INITIAL_STATE = {
  companies: [],
  totalCompanies: [],
  dispatcherUser: null,
  listPage: 0,
  fetchMore: false,
  listCount: 0,
  viewMoreLoader: false,
  allSearchResults: [],
  pendingSearchResults: [],
  acceptedSearchResults: [],
  rejectedSearchResults: [],
};

import {
  SET_DISPATCHER_COMPANIES,
  UPDATE_DISPATCHER_COMPANY,
  SET_BACK_PRESSED,
  CLEAR_DATA,
  SET_DISPATCHER_USER,
  UPDATE_DISPATCHER_COMPANIES_PAYLOAD,
  UPDATE_DISPATCHER_VIEWMORE_LOADER,
  SET_DISPATCHER_COMPANY_SEARCH_RESULTS,
  CLEAR_DISPACTHER_COMPANY_SEARCH_RESULTS,
} from "../Types";

import {
  updateViewMore,
  updateListPage,
  setDispatcherCompanySearchResult,
  updateDispatchersPayload,
  updateBackButtonCompanies,
} from "../Utils/dispatcherUtils";

const dispatcherReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_DISPATCHER_USER:
      return { ...state, dispatcherUser: action.payload };

    case SET_DISPATCHER_COMPANIES:
      return {
        ...state,
        companies: action.payload.dispatcherOfCompanies,
        totalCompanies: action.payload.dispatcherOfCompanies,
        listCount: action.payload.count,
        isBackPressed: false,
        fetchMore: updateViewMore(
          action.payload.dispatcherOfCompanies?.length,
          action.payload.count
        ),
        listPage: updateListPage(0, action.payload.dispatcherOfCompanies),
      };
    case SET_BACK_PRESSED:
      return {
        ...state,
        companies: updateBackButtonCompanies(
          state.totalCompanies,
          state.listPage
        ),
        isBackPressed: action.payload,
        listPage: state.listPage - 1,
        fetchMore: true,
      };

    case SET_DISPATCHER_COMPANY_SEARCH_RESULTS:
      return {
        ...state,
        allSearchResults: setDispatcherCompanySearchResult(
          action.payload.array,
          action.payload.id
        ),
      };

    case CLEAR_DISPACTHER_COMPANY_SEARCH_RESULTS:
      return { ...state, allSearchResults: [] };
    case CLEAR_DATA:
      return {
        ...state,
        companies: [],
        totalCompanies: [],
        dispatcherUser: null,
        listPage: 0,
        fetchMore: false,
        listCount: 0,
        viewMoreLoader: false,
        allSearchResults: [],
        pendingSearchResults: [],
        acceptedSearchResults: [],
        rejectedSearchResults: [],
      };

    case UPDATE_DISPATCHER_VIEWMORE_LOADER:
      return { ...state, viewMoreLoader: action.payload };

    case UPDATE_DISPATCHER_COMPANIES_PAYLOAD:
      return {
        ...state,
        totalCompanies: updateDispatchersPayload(state, action.payload),
        companies: action.payload.dispatcherOfCompanies,
        listCount: action.payload.count,
        fetchMore: updateViewMore(
          action.payload.dispatcherOfCompanies?.length + state.companies?.length,
          action.payload.count
        ),
        listPage: updateListPage(
          state.listPage,
          action.payload.dispatcherOfCompanies
        ),
        viewMoreLoader: false,
      };

    default:
      return state;
  }
};

export default dispatcherReducer;
