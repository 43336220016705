import {
  APPEND_NOTIFICATION,
  SET_NOTIFICATIONS,
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATION_PAYLOAD,
  UPDATE_VIEWMORE_LOADER,
  SET_HOME_NOTIFICATIONS,
  UPDATE_HOME_NOTIFICATION,
  UPDATE_HOME_NOTIFICATION_PAYLOAD,
  UPDATE_HOME_VIEWMORE_LOADER,
  SET_LT_SEARCH_DATA,
} from "../Types";

//Action Creator
export const appendNotification = (notification) => ({
  type: APPEND_NOTIFICATION,
  payload: notification,
});

export const setNotifications = (data) => ({
  type: SET_NOTIFICATIONS,
  payload: data,
});

export const updateNotification = (data) => ({
  type: UPDATE_NOTIFICATION,
  payload: data,
});

export const updateNotificationWithBulkPayload = (payload) => ({
  type: UPDATE_NOTIFICATION_PAYLOAD,
  payload,
});

export const updateViewMoreLoader = (payload) => ({
  type: UPDATE_VIEWMORE_LOADER,
  payload,
});

export const setHomeNotifications = (payload) => ({
  type: SET_HOME_NOTIFICATIONS,
  payload,
});

export const updateHomeNotifications = (payload) => ({
  type: UPDATE_HOME_NOTIFICATION,
  payload,
});

export const updateHomeNotificationsWithBulkPayload = (payload) => ({
  type: UPDATE_HOME_NOTIFICATION_PAYLOAD,
  payload,
});

export const updateHomeViewMoreLoader = (payload) => ({
  type: UPDATE_HOME_VIEWMORE_LOADER,
  payload,
});

export const setLTSearchData = (payload) => ({
  type: SET_LT_SEARCH_DATA,
  payload,
});
