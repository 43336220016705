import Button from "../Button";

export default function NotesInput({
  onSubmit,
  formFields,
  onInputChange,
  onCancelClick,
}) {
  return (
    <div className="relative">
      <div className="overflow-hidden rounded-lg border border-borderColor shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500 text-black">
        <input
          type="text"
          name="title"
          id="title"
          placeholder="Add a title"
          onChange={onInputChange}
          value={formFields.title.value}
          className="block w-full border-0 pt-2.5 text-lg font-medium placeholder:text-gray-400 focus:ring-0"
        />
        <textarea
          rows={2}
          id="description"
          name="description"
          placeholder="Add a note"
          onChange={onInputChange}
          value={formFields.description.value}
          className="block w-full resize-none border-0 py-0 text-gray-500 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
        />
      </div>

      {formFields.error && (
        <h5 className="errorMessage xs:text-sm">
          Please enter title or description to submit
        </h5>
      )}

      <div className="flex justify-end items-center gap-8 border-t border-gray-200 px-2 pt-2 sm:px-3">
        <Button
          width="w-28"
          height="h-11"
          title="Cancel"
          onButtonClick={onCancelClick}
          additionStyle="bg-white text-bgInactiveButton font-hkLight border border-borderColor shadow-none"
        />

        <Button
          width="w-28"
          height="h-11"
          title="Enter"
          onButtonClick={onSubmit}
        />
      </div>
    </div>
  );
}
